<ng-template #confirmationModal let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <h4 class="modal-title flex-grow-1 text-center">
      {{ 'APPROVAL.YOU-CONFIRM' | translate }} {{ 'APPROVAL.' + actionType.toUpperCase() |translate }} {{ 'APPROVAL.IS-THIS' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body py-4 f-18">
    <div *ngIf="!enableReason">
      <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
    </div>
    <div *ngIf="enableReason">
      <span>{{ reasonLabel | translate }}</span>
      <div class="form-group mt-2">
        <textarea
          class="form-control"
          fz-elastic
          [(ngModel)]="reason"
          (ngModelChange)="onReasonChange()">
        </textarea>
      </div>
    </div>
 </div>

  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="close()">
      {{"MEMOS.CANCEL" | translate}}
    </button>
    <button class="btn btn-confirm" 
        appSetTheme
        type="button"
        [disabled]="enableReason && !reason"
        (click)="submit()">
      {{"MEMOS.CONFIRM" | translate}}
    </button>
  </div>
</ng-template>
