import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';
import { RESPONSE } from 'src/app/shared/utils/constants';
import {
  IAllUsers,
  ResultAllUsers,
} from 'src/app/store/users/users.model';
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  userProfileImageStorage = localStorage.getItem('photoUrl');
  userProfileNameStorage = localStorage.getItem('name');
  userProfileFullNameStorage = localStorage.getItem('full_name');
  userProfileJobPositionStorage =
    localStorage.getItem('job_position');

  constructor(private http: ApiService) {}

  loadInputData() {
    return this.http.get(
      API_URL.people + localStorage.getItem('person_id') + '/',
    );
  }

  // Person
  getPeopleList(params?): Observable<unknown> {
    return this.http.get(API_URL.people, params);
  }

  createPerson(data) {
    return this.http.post(API_URL.people, data);
  }

  updatePerson(id, data) {
    return this.http.patch(API_URL.people + id + '/', data);
  }

  updateSelf(id, data) {
    return this.http.patch(
      API_URL.people + id + '/update-self/',
      data,
    );
  }

  uploadPersonBlob(id, data) {
    return this.http.patch(
      API_URL.upload_person_blob + id + '/',
      data,
    );
  }

  getPersonDetail(id) {
    return this.http.get(API_URL.people + id + '/');
  }

  disablePerson(id) {
    return this.http.patch(
      API_URL.people + id + '/disable-user/',
      {},
    );
  }

  enablePerson(id) {
    return this.http.patch(API_URL.people + id + '/enable-user/', {});
  }

  resetPassword(id) {
    return this.http.patch(
      API_URL.people + id + '/reset-password/',
      {},
    );
  }

  deleteUser(id, data?) {
    return this.http.patch(
      `${API_URL.people}${id}/inactive-user/`,
      data,
    );
  }

  // Document
  getProfileDocument(params) {
    return this.http.get(API_URL.profiles_files, params);
  }

  uploadProfileDocument(data) {
    return this.http.post(API_URL.profiles_files, data);
  }

  deleteProfileDocument(id) {
    return this.http.delete(API_URL.profiles_files + id + '/');
  }

  setPassword(data) {
    return this.http.put(API_URL.change_password, data);
  }

  // Setting notifications
  getLinkLineCODIUM() {
    return this.http.get(API_URL.link_line_codium);
  }

  getLinkLineUser() {
    return this.http.get(API_URL.link_line_user);
  }

  settingEmail(id, data) {
    return this.http.patch(API_URL.setting_email + id + '/', data);
  }

  // Delegate
  getDelegate() {
    return this.http.get(API_URL.delegate_permission);
  }

  createDelegate(data) {
    return this.http.post(API_URL.delegate_permission, data);
  }

  updateDelegate(id, data) {
    return this.http.patch(
      API_URL.delegate_permission + id + '/',
      data,
    );
  }

  deleteDelegate(id) {
    return this.http.delete(API_URL.delegate_permission + id + '/');
  }

  // OTP
  OpenOtp(data) {
    return this.http.post(API_URL.open_otp, data);
  }

  CloseOtp(data) {
    return this.http.post(API_URL.close_otp, data);
  }

  verifyOtp(data) {
    return this.http.post(API_URL.verify_otp, data);
  }

  // Update LocalStorage
  setUserProfileImage(profileImageUrl) {
    localStorage.setItem('photoUrl', profileImageUrl);
    this.refreshUserProfileImage();
  }

  setUserName(name) {
    localStorage.setItem('name', name);
    this.userProfileNameStorage = localStorage.getItem('name');
  }

  setUserFullName(fullName) {
    localStorage.setItem('full_name', fullName);
    this.userProfileFullNameStorage =
      localStorage.getItem('full_name');
  }

  setUserJobPosition(jobPosition) {
    localStorage.setItem('job_position', jobPosition);
    this.userProfileJobPositionStorage =
      localStorage.getItem('job_position');
  }

  setUserHasSignature(signature) {
    localStorage.setItem(
      'no_signature',
      signature ? 'false' : 'true',
    );
  }

  refreshUserProfileName() {
    this.userProfileNameStorage = localStorage.getItem('name');
    this.userProfileFullNameStorage =
      localStorage.getItem('full_name');
    this.userProfileJobPositionStorage =
      localStorage.getItem('job_position');
  }

  refreshUserProfileImage() {
    this.userProfileImageStorage = localStorage.getItem('photoUrl');
  }

  setLocalStorageUpdateProfile(data) {
    this.setUserName(data.first_name);
    this.setUserFullName(data.full_name);
    this.setUserProfileImage(data.photo);
    this.setUserHasSignature(data.signature);
  }

  updatePreferredLanguage(id, language) {
    return this.http.patch(
      API_URL.people + id + '/update-preferred-language/',
      { preferred_language: language },
    );
  }
}
