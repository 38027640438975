<div *ngIf="data">
  <ul class="nav nav-pills nav-fill mt-3">
    <span class="nav-item"
      *ngFor="let res of maxLevelArr;let  i = index;"
      [ngClass]="{'nav-item-2': maxLevelArr.length > 3}">
      <div class="connected-line right"
        *ngIf="i+1 !== maxLevelArr.length"
        [ngClass]="{
            'border-green': i + 1 < memo.current_level,
            'border-green-approve':
              i + 1 === memo.current_level && memo.status === 'approved',
            'connected-line-2': maxLevelArr.length > 4
          }">
      </div>

      <!--      // วงกลมเปล่า-->
      <a class="nav-link nav-circle"
        *ngIf="i + 1 > memo.current_level || (i + 1 === memo.current_level && memo.status === 'pending')"
        href="javascript:void(0)"
        [ngbTooltip]="checkTooltip(i)"
        [autoClose]="false"
        triggers="manual"
        container="body"
        #stepOne="ngbTooltip"
        (mouseenter)="checkTooltip(i);stepOne.open() "
        (mouseleave)="stepOne.close();">
        {{i + 1}}
      </a>

      <!--      // วงสีเขียว-->
      <a class="nav-link nav-circle"
        *ngIf="i + 1 < memo.current_level || (i + 1 === memo.current_level && memo.status === 'approved')"
        [ngClass]="{'green': true}"
        href="javascript:void(0)"
        [autoClose]="false"
        triggers="manual"
        #stepOne="ngbTooltip"
        (mouseenter)="checkTooltip(i); stepOne.open() "
        (mouseleave)="stepOne.close()"
        [ngbTooltip]="checkTooltip(i)"
        container="body">
        <span>
          <em class="fas fa-check mt-1"></em>
        </span>
      </a>

      <!--      // วงสีแดง-->
      <a class="nav-link nav-circle"
        *ngIf="i + 1 === memo.current_level && (memo.status === 'terminated' || memo.status === 'rejected')"
        [ngClass]="{'red': true}"
        href="javascript:void(0)"
        [autoClose]="false"
        triggers="manual"
        #stepOne="ngbTooltip"
        (mouseenter)="checkTooltip(i); stepOne.open() "
        (mouseleave)="stepOne.close()"
        [ngbTooltip]="checkTooltip(i)"
        container="body">
        <span>
          <em class="fas fa-times mt-1"></em>
        </span>
      </a>

      <span [autoClose]="false"
        triggers="manual"
        #ToolTip="ngbTooltip"
        (mouseenter)="memo.loa_level_name[i]; ToolTip.open() "
        (mouseleave)="ToolTip.close()"
        [ngbTooltip]="memo.loa_level_name[i]"
        container="body">
        <p class="w-loa-name mx-1 my-1 text-truncate"
          [ngClass]="{'w-line-connect': maxLevelArr.length > 2}">
          {{memo.loa_level_name[i]}}</p>
      </span>
    </span>
  </ul>
</div>