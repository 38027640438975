import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CreateRequestService } from 'src/app/modules/create-request/service/create-request.service';
import {
  ButtonState,
  RequestType,
} from 'src/app/modules/create-request/shared/create-request.model';
import { initialAccountForm } from 'src/app/modules/create-request/template/requestForms';
import { DropdownItem } from 'src/app/shared/models/common.model';
import { ConvertDateYearService } from 'src/app/shared/service/convert-date-year.service';
import { DropdownService } from 'src/app/shared/service/dropdown.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  sectionForm: FormGroup;
  subscription: Subscription = new Subscription();

  members: DropdownItem[];
  credits: DropdownItem[];
  transferees: DropdownItem[];
  isCollapsed = false;
  requestType = RequestType;
  buttonState = ButtonState;

  @Input() submitted: boolean;
  @Input() state: number;
  @Input() accountForm: FormArray;

  constructor(
    public createRequestService: CreateRequestService,
    private dropdownService: DropdownService,
    private formBuilder: FormBuilder,
    private convertService: ConvertDateYearService,
  ) {}

  ngOnInit() {
    this.getDropdownItem();
    this.subscribeService();
  }

  getDropdownItem(): void {
    const data = {
      type: 'branch,account_types,transferee',
    };
    this.dropdownService.getDropdown(data).subscribe((res) => {
      this.members = res.branch;
      this.credits = res.account_types;
      this.transferees = res.transferee;
    });
  }

  subscribeService(): void {
    this.subscription.add(
      this.createRequestService.getSectionForm$.subscribe((form) => {
        this.sectionForm = form;
      }),
    );
  }

  buildAccountForm(): FormGroup {
    const form = initialAccountForm();
    if (this.s.requestType?.value !== RequestType.STATUSACCOUNT) {
      delete form.transferee;
      delete form.payOffDebt;
    }
    return this.formBuilder.group(form);
  }

  addAccountIndex(): void {
    this.submitted = this.accountForm.invalid ? true : false;
    if (this.accountForm.invalid) {
      return;
    }
    this.accountForm.push(this.buildAccountForm());
  }

  deleteAccount(index: number): void {
    this.accountForm.removeAt(index);
  }

  getToday(account: AbstractControl): void {
    const today = this.convertService.getToday(new Date());
    account.patchValue({ payOffDebt: today });
  }

  clearDate(account: AbstractControl): void {
    account.patchValue({ payOffDebt: null });
  }

  get s(): { [key: string]: AbstractControl } {
    return this.sectionForm.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
