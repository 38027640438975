<nav [ngStyle]="{'background': 'linear-gradient(90deg, '+themeList?.bg_color_secondary+', '+themeList?.bg_color_primary+' 80%)'}"
	class="navbar navbar-expand-lg navbar-light bg-white border-bottom custom-layer"
	id="navbar">
	<div class="position-fixed d-flex custom-position-hamburger">
		<div [ngStyle]="{'color': themeList?.bg_color_primary}"
			class="mx-2">
			<em (click)="sidebar()"
				class="pointer custom-hamburger fa fa-bars mt-3 hamburger-pc"
				aria-hidden="true"></em>
			<em (click)="sidebar(true)"
				class="pointer custom-hamburger fa fa-bars mt-3 hamburger-mobile"
				aria-hidden="true"></em>
		</div>

		<div class="flex-grow-1 logo-none-mobile">
			<img [src]="themeList?.display_sidebar? (themeList.display_sidebar | secure | async): 'assets/images/codium_logo.png' "
				style="max-height: 50px;"
				class="pl-3"
				alt>
		</div>
	</div>

	<div class="row w-100 justify-content-end">
		<div class="col-auto d-flex px-0 align-items-center">
			<div (clickOutside)="closeNotification()"
				class="noti-center">
				<em [ngStyle]="{'color': hoverList.notification? themeList?.hovered_text_color: themeList?.text_color}"
					(mouseover)="hoverText('notification', true)"
					(mouseleave)="hoverText('notification', false)"
					(click)="openNotification()"
					class="fa fa-bell fa-lg noti-icon"
					aria-hidden="true"></em>
				<span *ngIf="notificationResult && notificationResult.unread_count > 0"
					class="badge-number d-inline-block">
					{{notificationResult.unread_count}}
				</span>
				<div *ngIf="showNotificationToggle"
					class="width-box">
					<div class="arrow"></div>
					<div class="custom-container ">
						<div class="row justify-content-between align-items-center px-2 w-title">
							<h6 class="m-0 headerText-custom">
								{{notificationHeader}}
							</h6>
							<a (click)="markAllAsRead()"
								href="javascript:void(0)"
								style="color: inherit;">
								{{"NAV-BAR.MARK-ALL-AS-READ"|translate}}
							</a>
						</div>
						<div (scrollingFinished)="loadMoreNotification()"
							class="scroll row p-0"
							appScrollTracker>
							<div *ngIf="notificationResult.results.length === 0"
								class="row mx-auto px-1 pt-1 height-box border-bottom-custom col-12 d-flex justify-content-center">
								<p style="font-size: 16px"
									class="pt-3">
									{{"NAV-BAR.NO-NOTIFICATION"|translate}}
								</p>
							</div>
							<div *ngFor="let notification of notificationResult.results"
								[ngClass]="{'bg-unread': !notification.read}"
								(click)="clickNotification(notification)"
								class="row mx-0 px-1 pt-1 height-box pointer border-bottom-custom col-12">
								<div class="col-auto p-0 d-flex align-items-start mx-3 mt-2">
									<img src='assets/images/flaticons/bell-notification.png'
										class="photo-actor"
										alt>
									<div [ngClass]="notification.read ? '':'status-circle'"></div>
								</div>
								<div class="col p-0">
									<p [innerHTML]="translateSidebar.currentLang === 'th'? notification.content: notification.content_en"
										class="custom-box-detail">
									</p>
									<span>
										<a [ngClass]="notification.read ? '':'text-blue'"
											class="text-time">
											{{ notification.notification.time_since }}
										</a>
									</span>
								</div>
							</div>
							<div class="w-100"
								style="margin: 5px">
								<app-loading-spinner [loading]="isLoading"></app-loading-spinner>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div [style.--c]="themeList?.text_color"
			class="col-auto md:col-auto ml-3 pr-lg-0 align-self-center profile-border">
			<div class="text-white d-flex"
				ngbDropdown
				display="dynamic">
				<div class="d-none d-md-inline ml-1 mr-4">
					<div [style.color]="themeList?.text_color">
						{{profile?.first_name}}
					</div>
					<div [style.color]="themeList?.text_color"
						(click)="logOut()"
						class="font-italic pointer"
						style="font-size: 10px;text-decoration: underline">
						Logout
					</div>
				</div>
				<img [src]="(profile?.photo | secure | async) || 'assets/images/person.png'"
					[ngStyle]="{'border-color': themeList?.bg_color_secondary}"
					(click)="navigateToProfile()"
					alt
					class="display-profile pointer d-none d-md-inline-block">
				<img [src]="(profile?.photo | secure | async) || 'assets/images/person.png'"
					[ngStyle]="{'border-color': themeList?.bg_color_secondary}"
					class="display-profile pointer d-inline-block d-md-none"
					id="dropdownProfile"
					alt
					data-toggle="dropdown">
				<div ngbDropdownMenu
					aria-labelledby="dropdownProfile"
					class="lang-menu dropdown-profile"
					style="right:0">
					<button [routerLink]="['/', 'profile-detail', 'detail']"
						ngbDropdownItem
						routerLinkActive="active">
						Profile
					</button>
					<div class="dropdown-divider"></div>
					<button (click)="logOut()"
						ngbDropdownItem>
						Logout
					</button>
				</div>
			</div>
		</div>
	</div>
</nav>