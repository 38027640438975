import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from '../navbar/navbar.service';
import { Store } from '@ngxs/store';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  hasPermission = false;
  recall = false;
  message = this.hasPermission
    ? `Sorry, but you don't have permission to access this page.`
    : `Sorry, we're unable to find the page you're looking for.`;

  constructor(
    private activateRoute: ActivatedRoute,
    private navbar: NavbarService,
    private router: Router,
    private store: Store,
    private authenticationService: AuthenticationService,
  ) {
    this.navbar.setActiveSidebar(false);
    this.activateRoute.queryParams.subscribe((res) => {
      this.hasPermission = !!res.permission;
      this.recall = !!res.recall;
    });
  }

  goToLogin(): void {
    const currentUser =
      this.store.selectSnapshot<string>((s) => s.auth?.access) ||
      this.authenticationService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/welcome-ncb']);
    } else {
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }
  }
}
