import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreeModule } from '@circlon/angular-tree-component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { UserAccessTreeDropdownComponent } from './user-access-tree-dropdown.component';

@NgModule({
  declarations: [UserAccessTreeDropdownComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TreeModule,
    ClickOutsideModule,
  ],
  exports: [UserAccessTreeDropdownComponent],
})
export class UserAccessTreeDropdownModule {}
