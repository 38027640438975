import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { NavbarService } from '../navbar/navbar.service';
import { TitleService } from '../../../shared/service/title-service';
import { mockConfigs } from 'src/app/shared/models/common.model';
import { ConfigsService } from 'src/app/shared/service/configs.service';
import { AngularDeviceInformationService } from 'angular-device-information';
import { SpinnerService } from '../../services/spinner.service';
import { ConfigsRes } from 'src/app/modules/memo-report/shared/configs.models';
import { UsersService } from 'src/app/modules/users/service/users.service';
import { map } from 'rxjs/operators';
import { UserAccessManagement } from 'src/app/shared/models/user.models';
import { ErrorMessageService } from 'src/app/shared/service/error-message.service';
import { SiteConfigService } from 'src/app/shared/service/site-config.service';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  modal: NgbModalRef;

  private subscription: Subscription;
  config: CountdownConfig = {
    leftTime: null,
    notify: 0,
  };
  navbarActive = false;
  fullWidth: number;
  flags;
  selectedFlags: string;
  profile: any;
  isAdmin = false;
  notificationsUnread: Array<any>;
  notificationsRead: Array<any>;
  notificationResult;
  notificationHeader: string;
  showNotificationToggle = false;
  showWarning = false;
  getWarningEnableOTPFirstTime = true;
  certificateStatus: string = null;
  earlier: string;
  configsRes: ConfigsRes;
  loadMore: string;
  translateSidebar;
  page = 1;
  isLoading = false;
  typeNotification = {
    tag_comment_on_memo: 'memo_comment_tag',
    comment_reply_on_memo: 'memo_comment_reply',
    new_announcement: 'memo_announced',
    new_post_department: 3,
    new_post_public: 4,
    new_post_and_mention: 5,
    new_comment: 6,
    new_comment_mention: 7,
    new_comment_tag_comment: 8,
    memo_new_req_for_approval: 9,
    memo_update_status: 10,
  };
  menus = {
    manage_menu: [],
    manage_report: [],
    manage_web_config: [],
  };

  checkLanguage = false;
  themeList: ThemeList;
  hoverList = {
    logout: false,
  };

  language: string;
  isFullSidebar = true;
  openSidebar = false;
  backgroundFade = null;
  mailReportPermissions: string[] = null;
  tagVersion: string = null;
  timeLeftString = null;
  isHideCountDown = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    public notificationService: NotificationService,
    private swal: SweetAlertService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private store: Store,
    private titleService: TitleService,
    private deviceInformationService: AngularDeviceInformationService,
    private configsService: ConfigsService,
    private spinnerService: SpinnerService,
    private usersService: UsersService,
    private errorMessageService: ErrorMessageService,
    private siteConfigService: SiteConfigService,
    private readonly title: Title,
    private modalService: NgbModal,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.openSidebar = false;
      }
    });

    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
      this.hexToRGBA();
    });
    this.titleService.getSidebar().subscribe((res) => {
      this.openSidebar = !this.openSidebar;
      this.isFullSidebar = res;
    });

    this.store.subscribe((state) => {
      this.profile = state.auth;
    });
  }

  ngOnInit(): void {
    $('#menu-toggle').click((e) => {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedFlags = localStorage.getItem('lang');
    this.translateSidebar = this.translate;
    this.navbar.data.subscribe((res) => {
      setTimeout(() => {
        this.navbarActive = res;
      });
    });
    this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.translateNotification();
      },
    );
    this.getAllNotification();
    this.translateNotification();
    this.setSidebarMenus();
    this.getConfigs();
    this.getTagVersionDeploy();
    this.subscription = this.siteConfigService.timeLeft$.subscribe(
      (timeLeft) => {
        if (timeLeft) {
          this.config.leftTime = 60 * timeLeft;
        } else {
          this.title.setTitle(null);
          this.config.leftTime = null;
          this.isHideCountDown = false;
        }
      },
    );
  }

  hexToRGBA(): void {
    let hex = this.themeList.hovered_text_color;
    const opacity = 0.1;
    const rgb =
      'rgba(' +
      (hex = hex.replace('#', ''))
        .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
        .map((l) => {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(isFinite(opacity) ? opacity : 1)
        .join(',') +
      ')';
    this.backgroundFade = rgb;
  }

  async setSidebarMenus(): Promise<void> {
    this.spinnerService.show();
    const keyMenu = Object.keys(this.menus);
    for (const key of keyMenu) {
      const manageMenu = await this.getAccessPermission(key);
      const accessLabel = [];
      this.menus[key] = manageMenu.map(
        (menus: UserAccessManagement) => {
          menus.app_label = this.extractAppLabels(menus);
          accessLabel.push(...menus.app_label);
          return menus;
        },
      );
      this.menus[key].app_label = accessLabel;
      this.spinnerService.hide();
    }
  }

  extractMailReport(subData: UserAccessManagement): string[] {
    if (!this.mailReportPermissions) {
      this.mailReportPermissions = this.extractAppLabels(subData);
    }
    return this.mailReportPermissions;
  }

  extractAppLabels(menus: UserAccessManagement | any): string[] {
    const appLabels: string[] = [];

    if (menus.children.length >= 1) {
      for (const childItem of menus.children) {
        if (childItem.children.length >= 1) {
          const appLabelChild = childItem.children
            .filter(
              (sub: { app_label: string[]; text: string }) =>
                sub.app_label || sub.text === 'Mail Report',
            )
            .map(
              (sub: {
                app_label: string[];
                text: string;
                children: UserAccessManagement[];
              }) => {
                if (sub.text === 'ยกเลิกรายการคำขอ') {
                  const excludedLabels = this.excludeCoreMemoLabel(
                    sub.app_label,
                  );
                  const addAppSearchPermission = [
                    ...excludedLabels,
                    'app_search',
                  ];
                  sub.app_label = [];
                  return addAppSearchPermission;
                }
                if (sub.text === 'Mail Report') {
                  const excludeMailReports = sub.children.reduce(
                    (excludeMailReports, child) => {
                      const excludedLabels =
                        this.excludeCoreMemoLabel(child.app_label);
                      return [
                        ...excludeMailReports,
                        ...excludedLabels,
                      ];
                    },
                    [],
                  );
                  sub.app_label = excludeMailReports;
                  return excludeMailReports;
                } else {
                  return this.excludeCoreMemoLabel(sub.app_label);
                }
              },
            )
            .flat();
          childItem.app_label = appLabelChild;
          appLabels.push(...appLabelChild);
        } else if (childItem.app_label) {
          childItem.app_label = this.excludeCoreMemoLabel(
            childItem.app_label,
          );
          appLabels.push(...childItem.app_label);
        }
      }
    } else if (menus.app_label) {
      menus.app_label = this.excludeCoreMemoLabel(menus.app_label);
      appLabels.push(...menus.app_label);
    }

    return appLabels;
  }

  excludeCoreMemoLabel(appLabels: string[]): string[] {
    return appLabels.filter((label) => label !== 'ncb_memos_common');
  }

  getAccessPermission(key: string): Promise<UserAccessManagement[]> {
    return new Promise<UserAccessManagement[]>((resolve) => {
      this.usersService
        .getAccessPermissionManagement(key.replace(/_/g, '-'))
        .pipe(
          map(
            (managePermission: UserAccessManagement[]) =>
              managePermission,
          ),
        )
        .subscribe(
          (permission) => {
            resolve(permission);
          },
          (error) => {
            this.errorMessageService.errorMessage(error);
          },
        );
    });
  }

  checkSingleMenu(appLabel: string): boolean {
    if (appLabel) {
      const singleMenus = [
        'กระดานข่าว',
        'การจัดการกล่องเอกสาร',
        'import_view',
        'agent_database',
        'update_batch',
        'admin_operated',
        'operation_logs',
        'รายงานเอกสาร',
        'mail_report',
        'master_data',
      ];

      return singleMenus.some(
        (menu: string) =>
          menu === appLabel.toLowerCase().replace(/\s+/g, '_'),
      );
    }
  }

  setPath(appLabels: string[]): string {
    if (appLabels) {
      return appLabels
        .find((label) => label !== 'ncb_memos_common')
        .replace(/_/g, '-');
    }
  }

  setMenuIconPath(appLabels: string[]): string {
    const fileName = this.setPath(appLabels);
    return `assets/svg/icon-ncb-menu/${fileName}.svg`;
  }

  setChildPath(appLabel: string): string {
    switch (appLabel) {
      case 'สร้างรายการคำขอ':
        return 'create-request';
      case 'Reception Application':
        return 'application';
      case 'บันทึกทะเบียนคำขอ':
        return 'app-search';
      case 'ยืนยันรายการคำขอตรวจสอบ':
        return 'confirmation';
      case 'การจัดการกล่องเอกสาร':
        return 'box-feature';

      default:
        return appLabel.toLowerCase().replace(/\s+/g, '-');
    }
  }

  checkRouteActive(url: string): boolean {
    return url.split('/')[1] === this.router.url.split('/')[1];
  }

  translateNotification(): void {
    this.notificationHeader = this.translate.instant(
      'NAV-BAR.NOTIFICATION',
    );
    this.earlier = this.translate.instant('NAV-BAR.EARLIER');
    this.loadMore = this.translate.instant('NAV-BAR.LOAD-MORE');
    if (this.translate.currentLang === 'en') {
      this.checkLanguage = true;
    } else {
      this.checkLanguage = false;
    }
  }

  getAllNotification(): void {
    this.notificationService
      .getAllNotification({ page: 1 })
      .subscribe(
        (notification) => {
          this.notificationResult = notification;
          [this.notificationsUnread, this.notificationsRead] =
            _.partition(this.notificationResult.results, [
              'read',
              false,
            ]);
        },
        (err) => {
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
  }

  canCreateUser(): boolean {
    return this.router.url === '/permission/profile/list';
  }

  navigateTo(): void {
    this.router.navigate(['/department', 'level']);
  }

  openNotification(): void {
    this.showNotificationToggle = !this.showNotificationToggle;
  }

  closeNotification(): void {
    this.showNotificationToggle = false;
  }

  // Notification
  clickNotification(notification): void {
    this.notificationService.updateIsRead(notification.id).subscribe(
      () => {
        _.forEach(this.notificationResult.results, (obj) => {
          if (notification.id === obj.id && obj.read === false) {
            obj.read = true;
            this.notificationResult.unread_count =
              this.notificationResult.unread_count - 1;
          }
        });
        [this.notificationsUnread, this.notificationsRead] =
          _.partition(this.notificationResult.results, [
            'read',
            false,
          ]);
      },
      (err) => {
        this.swal.toastNotification({
          type: 'error',
          content: err.status,
        });
      },
    );
    this.notificationRouter(notification);
  }

  getConfigs(): void {
    if (
      this.deviceInformationService
        .getDeviceInfo()
        .os.includes('Windows' || 'windows')
    ) {
      this.configsService.getDataHttpServer('configs/').subscribe(
        (configs: ConfigsRes) => {
          if (configs.ErrorCode === 0) {
            this.configsRes = configs;
          } else {
            this.toastNotification('error', configs.Desc);
          }
        },
        (err) => {
          this.spinnerService.hide();
          this.toastNotification(
            'error',
            'failed to connect configs',
          );
        },
      );
    } else {
      this.configsRes = mockConfigs;
    }
  }

  getTagVersionDeploy(): void {
    this.configsService.getTagVersionDeploy().subscribe(
      (res) => {
        this.tagVersion = res.version;
      },
      (error) => {
        this.errorMessageService.errorMessage(error);
      },
    );
  }

  toastNotification(type: string, content: string): void {
    this.swal.toastNotification({
      type: type,
      content: content,
    });
  }

  notificationRouter(notification): void {
    this.showNotificationToggle = !this.showNotificationToggle;
    if (
      notification.notification.source_content_type_name === 'memo'
    ) {
      if (notification.action === 'memo_pending') {
        return this.navigateToPage(
          '/executive/list/',
          notification.notification.source_id,
        );
      } else {
        return this.navigateToPage(
          '/memo/memo/real/list/',
          notification.notification.source_id,
        );
      }
    } else if (
      notification.notification.source_content_type_name ===
      'announcement'
    ) {
      this.router.navigate(['/feed', 'list']);
    } else if (
      notification.notification.source_content_type_name ===
      'newsfeedpost'
    ) {
      this.navigateToPage(
        '/feed/list/',
        notification.notification.source_id,
      );
    }
  }

  navigateToPage(url: string, id?: number): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/', url + id ? id : '']);
      });
  }

  loadMoreNotification(): void {
    if (
      this.notificationResult.results.length <
      this.notificationResult.count
    ) {
      this.isLoading = true;
      this.page += 1;
      const params = { page: this.page.toString() };
      this.notificationService.getAllNotification(params).subscribe(
        (res) => {
          [this.notificationsUnread, this.notificationsRead] =
            _.partition(this.notificationResult.results, [
              'read',
              false,
            ]);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.swal.toastNotification({
            type: 'error',
            content: err.status,
          });
        },
      );
    }
  }

  hoverText(type, value): void {
    this.hoverList[type] = value;
  }

  openFullSidebar() {
    this.isFullSidebar = !this.isFullSidebar;
  }

  onResize($event) {
    this.isFullSidebar = true;
  }

  handleEvent(ev: CountdownEvent) {
    this.title.setTitle(
      this.convertSecondsToTimes(ev.left / 1000) +
        ' -  National Credit Bureau',
    );
    if (ev.action === 'done') {
      this.siteConfigService.sendModalTimeConfig(20);
      this.isHideCountDown = true;
    }
  }

  convertSecondsToTimes(value: number) {
    // Calculate hours, minutes, and remaining seconds
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    const seconds: number = Math.floor(value % 60);

    const hoursString: string = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesString: string =
      minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsString: string =
      seconds < 10 ? `0${seconds}` : `${seconds}`;

    // Convert to angular format
    const timeLeftString = `${hoursString}H:${minutesString}m:${secondsString}s`;

    return timeLeftString;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
