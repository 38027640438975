const url = window.location.href;
const matches = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);
const baseUrl = matches[0];
const websocketUrl = baseUrl.replace('http', 'ws') + '/ws/';

export const environment = {
  production: false,
  baseUrl,
  websocketUrl,
};

export const featureFlag = {
  ddoc: false,
  ddoc_enable_feature_26: false,
  ddoc_enable_feature_28: false,
  timestamp_memo_enable: false,
  ndid: false,
  login_adfs_enable: false,
  maintenance_mode: false,
  maintenance_message_th: '',
  maintenance_message_en: '',
  sms_otp: false,
  email_otp: false,
  thaicom: false,
  self_service_form: false,
};

export const crystalReportUrlDev =
  'http://ec2-18-143-196-98.ap-southeast-1.compute.amazonaws.com';
export const crystalReportUrlUat = 'https://192.168.21.15';
export const crystalReportUrlProd = 'https://192.168.180.102';
