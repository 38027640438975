import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavbarService } from './core/components/navbar/navbar.service';
import { ThemeService } from './shared/service/theme.service';
import {
  Actions,
  ofActionDispatched,
  Select,
  Store,
} from '@ngxs/store';
import { GetUserById, Logout } from './store/auth/auth.actions';
import { Router, NavigationEnd } from '@angular/router';
import { AuthState } from './store/auth/auth.state';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthStateModel } from './store/auth/auth.model';
import { TranslateService } from '@ngx-translate/core';
import { SiteConfigService } from './shared/service/site-config.service';
import { filter, finalize, tap } from 'rxjs/operators';
import { ThemeInfo } from './modules/setting/component/theme/shared/theme.models';
import { setTheme } from 'ngx-bootstrap/utils';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TokenService } from './core/authentication/token.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ModalPermissionDeniedComponent } from './shared/components/modal-permission-denied/modal-permission-denied.component';
import { PlatformLocation } from '@angular/common';
import { AlertService } from './core/services/alert.service';
import { Title } from '@angular/platform-browser';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
const KEY = 'token_time';
const TOKEN_DEFAULT = 60 * 4;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @ViewChild('sessionTimeOutModal', { static: true })
  sessionTimeOutModal: ElementRef;
  loadingSuccess$ = new BehaviorSubject<boolean>(false);
  @ViewChild(SidebarComponent) sidebarComponent: SidebarComponent;
  @Select(AuthState.token) userList$: Observable<AuthStateModel>;
  modalTimeConfig: CountdownConfig = {
    leftTime: null,
    notify: 0,
    formatDate: ({ date }) => `${date / 1000}`,
  };

  tokenConfig: CountdownConfig = {
    leftTime: TOKEN_DEFAULT,
    notify: 0,
    format: '',
  };

  modal: NgbModalRef;

  defaultLang = 'en';
  sessionTimeOut = null;
  timeLeft = null;
  intervalTimeOut = null;

  navbarActive = false;
  refreshPage = true;

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private navbar: NavbarService,
    private themeService: ThemeService,
    private siteConfigService: SiteConfigService,
    private actions: Actions,
    private store: Store,
    private translateService: TranslateService,
    private idle: Idle,
    private tokenService: TokenService,
    public deviceInformationService: AngularDeviceInformationService,
    private modalService: NgbModal,
    public platformLocation: PlatformLocation,
    private alert: AlertService,
    private readonly title: Title,
  ) {
    platformLocation.onPopState(() => this.modalService.dismissAll());
    setTheme('bs4');
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate(['/login']);
    });
    this.initializeLanguage();

    // make sure that site setting is loaded before showing website.
    this.getTheme().subscribe(() => {
      this.navbar.data.subscribe((res) => {
        this.navbarActive = res;
      });
    });
    console.info(
      deviceInformationService.getDeviceInfo().screen_resolution,
    );

    this.subscription = this.siteConfigService
      .getModalTimeConfig()
      .subscribe((timeLeft) => {
        if (timeLeft) {
          this.modalTimeConfig.leftTime = timeLeft;
          if (this.modal) {
            this.modal.close();
            this.modal = null;
          }
          this.modal = this.modalService.open(
            this.sessionTimeOutModal,
            {
              centered: true,
              backdrop: 'static',
              keyboard: false,
            },
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('mouseover') onMouseMove() {
    this.modal = null;
    if (!this.modal) {
      this.siteConfigService.sendTimeLeft(null);
      this.reset();
    }
    return;
  }

  reset(): void {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.title.setTitle(null);
  }

  ngOnInit(): void {
    sessionStorage.alert = false;
    this.router.events
      .pipe(
        filter(
          (rs): rs is NavigationEnd => rs instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        this.refreshPage =
          event.id === 1 && event.url === event.urlAfterRedirects;
      });
    this.setTokenConfig();
    this.store
      .dispatch(GetUserById)
      .toPromise()
      .then(() => {
        // this.store.dispatch(GetUserById).toPromise();
        // right when the component initializes, start reset state and start watching
        this.setIdle().then(() => {
          this.reset();
        });
      });

    this.siteConfigService.pollSessionPolicyData(60000);
    this.siteConfigService.session$.subscribe(
      (config: { session_timeout: number }) => {
        const oldTimeOut = this.sessionTimeOut;
        this.sessionTimeOut = config?.session_timeout;
        setTimeout(() => {
          if (oldTimeOut !== this.sessionTimeOut) {
            sessionStorage.alert = true;
            this.setIdleWithTimeOut(this.sessionTimeOut);
            this.reset();
          } else {
            sessionStorage.alert = false;
          }
        }, 500);
      },
    );
  }

  async getSessionPolicy(): Promise<number> {
    return new Promise<number>((resolve) => {
      this.siteConfigService
        .getSessionPolicy()
        .subscribe((config: any) => {
          this.sessionTimeOut = config?.session_timeout;
          resolve(this.sessionTimeOut);
        });
    });
  }

  async setIdle() {
    await this.getSessionPolicy().then((sessionTimeOut) => {
      this.setIdleWithTimeOut(sessionTimeOut);
    });
  }

  setIdleWithTimeOut(sessionTimeOut): void {
    if (!this.modal) {
      if (JSON.parse(sessionStorage?.alert)) {
        this.siteConfigService.sendTimeLeft(null);
        this.alert.warning(
          'ระยะเวลาออกจากระบบอัตโนมัติเมื่อไม่มีการใช้งานถูกเปลี่ยนแปลง',
        );
        sessionStorage.alert = false;
      }
      this.idle.setIdle(60);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onIdleStart.subscribe(() => {
        if (!sessionTimeOut) {
          this.reset();
          return;
        }
        if (this.router.url.includes('login')) {
          this.reset();
          return;
        } else {
          this.alert.warning('ไม่พบการใช้งาน');
        }
      });

      this.idle.onInterrupt.subscribe(() => {
        this.siteConfigService.sendTimeLeft(null);
      });

      this.idle.onTimeoutWarning.subscribe(() => {
        this.siteConfigService.sendTimeLeft(sessionTimeOut);
      });
    }
  }

  /**
   * Set the initial language in localStorage
   * that will be used to stuff in HTTP Headers - `Accept-Language`
   * and for translation.
   */
  initializeLanguage(): void {
    this.defaultLang = localStorage.getItem('lang');
    if (!this.defaultLang || this.defaultLang === 'null') {
      localStorage.setItem('lang', 'th');
      this.translateService.setDefaultLang('th');
    }
    this.defaultLang = localStorage.getItem('lang');
    this.translateService.use(this.defaultLang);
  }

  setTokenConfig(): void {
    let value = +localStorage.getItem(KEY) ?? TOKEN_DEFAULT;
    if (value <= 0) value = TOKEN_DEFAULT;
    this.tokenConfig = { ...this.tokenConfig, leftTime: value };
  }

  getTheme(): Observable<ThemeInfo> {
    this.loadingSuccess$.next(false);
    this.spinnerService.show();
    return this.themeService.getTheme().pipe(
      tap((theme) => {
        this.themeService.setThemeList(theme);
      }),
      finalize(() => {
        this.loadingSuccess$.next(true);
        this.spinnerService.hide();
      }),
    );
  }

  handleEventToken(ev: CountdownEvent) {
    if (ev.action === 'notify') {
      if (this.refreshPage) {
        this.tokenConfig.leftTime = TOKEN_DEFAULT;
      }
      localStorage.setItem(KEY, `${ev.left / 1000}`);
    }
    if (ev.action === 'done') {
      this.refreshToken();
      this.setTokenConfig();
    }
  }

  refreshToken(): void {
    if (this.router.url.includes('login')) {
      return;
    }
    const token = this.tokenService.getRefreshToken();
    if (token) {
      this.tokenService.refreshToken(token).subscribe((tk: any) => {
        if (tk?.is_permission_denied) {
          this.openModal();
        }
        this.tokenService.saveToken(tk.access);
      });
    }
  }

  logout(): void {
    this.dismissAll();
    this.store.dispatch(Logout);
  }

  dismissAll(): void {
    this.modalTimeConfig.leftTime = 0;
    this.modal?.dismiss();
    setTimeout(() => {
      this.reset();
    }, 1000);
  }

  openModal() {
    this.modalService.open(ModalPermissionDeniedComponent, {
      backdrop: 'static',
      centered: true,
    });
  }

  timeOut(ev: CountdownEvent): void {
    this.title.setTitle(
      `Warning!(${ev.text}) -  National Credit Bureau`,
    );

    if (ev.action === 'done') {
      this.title.setTitle('TimeOut!! -  National Credit Bureau');
      this.siteConfigService.sendTimeLeft(null);
      localStorage.time = null;
      this.logout();
    }
  }
}
