import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { ClearState } from 'src/app/store/auth/auth.actions';
import { throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private authService: AuthenticationService,
  ) {}

  refreshToken(token: string) {
    const userRole = this.store.selectSnapshot<string>(
      (s) => s.auth?.role,
    );
    if (userRole) {
      return this.http
        .post(this.baseUrl + '/api/token-refresh/', {
          role: userRole,
          permissions: JSON.parse(localStorage.getItem('side_bar')),
          refresh: token,
        })
        .pipe(
          catchError((err) => {
            const errorCode = err?.error?.code;
            if (errorCode === 'token_not_valid') {
              this.store.dispatch(ClearState);
              this.router.navigate(['/login']).then(() => {
                window.location.reload();
              });
            }
            return throwError(err);
          }),
        );
    }
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refresh');
  }

  saveToken(token: string): void {
    localStorage.setItem('currentUser', token);
  }
}
