import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  ten = 10;
  transform(value: string, args?: any): any {
    return (
      {
        ten: value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      }[value.length] || value
    );
  }
}
