import { SignaturePosition } from 'src/app/modules/memos/model/memo.model';

export enum CeleryImportState {
  Pending = 'PENDING',
  Progress = 'PROGRESS',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export interface UploadMemoType {
  id?: number;
  name: string;
  name_en?: string;
  document_prefix?: string;
  memo_number_pattern?: string;
  task_id?: any;
  departments?: any[];
  default?: boolean;
  template_count?: number;
  is_all_department?: boolean;
}

export interface TypesUploadMemo {
  id: number;
  name: string;
  document_prefix: string;
}

export interface TypesUploadMemoDetail {
  count: number;
  next?: any;
  previous?: any;
  results: TypesUploadMemo[];
}

export interface CeleryTaskStatus {
  _state: CeleryImportState;

  [key: string]: any;
}

export interface ErrorMessage {
  [key: string]: ErrorMessageDetail;
}

export type ErrorMessageDetail = ErrorMessage | string | string[];

// eslint-disable-next-line prettier/prettier, max-len
export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export const PRE_POSITIONS: Array<SignaturePosition> = [
  {
    sequence: 0,
    name: 'watermark',
    positions: null,
    type: 'watermark',
  },
  {
    sequence: 1,
    name: 'date',
    positions: null,
    type: 'date',
  },
  {
    sequence: 2,
    name: 'memo number',
    positions: null,
    type: 'memo_number',
  },
];

export interface FontStyle {
  name: string;
  value: string;
}

export interface InitialPos {
  x: number;
  y: number;
}

export interface Position {
  X: number;
  Y: number;
  id: string;
  page: number;
  pageList: number[];
  fontStyle: FontStyle;
  initialPos: InitialPos;
  pageIdList: string[];
  enableSetting: boolean;
}

export interface SignaturePositions {
  name: string;
  type: string;
  sequence: number;
  positions: Position[];
}

export interface DetailMemo {
  id: number;
  name: string;
  name_en: string;
  sample_pdf: string;
  signature_position: SignaturePositions[];
  upload_memo_type: number;
  loa: number;
  custom_loa: any;
  loa_user_types: string[];
  allow_loa_change: boolean;
}
