<form [formGroup]="legalForm">
  <div class="section d-flex justify-content-between p-2 mb-4"
    *ngIf="displaySession">
    <div>รายละเอียดนิติบุคคล</div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 d-flex align-items-center">
      <div class="form-group d-flex mb-0 mr-5 pl-0">
        <input type="radio"
          class="mr-2 pointer"
          [value]="branch.HEADOFFICE"
          formControlName="headOffice"
          (ngModelChange)="setDisabledSubBranch($event)">
        <label class="mb-0">สำนักงานใหญ่</label>
      </div>
      <div class="form-group d-flex mb-0 mr-3">
        <input type="radio"
          class="mr-2 pointer"
          [value]="branch.SUBBRANCH"
          formControlName="headOffice"
          (ngModelChange)="setDisabledSubBranch($event)">
        <label class="mb-0"
          [class.field-require]="l.headOffice.value === branch.SUBBRANCH">
          สาขาที่
        </label>
      </div>
      <div class="form-group col mb-0 mt-2 px-0">
        <input type="text"
          autocomplete="off"
          maxlength="5"
          [imask]="{mask: '00000'}"
          class="form-control"
          formControlName="subBranch"
          [ngClass]="{ 'is-invalid': submitted && l.subBranch.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">เลขทะเบียนนิติบุคคล</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="legalNo"
          [imask]="{mask: '0000000000000'}"
          [ngClass]="{ 'is-invalid': submitted && l.legalNo.errors}">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">วันที่ออกหนังสือรับรอง</label>
        <input name="datepicker"
          class="form-control input-lg"
          formControlName="certificateDate"
          ngbDatepicker
          container="body"
          #dpCertificate="ngbDatepicker"
          (click)="dpCertificate.toggle();dpCertificate.navigateTo()"
          [ngClass]="{ 'is-invalid': submitted && l.certificateDate.errors}"
          (ngModelChange)="checkCertificateNoLaterThanNinetyDays($event)"
          [maxDate]="maxDate"
          [footerTemplate]="dpCertFooter"
          readonly>
        <div>
          <span class="span-calendar">
            <em class="fa fa-calendar i-calendar"
              aria-hidden="true"
              ngbDatepicker
              (click)="dpCertificate.toggle();dpCertificate.navigateTo()"></em>
          </span>
          <span class="span-calendar-times"
            *ngIf="l.certificateDate.value">
            <em class="fa fa fa-times i-calendar"
              aria-hidden="true"
              (click)="clearDate(legalForm,'certificateDate')"></em>
          </span>
        </div>
        <ng-template #dpCertFooter>
          <hr class="my-0" />
          <div class="d-flex">
            <div>
              <button class="btn button btn-sm m-2"
                (click)="getToday(legalForm,'certificateDate');dpCertificate.close()">
                Today
              </button>
            </div>
            <div class="ml-auto">
              <button class="btn outline-sm btn-sm m-2 ml-auto"
                (click)="dpCertificate.close()">Close</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">ประเภทนิติบุคคล</label>
        <ng-select class="selected-box"
          [items]="legalTitleList"
          bindLabel="label"
          bindValue="value"
          formControlName="title"
          (ngModelChange)="onLegalNameChange()"
          [ngClass]="{ 'is-invalid': submitted && l.title.errors}">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">วันที่จดทะเบียน</label>
        <input name="datepicker"
          class="form-control input-lg"
          formControlName="regisDate"
          ngbDatepicker
          container="body"
          #dpRegistration="ngbDatepicker"
          (click)="dpRegistration.toggle();dpRegistration.navigateTo()"
          [ngClass]="{ 'is-invalid': submitted && l.regisDate.errors}"
          [footerTemplate]="dpRegis"
          readonly>
        <div>
          <span class="span-calendar">
            <em class="fa fa-calendar i-calendar"
              aria-hidden="true"
              ngbDatepicker
              (click)="dpRegistration.toggle();dpRegistration.navigateTo()"></em>
          </span>
          <span class="span-calendar-times"
            *ngIf="l.regisDate.value">
            <em class="fa fa fa-times i-calendar"
              aria-hidden="true"
              (click)="clearDate(legalForm,'regisDate')"></em>
          </span>
        </div>
        <ng-template #dpRegis>
          <hr class="my-0" />
          <div class="d-flex">
            <div>
              <button class="btn button btn-sm m-2"
                (click)="getToday(legalForm,'regisDate');dpRegistration.close()">
                Today
              </button>
            </div>
            <div class="ml-auto">
              <button class="btn outline-sm btn-sm m-2 ml-auto"
                (click)="dpRegistration.close()">Close</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">ชื่อนิติบุคคล</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="legalName"
          (ngModelChange)="onLegalNameChange()"
          [ngClass]="{ 'is-invalid': submitted && l.legalName.errors}">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>ที่อยู่</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="address">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>จังหวัด</label>
        <ng-select class="selected-box"
          [items]="provinces"
          bindLabel="label"
          bindValue="value"
          formControlName="province"
          (change)="provinceChange($event)">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>เขต/อำเภอ</label>
        <ng-select class="selected-box"
          [items]="districts"
          bindLabel="label"
          bindValue="value"
          formControlName="district"
          (change)="districtChange($event)">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>แขวง/ตำบล</label>
        <ng-select class="selected-box"
          [items]="subdistricts"
          bindLabel="label"
          bindValue="value"
          formControlName="subdistrict"
          (change)="subDistrictChange($event)">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>รหัสไปรษณีย์</label>
        <ng-select class="selected-box"
          [items]="zipCodes"
          bindLabel="label"
          bindValue="value"
          formControlName="zipCode">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6"
      *ngIf="l.email">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">Email</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submitted && l.email.errors}">
        <div class="text-red"
          *ngIf="l.email.value && l.email.errors">
          <div>อีเมลไม่ถูกต้อง</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label [ngClass]="{'field-require': l.phone.validator}">เบอร์โทรศัพท์มือถือ</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="phone"
          [imask]="{mask: '000-000-0000'}"
          [ngClass]="{ 'is-invalid': submitted && l.phone.errors}">
      </div>
    </div>
  </div>
</form>

<ng-template #detectCertificateDate>
  <div class="modal-body">
    <div class="row justify-content-center mb-4">
      <div>หนังสือรับรองเกิน 90 วัน</div>
    </div>
    <div class="row justify-content-center">
      <div>
        <button type="button"
          class="btn primary-button py-2 px-4"
          (click)="closeModal(); dpCertificate.toggle();dpCertificate.navigateTo()">
          ตกลง
        </button>
      </div>
    </div>
  </div>
</ng-template>