import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Logout } from 'src/app/store/auth/auth.actions';
import { ThemeList, ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-modal-permission-denied',
  templateUrl: './modal-permission-denied.component.html',
  styleUrls: ['./modal-permission-denied.component.scss'],
})
export class ModalPermissionDeniedComponent implements OnInit {
  themeList: ThemeList;
  constructor(
    private modalService: NgbModal,
    private store: Store,
    private themeService: ThemeService,
  ) {
    themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.closeModal();
    }, 20000);
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  logOut(): void {
    this.store.dispatch(Logout);
  }
}
