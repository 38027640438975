import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NavbarService } from '../navbar/navbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { featureFlag } from 'src/environments/environment';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ADFS_URL } from '../../http/api.constant';
import { Select, Store } from '@ngxs/store';
import {
  ClearRemainingAttempt,
  ClearState,
  Login,
} from 'src/app/store/auth/auth.actions';
import { versionInfo } from '../../../../version-info';
import { ReCaptcha2Component } from 'ngx-captcha/public_api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { ErrorMessageService } from 'src/app/shared/service/error-message.service';
import { LoginSuccessNotificationType } from '../../../store/auth/auth.model';

declare let hljs: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  loginForm: FormGroup;
  loginAdfsEnable = featureFlag.login_adfs_enable;
  loading = false;
  submitted = false;
  error = '';
  email = '';
  currentTime: Date;
  currentDate = new Date().toLocaleString('th-TH', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  language = 'en';
  loginMode = true;
  adfsLoginMode = featureFlag.login_adfs_enable;
  textList: string;
  themeList: ThemeList;
  version = versionInfo.hash;
  isMobile;
  url = window.location.hostname;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public siteKey = '6LeoZ70dAAAAAP2ZkEanBnuBvtPsL0I97HVKHKGn';

  public aFormGroup: FormGroup;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'compact';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain = false;
  @ViewChild('captchaElem', { static: false })
  captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  @ViewChild('wrongPasswordAttempt', { static: false })
  wrongPasswordAttempt: ElementRef;

  @Select((state) => state.auth?.remaining_attempts)
  loginFailed$: Observable<number>;
  remainingAttempts: number;

  @Select((state) => state.auth?.advance_notification_type)
  loginAdvanceNotification$: Observable<LoginSuccessNotificationType>;

  @ViewChild('advancePopUpNotification', { static: false })
  advancePopUpNotification: ElementRef;
  popUpNotificationType: LoginSuccessNotificationType;
  popUpNotificationText: string;

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private spinner: SpinnerService,
    private navbar: NavbarService,
    private alert: AlertService,
    private translate: TranslateService,
    private themeService: ThemeService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal,
    private errorMessageService: ErrorMessageService,
    private zone: NgZone,
    private router: Router,
  ) {
    this.language = this.translate.currentLang;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(ClearState);
    this.checkCurrentTime();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.navbar.setActiveSidebar(false);
    this.changeLanguage(this.language);

    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });
    this.isMobile = this.themeService.isMobile();
    this.checkResponsive();
    this.subscribeLoginFailed();
    this.subscribeLoginAdvanceNotification();
  }

  ngAfterViewInit(): void {
    this.highlight();
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
    this.checkResponsive();
  }

  checkResponsive(): void {
    if (this.isMobile) {
      this.size = 'compact';
    } else {
      this.size = 'normal';
    }
  }

  checkCurrentTime(): void {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  adfsLogin(): void {
    window.location.href = ADFS_URL.login + '?email=' + this.email;
  }

  onSubmit(): void {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.spinner.hide();
    }
    this.loading = true;
    this.store.dispatch(
      new Login({
        username: this.f.username.value,
        password: this.f.password.value,
      }),
    );
  }

  changeLanguage(lang: string): void {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
    // this.checkValidate = true;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  handleError(): void {}

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  subscribeLoginFailed(): void {
    this.subscriptions = this.loginFailed$.subscribe(
      (res: number) => {
        if (res) {
          if (res <= 3 && !this.modalService.hasOpenModals()) {
            this.remainingAttempts = res;
            this.openModal(this.wrongPasswordAttempt);
          } else if (res > 3) {
            this.alert.error(
              this.translate.instant(
                'LOGIN.INVALID-USERNAME-OR-PASSWORD',
              ),
            );
          }
        }
      },
    );
  }

  openModal(modal: ElementRef): void {
    this.modalService.open(modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  subscribeLoginAdvanceNotification(): void {
    this.subscriptions = this.loginAdvanceNotification$.subscribe(
      (res: LoginSuccessNotificationType) => {
        if (
          res === LoginSuccessNotificationType.PREPARE_CHANGE_PASSWORD
        ) {
          this.popUpNotificationText =
            'กรุณาเปลี่ยนรหัสผ่านของคุณก่อนหมดอายุที่เมนูข้อมูลส่วนตัว';
          this.popUpNotificationType =
            LoginSuccessNotificationType.PREPARE_CHANGE_PASSWORD;
          this.openModal(this.advancePopUpNotification);
        } else if (
          res === LoginSuccessNotificationType.FORCE_CHANGE_PASSWORD
        ) {
          this.popUpNotificationText =
            'กรุณาเปลี่ยนรหัสผ่าน ก่อนเข้าสู่ระบบ';
          this.popUpNotificationType =
            LoginSuccessNotificationType.FORCE_CHANGE_PASSWORD;
          this.openModal(this.advancePopUpNotification);
        }
      },
    );
  }

  closeLoginAdvancePopUp() {
    if (
      this.popUpNotificationType ===
      LoginSuccessNotificationType.PREPARE_CHANGE_PASSWORD
    ) {
      this.zone.run(() => {
        this.router.navigate(['/welcome-ncb']);
      });
    } else {
      this.zone.run(() => {
        this.router.navigate(['/reset-password']);
        setTimeout(() => {
          location.reload();
        }, 500);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  closePopUp() {
    this.store.dispatch(new ClearRemainingAttempt());
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
