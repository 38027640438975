import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from './api.constant';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  constructor(private http: ApiService) {}

  resultNotiSendEmail(data) {
    return this.http.post(
      API_URL.result_notifications_send_email,
      data,
    );
  }

  previewEmail(data) {
    return this.http.post(
      API_URL.result_notifications_preview_email,
      data,
    );
  }

  previewEmailMultipleMemo(data, apiUrl: string): Observable<any> {
    return this.http.post(API_URL.memo_ncb + apiUrl, data);
  }
}
