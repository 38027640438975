import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ButtonState,
  ButtonType,
  CreateRequest,
} from 'src/app/modules/create-request/shared/create-request.model';

@Component({
  selector: 'app-option-buttons',
  templateUrl: './option-buttons.component.html',
  styleUrls: ['./option-buttons.component.scss'],
})
export class OptionButtonsComponent {
  @Input() isNextStage: boolean;
  @Input() path: string;
  @Input() state: number;
  @Input() registerMobile: boolean;
  @Input() canVerifyInformation = false;
  @Output() requestReceipt = new EventEmitter();
  @Output() actionType = new EventEmitter();

  buttonAction = ButtonType;
  buttonState = ButtonState;
  createRequest = CreateRequest;

  constructor() {}

  onButtonClick(actionType: string): void {
    this.actionType.emit(actionType);
  }
}
