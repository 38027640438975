import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { NavbarService } from '../components/navbar/navbar.service';
import { PermissionService } from '../services/permission.service';

import { Store } from '@ngxs/store';
import { GetUserById } from 'src/app/store/auth/auth.actions';
import { map, tap } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navbar: NavbarService,
    private permissions: PermissionService,
    private store: Store,
    private alertService: AlertService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const currentUser =
      this.store.selectSnapshot<string>((s) => s.auth?.access) ||
      this.authenticationService.currentUserValue;
    const userPermission = this.store.selectSnapshot<string[]>(
      (s) => s.auth?.side_bar,
    );
    let setPasswordStore: boolean =
      this.store.selectSnapshot<boolean>(
        (s) => s.auth.is_set_password,
      ) &&
      this.store.selectSnapshot<boolean>(
        (s) => s.auth.is_password_valid,
      );
    if (setPasswordStore == null) {
      setPasswordStore =
        this.authenticationService.isSetPassword &&
        this.authenticationService.isPasswordValid;
    }
    if (currentUser && setPasswordStore) {
      if (route.data[0] === 'welcome-ncb') {
        this.navbar.setActiveSidebar(true);
        return true;
      } else if (!userPermission) {
        return this.store.dispatch(GetUserById).pipe(
          map(() => {
            return this.checkPermission(
              this.store.selectSnapshot<string[]>(
                (s) => s.auth.permission,
              ),
              route.data,
              setPasswordStore,
            );
          }),
          tap({
            error: (error) => {
              this.router.navigateByUrl('/login');
            },
          }),
        );
      } else {
        return this.checkPermission(
          userPermission,
          route.data,
          setPasswordStore,
        );
      }
    } else {
      if (!setPasswordStore) {
        this.alertService.error('กรุณาตั้งค่ารหัสผ่านใหม่ก่อนใช้งาน');
      }
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  checkPermission(
    userPermission: any,
    permissionStore: Data,
    setPasswordStore: boolean,
  ): boolean {
    if (!setPasswordStore) {
      this.router.navigateByUrl('/reset-password');
    }
    const permissionStoreSet = new Set(
      Object.values(permissionStore),
    );
    const userPermissionSet = new Set(userPermission);
    if (
      Array.from(permissionStoreSet).some((perm) =>
        userPermissionSet.has(perm),
      )
    ) {
      this.navbar.setActiveSidebar(true);
      return true;
    } else {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
      return false;
    }
  }
  // tslint:disable-next-line:max-line-length
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}

@Injectable({ providedIn: 'root' })
export class MasterAdminOnlyViewGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private navbar: NavbarService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return this.store
      .dispatch(GetUserById)
      .toPromise()
      .then(() => {
        const userRole = this.store.selectSnapshot<string>(
          (s) => s.auth?.role,
        );
        if (userRole === 'Master Admin') {
          this.navbar.setActiveSidebar(true);
          return true;
        } else {
          this.router.navigate(['/page-not-found'], {
            queryParams: { permission: true },
          });
          return false;
        }
      });
  }
}
