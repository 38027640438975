import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ThemeList } from '../../../../shared/service/theme.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateRequestService } from 'src/app/modules/create-request/service/create-request.service';
import { CardType } from 'src/app/modules/create-request/shared/create-request.model';
import { Subscription, combineLatest } from 'rxjs';
import { FormGroup, Validators } from '@angular/forms';
import { SweetAlertService } from '../../../service/sweet-alert.service';
import { DatePipe } from '@angular/common';
import { CardDetail } from '../../../../modules/create-request/shared/idcard-model';
import {
  DopaID,
  DopaLaser,
} from '../../../../modules/create-request/shared/dopa.model';
import * as IMask from 'imask';
import { ButtonState } from '../../../../modules/create-request/shared/create-request.model';
import { DropdownItem } from 'src/app/shared/models/common.model';
import { ConvertDateYearService } from 'src/app/shared/service/convert-date-year.service';

@Component({
  selector: 'app-check-dopa',
  templateUrl: './check-dopa.component.html',
  styleUrls: ['./check-dopa.component.scss'],
})
export class CheckDopaComponent implements OnInit, OnDestroy {
  @Input() cardType: string;
  @Input() personIndex: number;
  @Input() state: number;
  @Input() person: FormGroup;
  @Output() clearValidators = new EventEmitter();

  themeList: ThemeList;
  modal: NgbModalRef;
  cardDetails: CardDetail;
  subscription: Subscription = new Subscription();
  buttonState = ButtonState;
  faceDetect: { isMatch: boolean }[];
  dropdownData: { [x: string]: DropdownItem[] };

  isShowApproveList = false;

  constructor(
    private swal: SweetAlertService,
    private datePipe: DatePipe,
    public createRequestService: CreateRequestService,
    private convertService: ConvertDateYearService,
  ) {}

  ngOnInit(): void {
    this.subscribeService();
  }

  subscribeService(): void {
    const combineLatestSub = combineLatest(
      this.createRequestService.faceRecognition$,
      this.createRequestService.cardDetails$,
      this.createRequestService.dropDownData$,
    ).subscribe(([face, cardDetail, dropdown]) => {
      this.faceDetect = face;
      this.cardDetails = cardDetail;
      this.dropdownData = dropdown;
    });
    this.subscription.add(combineLatestSub);
  }

  checkCardType(): boolean {
    return this.cardType === CardType.IDCARD;
  }

  checkDopa(): void {
    this.createRequestService.isLoading = true;
    this.createRequestService.errorCheckDopa[this.personIndex] =
      false;
    this.setControlValidation('dopa');
    if (!this.person.get('isSmartCard')?.value)
      this.setControlValidation('laserId', true);
    if (!this.checkInvalidForm()) {
      const data = this.setUpData(this.person);
      const checkDopaApi = this.person.get('isSmartCard')?.value
        ? this.createRequestService.checkDopaID(data)
        : this.createRequestService.checkDopaLaser(data);
      checkDopaApi.subscribe(
        (res) => {
          let type = 'success';
          let message = res.status;
          if (res.status === 'ERROR') {
            type = 'error';
            message = res.message;
          }
          this.swalNoti(type, message);
          this.person.get('dopa').patchValue(null);
          this.person.get('dopa').disable();
          this.createRequestService.isLoading = false;
        },
        (err) => {
          this.swalNoti('error', err.statusText);
          this.createRequestService.errorCheckDopa[this.personIndex] =
            true;
          this.person.get('dopa').patchValue('server');
        },
        () => {
          this.createRequestService.dopaSubmit = false;
          this.createRequestService.isLoading = false;
        },
      );
    } else {
      this.setControlValidation('dopa', true);
      this.person.get('dopa').patchValue(null);
      this.createRequestService.dopaSubmit = true;
      this.createRequestService.isLoading = false;
    }
  }

  setUpData(p: FormGroup): DopaID | DopaLaser {
    if (p.get('isSmartCard')?.value) {
      return {
        chipno: this.cardDetails.CID,
        pid: this.cardDetails.idCard.CitizenID,
        bp1no: this.cardDetails.Bp1No,
      };
    } else {
      return {
        firstname: p.value.firstName,
        lastname: p.value.lastName,
        birthdate: this.datePipe.transform(
          new Date(p.value.birthday),
          'yyyyMMdd',
        ),
        pid: p.value.idCard,
        laser: p.value.laserId.replace(/-/g, ''),
      };
    }
  }

  dopaSuccess(): boolean {
    return (
      !this.person.get('dopa')?.errors &&
      !this.person.get('dopa')?.value
    );
  }

  checkInvalidForm(): boolean {
    const validControls = [
      'idCard',
      'laserId',
      'firstName',
      'lastName',
      'birthday',
    ];

    return validControls.some(
      (control: string) =>
        this.person.get(control)?.status === 'INVALID',
    );
  }

  swalNoti(type: string, message: string): void {
    this.swal.toastNotification({
      type: type,
      content: message,
    });
  }

  setControlValidation(control: string, required?: boolean): void {
    required
      ? this.person.get(control)?.setValidators(Validators.required)
      : this.person.get(control)?.clearValidators();
    this.person.get(control)?.updateValueAndValidity();
  }

  masked(value: string, mask: string): string {
    const pipe = IMask.createPipe({
      mask: mask,
    });
    return pipe(value);
  }

  fullName(): string {
    return (
      this.getLabelFormDropdownValue(
        this.person.get('titleName').value,
        'name_prefix',
      ) +
      ' ' +
      this.person.get('firstName').value +
      ' ' +
      this.person.get('lastName').value
    );
  }

  dateFormatted(personDate: string, localDate: string): string {
    const date = new Date(personDate);
    const result = date.toLocaleDateString(localDate, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return result;
  }

  convertYearBack(date: string): string {
    if (date) {
      return this.convertService.convertYear(date, 'en-GB', true);
    }
  }

  address(): string {
    const fullAddress = this.person.get('fullAddress')?.value;
    const zipCode = this.person.get('zipCode')?.value;
    const regexPattern = /\d{5}$/;
    const result = fullAddress.match(regexPattern);
    if (result) {
      return fullAddress;
    } else {
      return fullAddress + ' ' + (zipCode ? zipCode : '');
    }
  }

  checkedOnline(dopaType: string): void {
    if (dopaType) {
      this.clearValidators.emit();
    }
  }

  getLabelFormDropdownValue(value: number, type: string): string {
    return this.dropdownData[type].find(
      (dropdown: DropdownItem) => dropdown.value === value,
    ).label;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
