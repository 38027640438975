import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CeleryImportState } from '../../models/manage-types-upload-memo.models';
import { ApiService } from 'src/app/core/http/api.service';

@Component({
  selector: 'app-export-progress',
  templateUrl: './export-progress.component.html',
  styleUrls: ['./export-progress.component.scss'],
})
export class ExportProgressComponent {
  @Input() progressTaskId: string;

  resultTitle = 'ดาวน์โหลดสำเร็จ';
  progressTitle = 'ดาวน์โหลดสำเร็จ';
  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
  ) {}

  isClose(): void {
    this.modalService.dismissAll();
  }

  exportDocumentFromUrl(event): void {
    const objKey = Object.keys(event);
    if (event._state === CeleryImportState.Success) {
      const key = objKey[objKey.length - 1];
      this.apiService.openMediaUrlInNewWindow(event[key]);
      this.isClose();
    }
  }
}
