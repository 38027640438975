import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {
  formatDateToYYYYMMDD,
  isLeapYear,
  isValidLeapDate,
} from '../../modules/memos/service/NgDateParser';

@Injectable({
  providedIn: 'root',
})
export class ConvertDateYearService {
  constructor(private datePipe: DatePipe) {}

  convertYear(
    date: string,
    localTime?: string,
    forSendingToBackend = false,
  ): string {
    //localTime,forSendingToBackend: 'null',true, ->  send original date format yyyy-dd-MM
    //localTime,forSendingToBackend: 'en-GB',true, -> send to backend
    //localTime,forSendingToBackend: 'th-TH',false or null, -> display page
    //localTime,forSendingToBackend: 'th-TH',true, -> display for dropdown calendar buddha
    if (date) {
      const formattedDate = this.datePipe.transform(
        date,
        'yyyy-MM-dd',
      );
      // 29 feb and leap year
      if (isValidLeapDate(formattedDate)) {
        const [yearStr, monthStr, dayStr] = formattedDate
          .split('-')
          .map((str) => parseInt(str));
        return forSendingToBackend
          ? `${
              localTime !== 'th-TH' ? yearStr : yearStr + 543
            }-${monthStr.toString().padStart(2, '0')}-${dayStr
              .toString()
              .padStart(2, '0')}`
          : `${dayStr.toString().padStart(2, '0')}/${monthStr
              .toString()
              .padStart(2, '0')}/${yearStr + 543}`;
      } else {
        const adjustedYear: number = localTime
          ? localTime === 'th-TH'
            ? parseInt(date.slice(0, 4)) + 543
            : parseInt(date.slice(0, 4)) - 543
          : parseInt(date.slice(0, 4));
        const adjustedDateStr: string = adjustedYear + date.slice(4);
        const newDate = new Date(adjustedDateStr);
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const day = newDate.getDate();
        const d = moment({
          year: year,
          month: month,
          date: day,
        });
        if (forSendingToBackend) {
          return d.isValid() ? d.format('YYYY-MM-DD') : '';
        } else {
          return d.isValid() ? d.format('DD/MM/YYYY') : '';
        }
      }
    }
  }

  formatDateTime(date: string, localTime?: string): string {
    if (date) {
      const newDate = new Date(date);
      const currentDate = new Date();
      const year = localTime
        ? localTime === 'th-TH'
          ? newDate.getFullYear() + 543
          : newDate.getFullYear() - 543
        : newDate.getFullYear();
      const month = newDate.getMonth();
      const day = newDate.getDate();
      const hour = currentDate.getHours();
      const minute = currentDate.getMinutes();
      const second = currentDate.getSeconds();
      const millisecond = currentDate.getMilliseconds();
      const d = moment({
        year: year,
        month: month,
        date: day,
        hour: hour,
        minute: minute,
        second: second,
        millisecond: millisecond,
      });
      return d.isValid()
        ? d.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
        : '';
    }
  }

  setDateFormat(date: string): string {
    if (date) {
      const newDate = new Date(date);
      const isLeap = isLeapYear(newDate.getFullYear());
      const year = newDate.getFullYear() + 543;
      const month = newDate.getMonth();
      const day = newDate.getDate();
      const d = moment({
        year: year,
        month: month,
        date: day,
      });
      if (!isLeap) {
        return d.isValid() ? d.format('YYYY-MM-DD') : '';
      } else {
        const leapDate = {
          year,
          month: month + 1,
          day,
        };
        return formatDateToYYYYMMDD(leapDate);
      }
    }
  }

  isDate(str: string) {
    if (typeof str === 'string') {
      const regex =
        /^(\d{4})-(\d{2})-(\d{2})(T\d{2}:\d{2}:\d{2}(\.\d+)?(\+\d{2}:\d{2})?)?$/;
      const match = regex.exec(str);
      if (!match) {
        return false;
      }
      const noSpace = str?.replace(/\s/g, '');
      if (noSpace.length < 3) {
        return false;
      }
      const parsedDate = Date.parse(str);
      return !isNaN(parsedDate) && isFinite(parsedDate);
    }
  }

  getToday(date: string | number | Date): string {
    return this.setDateFormat(
      this.datePipe.transform(date, 'yyyy-MM-dd'),
    );
  }

  ngbDateParse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 3) {
        return {
          year: +dateParts[0],
          month: +dateParts[1],
          day: +dateParts[2],
        };
      }
    }
    return null;
  }
}
