import { Injectable } from '@angular/core';
import { CropperPosition } from 'ngx-image-cropper';

export interface Pixel {
  r: number;
  g: number;
  b: number;
  a: number;
}

@Injectable()
export class ImageProcessingService {
  constructor() {}

  /**
   * Find boundary of signature(type sign now) for use to auto crop image
   * @returns CropperPosition in unit pixel
   */
  async getSignatureBoundary(
    signatureFile: File,
  ): Promise<CropperPosition> {
    const { imageData, width, height } = await this.readImage(
      signatureFile,
    );

    let minX = null;
    let maxX = null;
    let minY = null;
    let maxY = null;

    const calBoundary = (
      type: 'min' | 'max',
      currentPos,
      resultPos,
    ): number => {
      let updateCondition;
      if (type === 'min') {
        updateCondition = currentPos < resultPos;
      } else {
        updateCondition = currentPos > resultPos;
      }
      if (!resultPos) {
        return currentPos;
      } else if (updateCondition) {
        return currentPos;
      } else {
        return resultPos;
      }
    };

    this.processAllPixel(imageData, width, (pixel, x, y) => {
      if (this.isNotBackgroundOrWhite(pixel)) {
        minX = calBoundary('min', x, minX);
        minY = calBoundary('min', y, minY);
        maxX = calBoundary('max', x, maxX);
        maxY = calBoundary('max', y, maxY);
      }
    });

    const allNull = [minX, minY, maxX, maxY].every((v) => v === null);
    if (allNull) {
      return Promise.resolve({
        x1: 0,
        y1: 0,
        x2: width,
        y2: height,
      });
    }

    return Promise.resolve({
      x1: minX - 10,
      y1: minY - 10,
      x2: maxX,
      y2: maxY,
    });
  }

  processAllPixel(
    imageData: ImageData,
    imageWidth: number,
    processFunc: (pixel: Pixel, x: number, y: number) => void,
  ) {
    const data = imageData.data;
    let y = 0;
    let x = 0;
    for (let i = 0; i < data.length; i += 4) {
      const pixel: Pixel = {
        r: data[i],
        g: data[i + 1],
        b: data[i + 2],
        a: data[i + 3],
      };
      x = (i / 4) % imageWidth;
      y = ~~(i / 4 / imageWidth);

      processFunc(pixel, x, y);
    }
  }

  isNotBackgroundOrWhite(px: Pixel): boolean {
    const isBackground = [px.r, px.g, px.b, px.a].every(
      (v) => v === 0,
    );
    const isWhitColor = [px.r, px.g, px.b].every((v) => v === 255);
    return !(isBackground || isWhitColor);
  }

  async readImage(file: File): Promise<{
    imageData: ImageData;
    width: number;
    height: number;
  }> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result as string;
        const width = image.naturalWidth;
        const height = image.naturalHeight;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        const imageData = ctx.getImageData(0, 0, width, height);

        resolve({
          imageData,
          width,
          height,
        });
      };
      reader.readAsDataURL(file);
    });
  }
}
