<form [formGroup]="personalForm"
  #childHeader>
  <ng-container *ngFor="let person of p.controls; let i = index">
    <div formArrayName="people"
      id="scrollMe{{i}}">
      <div class="section d-flex justify-content-between p-2 mb-4"
        *ngIf="p.length > 1 || isLegal">
        <div *ngIf="i===0">
          {{s.delegation.value === delegation.PERSON ? 'รายละเอียดผู้ยื่นคำขอ':'รายละเอียดผู้รับมอบ'}}
        </div>
        <div *ngIf="i!==0">{{isCommercial ? 'รายละเอียดนิติบุคคล':'รายละเอียดผู้ยื่นคำขอ'}}
        </div>
        <div class="d-flex">
          <div class="mr-4"
            *ngIf="i >= 1">{{i}}/{{p.length - 1}}</div>
          <div>
            <em class="far fa-chevron-circle-up mr-2 pointer"
              data-toggle="collapse"
              [attr.data-target]="'#isCollapsed' + i"
              (click)="isCollapsed[i] = true"></em>
            <em class="far fa-chevron-circle-down mr-2 pointer"
              data-toggle="collapse"
              [attr.data-target]="'#isCollapsed' + i"
              (click)="isCollapsed[i] = false"></em>
          </div>
        </div>
      </div>

      <div [formGroupName]="i">
        <div class="d-flex justify-content-between"
          *ngIf="i >= 1">
          <div class="col d-flex align-items-center px-0">
            <div class="personIndex px-2 pt-2 pb-1">รายที่ {{ i }}</div>
            <div class="pt-2 px-2"
              *ngIf="p.length > 2 && state === 1">
              <div ngbDropdown
                class="d-inline-block">
                <em class="fas fa-ellipsis-h pointer"
                  id="dropdownMenu"
                  ngbDropdownToggle></em>
                <div ngbDropdownMenu
                  aria-labelledby="dropdownMenu">
                  <button ngbDropdownItem
                    (click)="removePeopleIndex(i)">
                    Delete
                  </button>
                  <button ngbDropdownItem>Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col d-flex justify-content-end px-0">
            <div class="d-flex mr-4"
              *ngIf="memo?.length >= 1 && filterAppNumber(person, i)">
              <label class="text-nowrap mt-1 mr-2">App No.</label>
              <input type="text"
                autocomplete="off"
                class="form-control"
                [value]="filterAppNumber(person, i)"
                disabled>
            </div>
            <div class="text-right align-self-end"
              *ngIf="!isCommercial">
              <button *ngIf="person.get('cardType').value === cardType.IDCARD"
                type="button"
                class="reader-button"
                (click)="getCardDetails(i)"
                [disabled]="state !== 1">
                <img class="mr-2"
                  alt="sim-card"
                  src="../../../../../assets/images/icons/sim-card.svg" />
                อ่าน Smart Card
              </button>
              <button type="button"
                class="scan-card-button ml-4"
                (click)="onScanCard(i)"
                [disabled]="state !== 1 || person.get('noScanCard')?.value">
                Scan Card
              </button>
              <div class="ml-4 mt-4">
                <input type="checkbox"
                  class="ml-2 mr-2"
                  formControlName="noScanCard"
                  [disabled]="state !== 1">
                <label>ไม่สแกนบัตร</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row"
          *ngIf="!(i >= 1 && isCommercial)">
          <div class="col-sm-12"
            [ngClass]="{'col-md-6': isCollapsed[i] || person.get('laserId')}">
            <div class="form-group mx-1 mb-4 selected-box">
              <label [ngClass]="{'field-require': checkValidator('cardType', i)}">ประเภทบัตร</label>
              <ng-select [items]="cardTypeList[i]"
                bindLabel="label"
                bindValue="value"
                formControlName="cardType"
                (ngModelChange)="onCardTypeChange($event, i)"
                (change)="resetForm(i)">
              </ng-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-group mx-1 mb-4">
              <label [ngClass]="{'field-require': checkValidator('idCard', i)}">หมายเลขบัตร</label>
              <input *ngIf="person.get('cardType').value === cardType.IDCARD"
                type="text"
                autocomplete="off"
                class="form-control"
                formControlName="idCard"
                [imask]="{mask: '0000000000000'}"
                [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                && person.get('idCard').errors }">
              <input *ngIf="person.get('cardType').value !== cardType.IDCARD"
                type="text"
                autocomplete="off"
                class="form-control"
                formControlName="idCard"
                [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                && person.get('idCard').errors }"
                maxlength="20">
              <div class="text-red"
                *ngIf="person.get('idCard').value && person.get('idCard').errors">
                <div *ngIf="!person.get('idCard').errors?.idCard">หมายเลขบัตรประชาชนไม่ถูกต้อง
                  <span *ngIf="person.get('idCard').errors?.minlength">และไม่ครบ 13 หลัก</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 collapsible"
            *ngIf="person.get('laserId')"
            [@collapse]="isCollapsed[i]">
            <div class="form-group mx-1 mb-4">
              <label [ngClass]="{'field-require': isScanCard[i] || checkValidator('laserId', i)}">
                รหัสหลังบัตรประชาชน
              </label>
              <input type="text"
                autocomplete="off"
                class="form-control uppercase"
                formControlName="laserId"
                [imask]="{mask: 'aa0-0000000-00'}"
                [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                && person.get('laserId').errors }">
            </div>
          </div>
          <div class="col-sm-12 collapsible"
            [ngClass]="{'col-md-6': !isCollapsed[i] || person.get('cardType').value === cardType.IDCARD}"
            [@collapse]="isCollapsed[i]">
            <div class="form-group mx-1 mb-4">
              <label>วันที่บัตรหมดอายุ</label>
              <input name="datepicker"
                class="form-control input-lg"
                ngbDatepicker
                container="body"
                #dpCardExp="ngbDatepicker"
                formControlName="cardExp"
                (click)="dpCardExp.toggle();dpCardExp.navigateTo()"
                [footerTemplate]="footerTemplate"
                readonly>
              <div>
                <span class="span-calendar">
                  <em class="fa fa-calendar i-calendar"
                    aria-hidden="true"
                    ngbDatepicker
                    (click)="dpCardExp.toggle();dpCardExp.navigateTo()"></em>
                </span>
                <span class="span-calendar-times"
                  *ngIf="person.get('cardExp').value">
                  <em class="fa fa fa-times i-calendar"
                    aria-hidden="true"
                    (click)="clearDate(person,'cardExp')"></em>
                </span>
              </div>
              <ng-template #footerTemplate>
                <hr class="my-0" />
                <div class="d-flex">
                  <div>
                    <button class="btn button btn-sm m-2"
                      (click)="getToday(person,'cardExp');dpCardExp.close()">
                      Today
                    </button>
                  </div>
                  <div class="ml-auto">
                    <button class="btn outline-sm btn-sm m-2 ml-auto"
                      (click)="dpCardExp.close()">Close</button>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group mx-1 mb-4 selected-box">
              <label [ngClass]="{'field-require': checkValidator('titleName', i)}">คำนำหน้า</label>
              <ng-select [items]="titleNameList"
                bindLabel="label"
                bindValue="value"
                formControlName="titleName"
                (ngModelChange)="patchDefaultToDeliveryAddress(person)"
                [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                && person.get('titleName').errors }">
              </ng-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group mx-1 mb-4">
              <label [ngClass]="{'field-require': checkValidator('firstName', i)}">ชื่อจริง</label>
              <input type="text"
                autocomplete="off"
                class="form-control"
                formControlName="firstName"
                (ngModelChange)="patchDefaultToDeliveryAddress(person)"
                [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                && person.get('firstName').errors }">
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group mx-1 mb-4">
              <label [ngClass]="{'field-require': checkValidator('middleName', i)}">ชื่อกลาง</label>
              <input type="text"
                autocomplete="off"
                class="form-control"
                formControlName="middleName"
                (ngModelChange)="patchDefaultToDeliveryAddress(person)">
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="form-group mx-1 mb-4">
              <label [ngClass]="{'field-require': checkValidator('lastName', i)}">นามสกุล</label>
              <input type="text"
                autocomplete="off"
                class="form-control"
                formControlName="lastName"
                (ngModelChange)="patchDefaultToDeliveryAddress(person)"
                [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                && person.get('lastName').errors }">
            </div>
          </div>
        </div>
        <div class="row collapsible"
          *ngIf="!isCollapsed[i]"
          [@collapse]="isCollapsed[i]">
          <ng-container *ngIf="!(i >= 1 && isCommercial)">
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4 selected-box">
                <label [ngClass]="{'field-require': checkValidator('sex', i)}">เพศ</label>
                <ng-select [items]="sexList"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="sex">
                </ng-select>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4">
                <label [ngClass]="{'field-require': checkValidator('birthday', i)}">วันเกิด</label>
                <input name="datepicker"
                  class="form-control input-lg"
                  ngbDatepicker
                  container="body"
                  #dpBirthday="ngbDatepicker"
                  [maxDate]="setMaxDate()"
                  formControlName="birthday"
                  (click)="dpBirthday.toggle();dpBirthday.navigateTo()"
                  [ngClass]="{ 'is-invalid': (submitted||createRequestService.dopaSubmit) 
                  && person.get('birthday').errors }"
                  (ngModelChange)="getAge($event) < 20 ? openModal(detectAge) : null"
                  readonly>
                <div>
                  <span class="span-calendar">
                    <em class="fa fa-calendar i-calendar"
                      aria-hidden="true"
                      ngbDatepicker
                      (click)="dpBirthday.toggle();dpBirthday.navigateTo()"></em>
                  </span>
                  <span class="span-calendar-times"
                    *ngIf="person.get('birthday').value">
                    <em class="fa fa fa-times i-calendar"
                      aria-hidden="true"
                      (click)="clearDate(person,'birthday')"></em>
                  </span>
                </div>
              </div>
              <ng-template #detectAge>
                <div class="modal-body">
                  <div class="row justify-content-center mb-4">
                    <div>อายุต่ำกว่า 20 ปี</div>
                  </div>
                  <div class="d-flex justify-content-around">
                    <button type="button"
                      class="btn primary-button h-45 my-2 mx-4"
                      (click)="modal.dismiss(); dpBirthday.close()">
                      Consent
                    </button>
                    <button type="button"
                      class="btn primary-button h-45 my-2 mx-4"
                      (click)="modal.dismiss(); dpBirthday.close()">
                      OK
                    </button>
                    <button type="button"
                      class="btn outline-primary-button h-45 my-2 mx-4"
                      (click)="patchBirthDateIsNull(i)">
                      Cancel
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4">
                <label [ngClass]="{'field-require': checkValidator('address', i)}">ที่อยู่</label>
                <input type="text"
                  autocomplete="off"
                  class="form-control"
                  formControlName="address">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4 selected-box">
                <label [ngClass]="{'field-require': checkValidator('province', i)}">จังหวัด</label>
                <ng-select [items]="provinces"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="province"
                  (change)="provinceChange($event, i)"
                  (clear)="provinceChange(null, i)">
                </ng-select>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4 selected-box">
                <label [ngClass]="{'field-require': checkValidator('district', i)}">เขต/อำเภอ</label>
                <ng-select [items]="districts[i] || dropdownData.district"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="district"
                  (change)="districtChange($event, i)"
                  (clear)="districtChange(null, i)">
                </ng-select>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4 selected-box">
                <label [ngClass]="{'field-require': checkValidator('subdistrict', i)}">แขวง/ตำบล</label>
                <ng-select [items]="subdistricts[i] || dropdownData.sub_district"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="subdistrict"
                  (change)="subDistrictChange($event, i)"
                  (clear)="subDistrictChange(null, i)">
                </ng-select>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group mx-1 mb-4 selected-box">
                <label [ngClass]="{'field-require': checkValidator('zipCode', i)}">รหัสไปรษณีย์</label>
                <input type="text"
                  autocomplete="off"
                  class="form-control"
                  formControlName="zipCode"
                  readonly>
              </div>
            </div>
            <div class="col-sm-12 col-md-6"
              *ngIf="person.get('email')">
              <div class="form-group mx-1 mb-4">
                <label [ngClass]="{'field-require': checkValidator('email', i)}">Email</label>
                <input type="text"
                  autocomplete="off"
                  class="form-control"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && person.get('email').errors }">
                <div class="text-red"
                  *ngIf="person.get('email').value && person.get('email').errors">
                  <div>อีเมลไม่ถูกต้อง</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12"
              *ngIf="person.get('phone')"
              [ngClass]="person.get('otp') ? 'col-md-4' : 'col-md-6'">
              <div class="form-group mx-1 mb-4">
                <label [ngClass]="{'field-require': checkValidator('phone', i)}">
                  เบอร์โทรศัพท์มือถือ
                </label>
                <input type="text"
                  autocomplete="off"
                  class="form-control"
                  formControlName="phone"
                  [imask]="{mask: '000-000-0000'}"
                  [ngClass]="{ 'is-invalid': submitted && person.get('phone').errors }">
              </div>
            </div>
            <ng-container *ngIf="person.get('otp')">
              <div class="col-sm-12 col-md-4">
                <div class="form-group mx-1 mb-4">
                  <label [ngClass]="{'field-require': checkValidator('otp', i)}">OTP</label>
                  <input type="text"
                    autocomplete="off"
                    class="form-control"
                    formControlName="otp"
                    [ngClass]="{ 'is-invalid': submitted && person.get('otp').errors }">
                </div>
              </div>
              <div class="col-sm-12 col-md-4 d-flex align-items-center">
                <div class="d-flex pt-2">
                  <div class="text-right">
                    <button type="button"
                      class="otp-button mr-3"
                      (click)="sentOTP(i)">
                      Send OTP
                    </button>
                  </div>
                  <div class="text-right">
                    <button type="button"
                      class="otp-button"
                      (click)="confirmOTP(i)">
                      Confirm
                    </button>
                    <span *ngIf="person.get('otpConfirm').value"
                      class="success pl-2">ยืนยันสำเร็จ</span>
                    <span *ngIf="submitted && person.get('otpConfirm').errors"
                      class="text-red pl-2">
                      กรุณากดยืนยัน OTP
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mb-0 d-flex justify-content-end">
                <div class="col-md-4 otp-checkbox">
                  <input type="checkbox"
                    class="ml-2 mr-2 mb-4"
                    formControlName="confirmOwner"
                    (ngModelChange)="forceOTPConfirm($event, i)">
                  <label>กรณีไม่ได้รับ OTP แต่ Confirm ได้ว่าเป็นเจ้าของข้อมูลจริง</label>
                </div>
              </div>
            </ng-container>

            <div class="col-sm-12">
              <app-check-dopa *ngIf="person.get('cardType').value"
                [cardType]="person.get('cardType').value"
                [personIndex]="i"
                [state]="state"
                [person]="person"
                (clearValidators)="clearVlidators(i)">
              </app-check-dopa>
            </div>
          </ng-container>

          <div class="col-sm-12"
            *ngIf="person.get('legal')">
            <app-legal [submitted]="submitted"
              [legalForm]="person.get('legal')"
              [displaySession]="!(i >= 1 && isCommercial)"
              (legalNameChange)="patchDefaultToDeliveryAddress(person)"></app-legal>
          </div>
          <ng-container *ngIf="person.get('account')">
            <div class="col-sm-12">
              <app-account [submitted]="submitted"
                [accountForm]="person.get('account')"
                [state]="state"></app-account>
            </div>
            <div class="col-sm-12"
              *ngIf="person.get('deliveryAddress')">
              <app-postal-address [submitted]="submitted"
                [postalAddressForm]="person.get('deliveryAddress')"></app-postal-address>
            </div>
          </ng-container>

          <div class="col-sm-12 px-0 d-flex justify-content-end"
            *ngIf="i === p.length - 1 && memo?.length >= 1 && filterAppNumber(person, i)">
            <div class="d-flex mr-4">
              <label class="text-nowrap mt-1 mr-2">App No.</label>
              <input type="text"
                autocomplete="off"
                class="form-control"
                [value]="filterAppNumber(person, i)"
                disabled>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>

<div class="d-flex mb-4"
  *ngIf="p.length > 1 && p.length <= 10 && state === 1">
  <div class="text-center d-flex px-2">
    <button type="button"
      class="btn primary-button py-2"
      (click)="addPersonIndex()">
      <em class="fas fa-plus"></em>
    </button>
  </div>
</div>