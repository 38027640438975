<form [formGroup]="accountForm">
  <div class="section d-flex justify-content-between align-items-center p-2 mb-4">
    <div>รายละเอียดบัญชี</div>
    <div class="d-flex">
      <em class="far fa-chevron-circle-up mr-2 pointer"
        (click)="!isCollapsed ? collapse.toggle() : null"
        [attr.aria-expanded]="isCollapsed"
        aria-controls="collapseExample"></em>
      <em class="far fa-chevron-circle-down mr-2 pointer"
        (click)="isCollapsed ? collapse.toggle() : null"
        [attr.aria-expanded]="isCollapsed"
        aria-controls="collapseExample"></em>
    </div>
  </div>

  <div class="row"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed">
    <div class="col">
      <ng-container *ngFor="let account of accountForm.controls; let accountIndex = index">
        <div class="card mx-5 mb-4"
          [formGroup]="account">
          <div class="title d-flex justify-content-between py-2 px-5 border-bottom">
            <div class="d-flex">
              <div class="align-self-center">
                <em class="fas fa-bars"></em>
              </div>
              <div class="p-2">รายการที่ {{accountIndex+1}}</div>
            </div>
            <div class="delete d-flex"
              *ngIf="state === buttonState.SUBMIT">
              <div class="align-self-center">
                <svg-icon src="assets/images/icons/delete-list.svg"></svg-icon>
              </div>
              <u class="p-2 pointer"
                (click)="deleteAccount(accountIndex)">ลบรายการ</u>
            </div>
          </div>
          <div class="details py-2 px-5 mt-4">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group mx-1 mb-4">
                  <label class="field-require">สมาชิก</label>
                  <ng-select class="selected-box"
                    [items]="members"
                    bindLabel="context.branch_name"
                    bindValue="label"
                    formControlName="member"
                    [ngClass]="{ 'is-invalid': submitted && account.get('member').errors }">
                  </ng-select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group mx-1 mb-4">
                  <label class="field-require">เลขที่บัญชี</label>
                  <input type="text"
                    class="form-control"
                    formControlName="accountNo"
                    [ngClass]="{ 'is-invalid': submitted && account.get('accountNo').errors }">
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group mx-1 mb-4">
                  <label class="field-require">ประเภทสินเชื่อ</label>
                  <ng-select class="selected-box"
                    [items]="credits"
                    bindLabel="context.display_name"
                    bindValue="context.display_name"
                    formControlName="credit"
                    [ngClass]="{ 'is-invalid': submitted && account.get('credit').errors }">
                  </ng-select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6"
                *ngIf="account.get('transferee')">
                <div class="form-group mx-1 mb-4">
                  <label class="field-require">ผู้รับโอน</label>
                  <ng-select class="selected-box"
                    [items]="transferees"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="transferee"
                    [ngClass]="{ 'is-invalid': submitted && account.get('transferee').errors }">
                  </ng-select>
                </div>
              </div>

              <div class="col-sm-12 col-md-6"
                *ngIf="account.get('payOffDebt')">
                <div class="form-group mx-1 mb-4">
                  <label class="field-require">วันที่ชำระหนี้เสร็จสิ้น</label>
                  <input name="datepicker"
                    class="form-control input-lg"
                    ngbDatepicker
                    container="body"
                    #dpPayOffDebt="ngbDatepicker"
                    formControlName="payOffDebt"
                    (click)="dpPayOffDebt.toggle()"
                    [ngClass]="{ 'is-invalid': submitted && account.get('payOffDebt').errors }"
                    [footerTemplate]="footerTemplate"
                    readonly>
                  <div>
                    <span class="span-calendar">
                      <em class="fa fa-calendar i-calendar"
                        aria-hidden="true"
                        ngbDatepicker
                        (click)="dpPayOffDebt.toggle()"></em>
                    </span>
                    <span class="span-calendar-times"
                      *ngIf="account.get('payOffDebt').value">
                      <em class="fa fa fa-times i-calendar"
                        aria-hidden="true"
                        (click)="clearDate()"></em>
                    </span>
                  </div>
                  <ng-template #footerTemplate>
                    <hr class="my-0" />
                    <div class="d-flex">
                      <div>
                        <button class="btn button btn-sm m-2"
                          (click)="getToday(account);dpPayOffDebt.close()">
                          Today
                        </button>
                      </div>
                      <div class="ml-auto">
                        <button class="btn outline-sm btn-sm m-2
                          ml-auto"
                          (click)="dpPayOffDebt.close()">Close</button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>

      <div class="add-list mx-5 mb-4"
        *ngIf="state === buttonState.SUBMIT">
        <div class="row">
          <div class="col">
            <button type="button"
              class="outline-button w-100 py-2"
              (click)="addAccountIndex()">
              <em class="fas fa-plus pr-2"></em>
              เพิ่มบัญชี
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>