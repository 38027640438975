import { Injectable } from '@angular/core';
import { API_URL, HTTP_SERVER } from './api.constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigsService {
  constructor(
    private httpClient: HttpClient,
    private http: ApiService,
  ) {}

  getDataHttpServer(type: string) {
    return this.httpClient.get(HTTP_SERVER.local_port + type);
  }

  getTagVersionDeploy(): Observable<{ version: string }> {
    return this.http.get(API_URL.ncb_deploy_version);
  }
}
