<div class="my-3 box-validate-password"
  *ngIf="passwordPolicy">
  <p class="text-red mb-1"
    *ngIf="passwordPolicy.is_needed_lower_case"
    [ngClass]="{ 'text-green': checkLowerCase }">
    <em class="fa"
      [ngClass]="checkLowerCase ? 'fa-check' : 'fa-times'"
      aria-hidden="true"></em>
    <b class="pl-1">{{'VALIDATION.LOWER-CHARACTER' | translate}}</b>
  </p>
  <p class="text-red mb-1"
    *ngIf="passwordPolicy.is_needed_upper_case"
    [ngClass]="{ 'text-green': checkUpperCase }">
    <em class="fa"
      [ngClass]="checkUpperCase ? 'fa-check' : 'fa-times'"
      aria-hidden="true"></em>
    <b class="pl-1">{{'VALIDATION.UPPER-CHARACTER' | translate}}</b>
  </p>
  <p class="text-red mb-1"
    *ngIf="passwordPolicy.is_needed_digit"
    [ngClass]="{ 'text-green': checkNumber }">
    <em class="fa"
      [ngClass]="checkNumber ? 'fa-check' : 'fa-times'"
      aria-hidden="true"></em>
    <b class="pl-1">{{'VALIDATION.DIGIT-CHARACTER' | translate}}</b>
  </p>
  <p class="text-red mb-1"
    *ngIf="passwordPolicy.is_needed_special_case"
    [ngClass]="{ 'text-green': checkSymbols }">
    <em class="fa"
      [ngClass]="checkSymbols ? 'fa-check' : 'fa-times'"
      aria-hidden="true"></em>
    <b class="pl-1">{{'VALIDATION.SPECIAL-CHARACTER' | translate}}</b>
  </p>
  <p class="text-red mb-0"
    [ngClass]="{ 'text-green': checkLength }">
    <em class="fa"
      [ngClass]="checkLength ? 'fa-check' : 'fa-times'"
      aria-hidden="true"></em>
    <b class="pl-1">{{
      'VALIDATION.LENGTH-CHARACTER' | translate
      }} {{passwordPolicy.minimum_length}} {{'VALIDATION.characters'|translate}}</b>
  </p>
</div>