<ng-template #deleteConfirmationModal
  let-c="close"
  let-d="dismiss">
  <div class="modal-header red">
    <h4 class="modal-title">
      {{header|translate}}
    </h4>
    <button type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')">
      <em class="fas fa-times"></em>
    </button>
  </div>
  <div class="modal-body text-center f-18">
    {{text|translate}}
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm"
      [style.--c]="themeList?.header_table_color"
      (click)="c()">
      {{'MEMOS.CANCEL'|translate}}
    </button>
    <button class="btn btn-confirm"
      type="button"
      appSetTheme
      (click)="submit()">
      {{'MEMOS.CONFIRM'|translate}}
    </button>
  </div>
</ng-template>

<em *ngIf="type==='icon' && !useNewDesign"
  class="fas fa-trash icon-btn red p-0"
  (click)="$event.stopPropagation(); open(deleteConfirmationModal)">
  <em class="fas fa-trash"
    *ngIf="!useNewDesign"></em>
</em>

<svg-icon *ngIf="type==='icon' && useNewDesign"
  class="size-svg pointer"
  src="assets/svg/delete.svg"
  (click)="open(deleteConfirmationModal)"></svg-icon>

<!-- <img class="pointer" *ngIf="type==='icon' && useNewDesign" src="assets/images/icons/delete.svg"
     (click)="open(deleteConfirmationModal)"> -->

<button *ngIf="type === 'text'"
  class="btn btn-danger p-btn"
  (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-w-100'"
  class="btn btn-danger w-100"
  (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-memo'"
  class="btn btn-danger del-btn w-auto"
  (click)="open(deleteConfirmationModal)">
  {{ "MEMOS.DEL-MEMO" | translate }}
</button>

<div *ngIf="type === 'x-icon'"
  (click)="open(deleteConfirmationModal)">
  <em class="fas fa-times pointer"></em>
</div>

<button *ngIf="type === 'tag'"
  class="tag red pointer"
  (click)="open(deleteConfirmationModal)">
  {{ "CREATE-USER.DELETE"|translate }}
</button>