import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { User } from '../../../shared/models/user.models';
import { WebSocketSubject } from 'rxjs/webSocket';
import { WebsocketMessage } from './navbar.model';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private setnavbar = new BehaviorSubject<boolean>(false);
  // tslint:disable-next-line:variable-name
  private _websocketSubject: WebSocketSubject<WebsocketMessage>;
  public websocketSubject = new Subject<WebsocketMessage>();
  // private websocketUrl = environment.websocketUrl;

  data = this.setnavbar.asObservable();
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe((newValue) => {
      if (newValue) {
        // this.connectToWebsocket(newValue);
      }
    });
  }

  setActiveSidebar(active: boolean) {
    this.setnavbar.next(active);
  }

  private connectToWebsocket(token: User): void {
    if (this._websocketSubject) {
      // unsubscribe old value
      this._websocketSubject.unsubscribe();
    }
    // this._websocketSubject = webSocket(
    //   this.websocketUrl + '?uid=' + token.access
    // );
    this._websocketSubject.subscribe(
      (value) => {
        this.websocketSubject.next(value);
      },
      (err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete'), // Called when connection is closed (for whatever reason).
    );
  }
}
