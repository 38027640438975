import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { CreateRequestService } from 'src/app/modules/create-request/service/create-request.service';
import {
  CardType,
  CreateRequest,
  Delegation,
  MemoReceipt,
  PersonType,
  RequestType,
} from 'src/app/modules/create-request/shared/create-request.model';
import { collapse } from 'src/app/shared/animations/collapse';
import { DropdownItem } from 'src/app/shared/models/common.model';
import { ConvertDateYearService } from 'src/app/shared/service/convert-date-year.service';
import { DropdownService } from 'src/app/shared/service/dropdown.service';
import { ErrorMessageService } from 'src/app/shared/service/error-message.service';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  animations: [collapse],
})
export class DetailsComponent implements OnInit, OnDestroy {
  modal: NgbModalRef;
  sectionForm: FormGroup;
  personalForm: FormGroup;
  subscription: Subscription = new Subscription();
  cardType = CardType;
  requestType = RequestType;
  personType = PersonType;
  delegation = Delegation;
  createRequest = CreateRequest;
  titleNameList: DropdownItem[];
  sexList: DropdownItem[];
  provinces: DropdownItem[];
  districts: DropdownItem<number, any>[][] = [];
  subdistricts: DropdownItem<number, any>[][] = [];
  cardTypeList = [];
  isCollapsed: boolean[] = [];
  isInvalid: boolean[] = [];
  ref_code: string;
  dropdownData: {
    card_type: any;
    gender_type: DropdownItem<number, any>[];
    province: DropdownItem<number, any>[];
    district: DropdownItem<number, any>[];
    sub_district: DropdownItem<number, any>[];
  };
  faceDetect: { isMatch: boolean }[];

  @Input() submitted: boolean;
  @Input() path: string;
  @Input() isNextStage: boolean;
  @Input() isScanCard: boolean[];
  @Input() readSmartCard: boolean[];
  @Input() isClearForms: boolean[];
  @Input() isLegal: boolean;
  @Input() state: number;
  @Input() memo: MemoReceipt[];
  @Input() isCommercial: boolean;
  @Output() addPerson = new EventEmitter();
  @Output() scanCardIndex = new EventEmitter();
  @Output() smartCardIndex = new EventEmitter();
  @Output() actionType = new EventEmitter();
  @ViewChild('childHeader', { static: true }) childHeader: ElementRef;

  constructor(
    public createRequestService: CreateRequestService,
    private dropdownService: DropdownService,
    private router: Router,
    private modalService: NgbModal,
    private convertService: ConvertDateYearService,
    private errorMessageService: ErrorMessageService,
    private spinnerService: SpinnerService,
    private swal: SweetAlertService,
  ) {}

  ngOnInit() {
    this.subscribeService();
    this.getPrefixNameItem();
  }

  subscribeService(): void {
    this.subscription.add(
      this.createRequestService.dropDownData$.subscribe((res) => {
        this.dropdownData = res;
        this.setDataFromDropDown();
      }),
    );
    this.subscription.add(
      this.createRequestService.getSectionForm$.subscribe((form) => {
        this.sectionForm = form;
      }),
    );
    this.subscription.add(
      this.createRequestService.getPersonalForm$.subscribe((form) => {
        this.personalForm = form;
        this.isCollapsed = [];
        this.setCardTypeItem(this.dropdownData.card_type);
        this.setDefaultCardType(0);
      }),
    );
    this.subscription.add(
      this.createRequestService.faceRecognition$.subscribe((face) => {
        this.faceDetect = face;
      }),
    );
  }

  setDefaultCardType(index: number): void {
    const cardType = this.p?.controls[index].get('cardType');
    const idCard = this.p?.controls[index].get('idCard');
    cardType.setValue(CardType.IDCARD);
    this.cardTypeValidators(cardType.value, idCard);
  }

  cardTypeValidators(
    cardType: string,
    idCard: AbstractControl,
  ): void {
    idCard?.setValidators([
      Validators.required,
      this.validationIdCard(cardType),
    ]);
    idCard?.updateValueAndValidity();
  }

  validationIdCard(cardType: string) {
    return (
      control: AbstractControl,
    ): { [key: string]: any } | null => {
      const idCard = control.value;
      if (cardType === CardType.IDCARD) {
        if (!idCard?.match(/^[0-9]{13}$/g)) {
          return {
            idCard: false,
            minlength: Validators.minLength(13),
          };
        }
        let sum = 0;
        for (let i = 0; i < 12; i++) {
          sum += Number.parseInt(idCard?.charAt(i)) * (13 - i);
        }
        if (
          (11 - (sum % 11)) % 10 ===
          Number.parseInt(idCard?.charAt(12))
        ) {
          return;
        }
        return { idCard: false };
      }
    };
  }

  setDataFromDropDown(index = 0): void {
    this.sexList = this.dropdownData.gender_type;
    this.provinces = this.dropdownData.province;
    this.districts[index] = this.dropdownData.district;
    this.subdistricts[index] = this.dropdownData.sub_district;
  }

  setCardTypeItem(type: any[]): void {
    if (
      this.sectionForm.get('requestType')?.value ===
      RequestType.REGISTERMOBILE
    ) {
      this.cardTypeList[0] = type.filter(
        (item) => item.value === CardType.IDCARD,
      );
    } else {
      this.cardTypeList[0] = type;
    }
  }

  getPrefixNameItem(): void {
    const data = {
      type: 'name_prefix',
      applicant_type: 'customer',
    };
    this.dropdownService.getDropdown(data).subscribe((res) => {
      this.titleNameList = res.name_prefix;
    });
  }

  onCardTypeChange(card: string, index: number): void {
    const people = this.p.get(String(index)) as FormGroup;
    const controls = ['laserId', 'dopa'];
    controls.forEach((controlName: string) => {
      if (card !== CardType.IDCARD) {
        people.removeControl(controlName);
      } else {
        people.addControl(
          controlName,
          new FormControl(null, Validators.required),
        );
      }
    });
    this.cardTypeValidators(card, people.get('idCard'));
  }

  patchDefaultToDeliveryAddress(people: FormGroup): void {
    const legal = people.get('legal');
    people.get('deliveryAddress')?.patchValue({
      titleName: legal
        ? legal.get('title')?.value
        : people.get('titleName')?.value,
      firstName: legal
        ? legal.get('legalName')?.value
        : people.get('firstName')?.value,
      middleName: legal ? '' : people.get('middleName')?.value,
      lastName: legal ? '' : people.get('lastName')?.value,
    });
  }

  resetForm(index: number): void {
    this.resetCardDetail(index);
    this.createRequestService.dopaSubmit = false;
    const people = this.p.get(String(index)) as FormGroup;
    Object.keys(people.controls).forEach((key) => {
      if (key !== 'cardType') {
        people.get(key).reset();
      }
    });
    this.isCollapsed[index] = false;
  }

  resetCardDetail(index: number): void {
    this.createRequestService.updateCardDetails(null);
    this.faceDetect[index].isMatch = null;
    this.createRequestService.faceRecognition.next(this.faceDetect);
  }

  filterAppNumber(person, index: number): string {
    const cardNo = person.get('legal')
      ? person.get('legal').get('legalNo')?.value
      : person.get('idCard')?.value;
    return (
      this.memo.find(
        (memo) =>
          memo.card_number === cardNo && memo.person_index === index,
      )?.memo_number || null
    );
  }

  addPersonIndex(): void {
    this.addPerson.emit();
  }

  removePeopleIndex(index: number): void {
    this.p.removeAt(index);
    this.isCollapsed[this.p.length - 1] = false;
  }

  getCardDetails(index: number): void {
    this.readSmartCard[index] = true;
    this.smartCardIndex.emit(index);
  }

  onScanCard(index: number): void {
    this.isScanCard[index] = true;
    this.scanCardIndex.emit(index);
  }

  checkValidator(controls: string, index: number): boolean {
    const validator = this.p.controls[index].get(controls)?.validator;
    return Boolean(validator);
  }

  provinceChange(event: DropdownItem, index: number): void {
    this.p.controls[index].get('province').patchValue(event?.value);
    const types = ['district', 'subdistrict', 'zipCode'];
    this.patchAddressNull(types, index);
    if (event) {
      const data = {
        type: 'district',
        province: event.value,
      };
      this.spinnerService.show();
      this.dropdownService.getDropdown(data).subscribe(
        (res) => {
          this.districts[index] = res.district;
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.districts[index] = this.dropdownData.district;
      this.districtChange(null, index);
    }
  }

  districtChange(event: DropdownItem, index: number): void {
    this.p.controls[index].get('district').patchValue(event?.value);
    const types = ['subdistrict', 'zipCode'];
    this.patchAddressNull(types, index);
    if (event) {
      const data = {
        type: 'sub_district',
        district: event.value,
      };
      this.spinnerService.show();
      this.dropdownService.getDropdown(data).subscribe(
        (res) => {
          this.subdistricts[index] = res.sub_district;
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.subdistricts[index] = this.dropdownData.sub_district;
      this.subDistrictChange(null, index);
    }
  }

  subDistrictChange(event: DropdownItem, index: number): void {
    const control = this.p.controls[index];
    const subdistrictControl = control.get('subdistrict');
    const zipCodeControl = control.get('zipCode');

    subdistrictControl.patchValue(event?.value);
    const types = ['zipCode'];
    this.patchAddressNull(types, index);

    if (event) {
      const code = this.getCodeFromEventOrSubdistricts(event, index);
      zipCodeControl.patchValue(code);
    }
  }

  patchAddressNull(types: string[], index: number): void {
    types.forEach((type) => {
      this.p.controls[index].get(type).patchValue(null);
    });
  }

  getCodeFromEventOrSubdistricts(
    event: DropdownItem,
    index: number,
  ): string | null {
    if (this.readSmartCard[index]) {
      return event?.context?.postal_code || null;
    } else {
      const selectedSubdistrict = this.subdistricts[index].find(
        (item) => item.value === event.value,
      );
      return selectedSubdistrict?.context?.postal_code || null;
    }
  }

  sentOTP(index: number): void {
    const phone = this.p?.controls[index]
      .get('phone')
      ?.value.replace(/-/g, '');
    const data = new FormData();
    data.set('phone_number', phone);
    this.createRequestService.sentOTP(data).subscribe(
      (res) => {
        this.ref_code = res.ref;
        this.swal.toastNotification({
          type: 'success',
          content: 'Send successfully!',
        });
      },
      (error) => {
        this.errorMessageService.errorMessage(error);
      },
    );
  }

  confirmOTP(index: number): void {
    const person = this.p?.controls[index];
    const otp = person.get('otp').value;
    const data = new FormData();
    data.set('ref', this.ref_code);
    data.set('otp', otp);
    this.createRequestService.validateOTP(data).subscribe(
      (res) => {
        person.get('otpConfirm')?.setValue(true);
      },
      (error) => {
        this.errorMessageService.errorMessage(error);
      },
    );
  }

  forceOTPConfirm(event: boolean, index: number): void {
    const includes = ['otp', 'otpConfirm'];
    includes.forEach((key: string) => {
      const field = this.p.controls[index].get(key);
      if (event) {
        field.patchValue(null);
        field.clearValidators();
      } else {
        field.setValidators(Validators.required);
      }
      field.updateValueAndValidity();
    });
  }

  getAge(birthDate: string | number | Date): number {
    const today = new Date();
    const birthOfDate = new Date(birthDate);
    if (birthDate) {
      let age =
        today.getFullYear() - (birthOfDate.getFullYear() - 543);
      const m = today.getMonth() - birthOfDate.getMonth();
      if (
        m < 0 ||
        (m === 0 && today.getDate() < birthOfDate.getDate())
      ) {
        age--;
      }
      return age;
    }
  }

  openModal(content): void {
    this.modal = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      windowClass: 'custom-model',
    });
  }

  patchBirthDateIsNull(index: number): void {
    if (!this.p.controls[index].get('isSmartCard').value) {
      this.p.controls[index].get('birthday').patchValue(null);
    }
    this.modal.dismiss();
    this.isCancel();
  }

  setMaxDate(): NgbDateStruct {
    const current = new Date();
    return {
      year: current.getFullYear() + 543,
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  clearVlidators(index: number): void {
    const people = this.p.get(String(index)) as FormGroup;
    Object.keys(people.controls).forEach((key) => {
      if (
        key === 'laserId' ||
        (this.isCommercial &&
          this.s.delegation.value === Delegation.PERSON)
      ) {
        people.get(key).clearValidators();
        people.get(key).updateValueAndValidity();
      }
    });
  }

  getToday(dataForm: AbstractControl, keyPatch: string): void {
    const today = this.convertService.getToday(new Date());
    dataForm.patchValue({ [keyPatch]: today });
  }

  clearDate(dataForm: AbstractControl, keyPatch: string): void {
    dataForm.patchValue({ [keyPatch]: null });
  }

  isCancel(): void {
    this.router.navigate(['/', this.path]).then(() => {
      window.location.reload();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get s(): { [key: string]: AbstractControl } {
    return this.sectionForm.controls;
  }

  get p(): FormArray {
    return this.personalForm?.get('people') as FormArray;
  }
}
