import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';

@Directive({
  selector: '[appCheckPermission]',
})
export class PermissionDirective {
  private permissions: string[] = [''];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permission: PermissionService,
  ) {}

  @Input()
  set appCheckPermission(val: {
    appLabel: string[];
    role?: string[];
  }) {
    this.permissions = val.appLabel;
    if (val.role) {
      this.permissions = [...this.permissions, ...val.role];
    }
    this.updateView();
  }

  private async updateView() {
    const hasPermission = await this._checkPermission();
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private _checkPermission() {
    return this.permission.checkAccess(this.permissions);
  }
}
