<div class="row justify-content-end mx-2 my-5">
  <div class="col-sm-12 col-md-6 px-0">
    <div class="row mb-lg-3 justify-content-end"
      *ngIf="!isNextStage">
      <div class="col-12 col-lg-4 text-center px-2">
        <button type="button"
          class="btn"
          [ngClass]="state === buttonState.SUBMIT ? 'primary-button' : 'outline-button'"
          (click)="onButtonClick(buttonAction.SAVEREQUEST)"
          [disabled]="state !== buttonState.SUBMIT">
          บันทึกคำขอ
        </button>
      </div>
      <div class="col-12 col-lg-4 text-center px-2">
        <button type="button"
          class="btn"
          [ngClass]="state !== buttonState.SUBMIT ? 'primary-button' : 'outline-button'"
          (click)="onButtonClick(buttonAction.PRINTDOCUMENT)"
          [disabled]="state === buttonState.SUBMIT">
          พิมพ์ใบคำขอ
        </button>
      </div>
      <div class="col-12 col-lg-4 text-center px-2"
        *ngIf="path !== createRequest.INFORMATION && !registerMobile">
        <button type="button"
          class="btn outline-button"
          [ngClass]="state === buttonState.RECEIPT  || canVerifyInformation ? 'primary-button' : 'outline-button'"
          (click)="onButtonClick(buttonAction.REQUESTRECEIPT)"
          [disabled]="state !== buttonState.RECEIPT && !canVerifyInformation">
          ออกใบเสร็จ
        </button>
      </div>
    </div>
    <div class="row mb-4 justify-content-end"
      [ngClass]="{'d-flex justify-content-lg-center':state !== buttonState.SUBMIT}">
      <div class="col-12 col-lg-4 text-center px-2"
        *ngIf="!isNextStage && path !== createRequest.INFORMATION && !registerMobile">
        <button type="button"
          class="btn"
          [ngClass]="canVerifyInformation ? 'primary-button' : 'outline-button'"
          (click)="onButtonClick(buttonAction.VERIFY)"
          [disabled]="!canVerifyInformation">
          ยืนยันตรวจสอบข้อมูล
        </button>
      </div>
      <div class="col-12 col-lg-4 text-center px-2"
        [class.d-none]="state !== buttonState.SUBMIT">
        <button type="button"
          class="btn outline-button"
          (click)="onButtonClick(buttonAction.CLEARFORM)">
          ล้างข้อมูล
        </button>
      </div>
      <div class="col-12 col-lg-4 text-center px-2">
        <button type="button"
          class="btn"
          [ngClass]="isNextStage ? 'outline-button' : 'primary-button'"
          (click)="onButtonClick(buttonAction.CLOSE)">
          ปิด
        </button>
      </div>
      <div class="col-12 col-lg-4 text-center px-2"
        *ngIf="isNextStage">
        <button type="button"
          class="btn primary-button"
          (click)="onButtonClick(buttonAction.NEXT)">
          ถัดไป
        </button>
      </div>

    </div>
  </div>
</div>