import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ThemeList, ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() boundaryLinks: boolean;
  @Input() count: number;
  @Input() ellipses: boolean;
  @Input() rotate: boolean;
  @Input() pageSize: number;
  @Input() newDesign = false;
  @Input() maxSize = 5;
  @Output() pageSizeChange = new EventEmitter();

  @Input() page: number;
  @Output() pageChange = new EventEmitter();
  @Input() noneSize: boolean;
  isMobile = this.themeService.isMobile();
  themeList: ThemeList;
  paginationDropdown = ['10', '20', '30', '40', '50'];

  constructor(private themeService: ThemeService) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  changePageSizeEmit(e) {
    this.page = 1;
    this.pageSize = e;
    this.pageSizeChange.emit(this.pageSize);
  }

  changePageEmit() {
    this.pageChange.emit(this.page);
  }
}
