<label class="col-form-label mb-2">
  {{"UPLOAD.Import Excel File"|translate}}
</label>
<div class="form-group">
  <div>
    <ngx-file-drop class="ngx-file-drop "
      dropZoneClassName="custom-drop-file"
      dropZoneLabel="Browse or Drop files here"
      [disabled]="disableUpload"
      (click)="multipleFileUpload.click()"
      (onFileDrop)="dropped($event)">
      <ng-template ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
        <svg-icon src="assets/svg/upload.svg"
          [svgStyle]="{ 'fill': '#2D5B8F', 'height.px': 20, 'width.px': 20}">
        </svg-icon>
        <span class="cus-blue pl-2">
          {{"UPLOAD.Browse or Drop files here"|translate}}
        </span>
      </ng-template>
    </ngx-file-drop>
    <input type="file"
      hidden
      accept=".xlsx, .xls, .csv"
      #multipleFileUpload
      (change)="selectFile($event)"
      [disabled]="disableUpload"
      #fileSignature>
    <div *ngIf="fileInput"
      class="mt-2">
      <strong class="pointer text-blue"
        (click)="downloadFile(fileInput)">
        {{fileInput.name}}
        <a class="text-red"
          (click)="deleteFile(fileSignature)">
          <em class="fas fa-times"></em> 
        </a>
      </strong>
    </div>
  </div>
</div>