import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  requestHeader(): HttpHeaders {
    const headers = new HttpHeaders().set('Accept-Language', 'th');
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return headers;
  }

  get<T>(url: string, params?: any, headers?: any): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {
      headers: headers ? headers : this.requestHeader(),
      params: { ...params },
    });
  }

  getBlob<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  getMedia<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  printFile<T>(url, params?): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  post<T>(url: string, item: any, headers?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  put<T>(url: string, item: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, item, {
      headers: this.requestHeader(),
    });
  }

  patch<T>(url: string, item: any): Observable<T> {
    return this.http.patch<T>(this.baseUrl + url, item, {
      headers: this.requestHeader(),
    });
  }

  delete<T>(url: string, item?: any): Observable<any> {
    return this.http.delete(this.baseUrl + url, {
      body: item,
      headers: this.requestHeader(),
    });
  }

  download<T>(
    url: string,
    params?: { [type: string]: string },
  ): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  pdfPost<T>(url: string, data?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, data, {
      headers: this.requestHeader(),
    });
  }

  postResponseBlob<T>(url: string, data?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, data, {
      headers: this.requestHeader(),
      responseType: 'blob' as 'json',
    });
  }

  // Check Mobile Operating system
  getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/.test(userAgent)) {
      return 'Android';
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return null;
  }

  isMobileOperationSystem(): boolean {
    return this.getMobileOperatingSystem() != null;
  }

  openMediaUrlInNewWindow(mediaUrl: any) {
    if (mediaUrl == null) return;

    mediaUrl = mediaUrl.trim();
    if (mediaUrl.length === 0) return;

    // open new window here to prevent popup-block.
    // default page will be MediaWindowComponent in file 'app-routing.module.ts'
    // because mobile device cannot preview pdf and it will automatically download file.
    if (!mediaUrl.includes('.pdf')) {
      const parts = mediaUrl.split('/');
      // Get the last part which is the filename
      const filename = parts[parts.length - 1];
      this.http
        .get(mediaUrl, { responseType: 'blob' })
        .subscribe((blob: Blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
    } else {
      const newWindow: any = window.open('/window-media');

      this.getMedia(mediaUrl, { responseType: 'blob' }).subscribe(
        (response: Blob | any) => {
          // this will fix Adblock block new window.
          // more info: https://stackoverflow.com/questions/43283454/open-blob-objecturl-in-chrome

          const checkReadyState = () => {
            if (newWindow.document.readyState === 'complete') {
              newWindow.removeEventListener('load', checkReadyState);
              this.setWindowLocation(response, newWindow);
            }
          };
          newWindow.addEventListener('load', checkReadyState);
          newWindow.location = URL.createObjectURL(response);
        },
        (error: any) => {
          console.error('Failed to fetch media:', error);
        },
      );
    }
  }

  setWindowLocation(response: Blob, newWindow: Window): void {
    const objectURL = URL.createObjectURL(response);

    // Navigate the new window to the object URL
    newWindow.location.href = objectURL;
    newWindow.focus();

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(objectURL);
  }
}
