import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ITreeOptions,
  TreeComponent,
} from '@circlon/angular-tree-component';
import * as _ from 'lodash';
import { FormControl, FormGroupDirective } from '@angular/forms';
import {
  UserAccessManagement,
  UserAccessNodeType,
} from '../../models/user.models';
import { DepartmentService } from '../../../modules/department/department.service';
import { UsersService } from '../../../modules/users/service/users.service';

@Component({
  selector: 'app-user-access-tree-dropdown',
  templateUrl: './user-access-tree-dropdown.component.html',
  styleUrls: ['./user-access-tree-dropdown.component.scss'],
})
export class UserAccessTreeDropdownComponent implements OnInit {
  @ViewChild('treeRoot', { static: false })
  private tree: TreeComponent;

  @Input() multiple = true;

  @Input() placeholder = '';
  @Input() form: FormControl;
  @Input() defaultValue;
  @Input() disable = false;

  @Input() isAllItems = false;
  nodeData: [UserAccessManagement];
  @Input() nodeDataType: string;

  @Output() modelChange = new EventEmitter<
    number[] | number | string
  >();
  model: { id: number; name: string }[] | number[] = [];
  isShow = false;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };

  nodes;
  items = [];
  loading = false;
  formDirective: FormGroupDirective;
  allNodesLength = 0;

  constructor(
    private departmentService: DepartmentService,
    private cd: ChangeDetectorRef,
    private userService: UsersService,
  ) {}

  ngOnInit(): void {
    this.getUserManagementDropDowns();
  }

  nodeInit(): void {
    this.nodes = _.cloneDeep(this.nodeData);
    this.loading = true;
    if (this.form && this.form.value?.length) {
      this.model = this.form.value;
      this.mapNameFromModel();
    } else if (this.defaultValue) {
      this.items = this.defaultValue;
    }
    this.loading = false;
  }

  initNodeData(endpoint: string) {
    this.userService
      .getAccessPermissionManagement(endpoint)
      .subscribe((res: [UserAccessManagement]) => {
        this.nodeData = res;
        this.nodeInit();
      });
  }

  getUserManagementDropDowns() {
    this.loading = false;
    switch (this.nodeDataType) {
      case UserAccessNodeType.ACCESS_MENU:
        this.initNodeData('manage-menu');
        break;
      case UserAccessNodeType.ACCESS_REPORT:
        this.initNodeData('manage-report');
        break;
      case UserAccessNodeType.ACCESS_WEB_CONFIG:
        this.initNodeData('manage-web-config');
        break;
      default:
        break;
    }
  }

  mapNameFromModel(): void {
    this.items = this.model;
    this.emitData();
  }

  nodeChecked(node, check, children = true): void {
    this._nodeChecked(node, check, children);
    this.emitData();
  }

  emitData(): void {
    const value: any = this.multiple
      ? this.items
          .filter(
            (item) => item.id === item.id && item.app_label !== null,
          )
          .map((item) => item.app_label)
      : this.items
          .filter(
            (item) => item.id === item.id && item.app_label !== null,
          )
          .map((item) => item.app_label)
          .join('');
    const flattenedArray: any = Array.from(new Set(value.flat()));
    this.modelChange.emit(flattenedArray);
    if (this.form) {
      this.form.setValue(flattenedArray);
    }
  }

  _nodeChecked(node, check, children): void {
    if (check) {
      if (!this.tree.treeModel.isSelected(node)) {
        this.tree.treeModel.setSelectedNode(node, true);
        this.expandAllChain(node);
      }

      if (
        this.items.find((obj) => obj.id === node.data.id) ===
        undefined
      ) {
        this.items.push(node.data);
      }

      if (children) {
        node.children
          .filter((obj) => obj !== undefined)
          .forEach((element) => {
            this._nodeChecked(element, true, true);
          });
      }
    } else {
      this.items = this.items.filter(
        (obj) => obj.id !== node.data.id,
      );
      if (children) {
        node.children.forEach((element) => {
          this.tree.treeModel.setSelectedNode(element, false);
        });
      }
    }
  }

  onActivate(event): void {
    this.tree.treeModel.setExpandedNode(event.node, true);
  }

  deleteItem(index: number, nodeId: number): void {
    this.items.splice(index, 1);
    const value: any = this.multiple
      ? this.items
          .filter(
            (item) => item.id === item.id && item.app_label !== null,
          )
          .map((item) => item.app_label)
      : this.items
          .filter(
            (item) => item.id === item.id && item.app_label !== null,
          )
          .map((item) => item.app_label)
          .join('');
    const flattenedArray: any = Array.from(new Set(value.flat()));
    this.modelChange.emit(flattenedArray);
    if (this.form) {
      this.form.setValue(flattenedArray);
    }

    const treeNode = this.tree.treeModel.getNodeById(nodeId);
    this.tree.treeModel.setSelectedNode(treeNode, false);
  }

  showDropdown(): void {
    this.isShow = !this.isShow;
    this.cd.detectChanges();
    if (this.isShow && this.tree) {
      if (typeof this.items === 'object') {
        this.items.forEach((element) => {
          this.setNode(element.id ? element.id : element);
        });
      }
    }
  }

  setNode(id: number | string, value = true): void {
    if (this.tree && this.tree.treeModel) {
      const node = this.tree.treeModel.getNodeById(id);
      this.expandAllChain(node);
      this.tree.treeModel.selectedLeafNodeIds = Object.assign(
        {},
        this.tree.treeModel.selectedLeafNodeIds,
        { [node.id]: value },
      );
    }
  }

  expandAllChain(node) {
    while (node.parent !== null) {
      this.tree.treeModel.setExpandedNode(node.parent, true);
      node = node.parent;
    }
  }

  clearValue(): void {
    this.items = [];
    this.modelChange.emit('');
    if (this.form && this.form.value) {
      this.form.setValue('');
    }
  }
}
