import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '../../models/drf-response.models';
import { ManageTypesUploadMemoService } from '../../service/manage-types-upload-memo.service';
import { buildErrorHTMLMessage } from '../../utils/common.util';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-progress',
  templateUrl: './import-progress.component.html',
  styleUrls: ['./import-progress.component.scss'],
})
export class ImportProgressComponent implements OnInit, OnDestroy {
  celeryImportState = CeleryImportState;

  sendingTaskInterval;
  sendingTaskDetail: CeleryTaskStatus;

  // refresh less than 1-2 secs is too much. it should be more than 3 secs.
  @Input() getAllRes = false;
  @Input() timeout = 3000;
  @Input() resultTitle: string;
  @Input() taskId: string;
  @Input() progressExport?: string | boolean;
  @Input() displayTitle = true;
  @Output() sent = new EventEmitter();
  @Output() export = new EventEmitter();

  constructor(
    private apiService: ManageTypesUploadMemoService,
    private modalService: NgbModal,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.cancelSendingTask();
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.subscribe(this.taskId);
  }

  subscribe(taskId: string): void {
    this.sendingTaskInterval = undefined;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };

    this.subscribeSendingTaskStatus(taskId);
  }

  subscribeSendingTaskStatus(taskId: string): void {
    this.sendingTaskInterval = setInterval(() => {
      this.apiService.getCeleryTaskStatus(taskId).subscribe((res) => {
        // update progress
        this.sendingTaskDetail = res;

        // unsubscribe when success or failure
        if (
          res._state === CeleryImportState.Success ||
          res._state === CeleryImportState.Failure
        ) {
          if (res._state === CeleryImportState.Success) {
            this.export.emit(this.sendingTaskDetail);
          }
          this.sent.emit(this.getAllRes ? res : res._state);
          clearInterval(this.sendingTaskInterval);
        }

        if (res._state === CeleryImportState.Failure) {
          res.detail = buildErrorHTMLMessage(res.detail);
        }
      });
    }, this.timeout);
  }

  cancelSendingTask(): void {
    if (this.sendingTaskInterval) {
      clearInterval(this.sendingTaskInterval);
    }
  }

  replaceUnderscore(text: string, withChar = ' '): string {
    return text.split('_').join(withChar);
  }

  navigateToExportResult(): void {
    this.modalService.dismissAll();
    this.router.navigate(['/memo-report/task-results']);
  }

  ngOnDestroy(): void {
    this.cancelSendingTask();
  }

  get handleProgressExport(): string {
    if (typeof this.progressExport === 'string') {
      return this.progressExport;
    } else {
      if (
        this.sendingTaskDetail._state === CeleryImportState.Success
      ) {
        return this.progressExport
          ? 'ดาวน์โหลดสำเร็จ'
          : 'อัปโหลดสำเร็จ';
      } else if (
        this.sendingTaskDetail._state === CeleryImportState.Failure
      ) {
        return this.progressExport
          ? 'ไม่สามารถดาวน์โหลดไฟล์ได้'
          : 'ไม่สามารถอัปโหลดไฟล์ได้';
      }
      return this.progressExport ? 'กำลังดาวน์โหลด' : 'กำลังอัปโหลด';
    }
  }

  get resultKeys(): string[] {
    if (
      !this.sendingTaskDetail.rows ||
      !this.sendingTaskDetail.rows.length
    ) {
      return [];
    }

    return Object.keys(this.sendingTaskDetail.rows[0]);
  }

  get currentStateProgressType(): string {
    if (this.sendingTaskDetail._state === CeleryImportState.Success) {
      return 'success';
    }
    if (this.sendingTaskDetail._state === CeleryImportState.Failure) {
      return 'danger';
    }
    return 'info';
  }
}
