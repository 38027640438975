import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CreateRequestService } from 'src/app/modules/create-request/service/create-request.service';
import { DropdownItem } from 'src/app/shared/models/common.model';
import { DropdownService } from 'src/app/shared/service/dropdown.service';

@Component({
  selector: 'app-postal-address',
  templateUrl: './postal-address.component.html',
  styleUrls: ['./postal-address.component.scss'],
})
export class PostalAddressComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  isCollapsed = false;
  zipCodes: DropdownItem[];
  dropdownData: {
    province: DropdownItem<number, any>[];
    district: DropdownItem<number, any>[];
    sub_district: DropdownItem<number, any>[];
    name_prefix: DropdownItem<number, any>[];
  };

  @Input() submitted: boolean;
  @Input() postalAddressForm: FormGroup;

  constructor(
    private createRequestService: CreateRequestService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit() {
    this.subscribeService();
    this.getPrefixNameItem();
  }

  subscribeService(): void {
    this.subscription.add(
      this.createRequestService.dropDownData$.subscribe((res) => {
        this.dropdownData = res;
      }),
    );
  }

  getPrefixNameItem(): void {
    const data = {
      type: 'name_prefix',
    };
    this.dropdownService.getDropdown(data).subscribe((res) => {
      this.dropdownData.name_prefix = res.name_prefix;
    });
  }

  provinceChange(event: number): void {
    if (event) {
      const data = {
        type: 'district',
        province: event,
      };
      this.dropdownService.getDropdown(data).subscribe((res) => {
        this.dropdownData.district = res.district;
      });
    }
  }

  districtChange(event: number): void {
    if (event) {
      const data = {
        type: 'sub_district',
        district: event,
      };
      this.dropdownService.getDropdown(data).subscribe((res) => {
        this.dropdownData.sub_district = res.sub_district;
      });
    }
  }

  subDistrictChange(event: number): void {
    if (event) {
      const code = this.dropdownData.sub_district.find(
        (item) => item.value === event,
      ).context.postal_code;
      this.zipCodes = [
        {
          label: String(code),
          value: code,
        },
      ];
      this.postal.zipCode.setValue(code);
    }
  }

  get postal() {
    return this.postalAddressForm?.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
