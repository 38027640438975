import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  MasterAdminOnlyViewGuard,
} from './core/guards/auth.guard';
import { HomeComponent } from './core/components/home/home.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';

@Component({
  selector: 'app-media-window-component',
  template: 'file is in download folder',
  styleUrls: [],
})
class MediaWindowComponent {}
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'window-media',
    component: MediaWindowComponent,
  },
  {
    path: 'profile-detail',
    loadChildren: () =>
      import('../app/modules/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
  },
  {
    path: 'welcome-ncb',
    data: ['welcome-ncb'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/welcome-ncb/welcome-ncb.module').then(
        (m) => m.WelcomeNcbModule,
      ),
  },
  {
    path: 'request-credit-bureau',
    data: ['request_credit_bureau'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/create-request/create-request.module'
      ).then((m) => m.CreateRequestModule),
  },
  {
    path: 'request-information',
    data: ['request_information'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/create-request/create-request.module'
      ).then((m) => m.CreateRequestModule),
  },
  {
    path: 'ncb-apps-counter-imports',
    data: ['ncb_apps_counter_imports'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/import-agent-atm/import-agent-atm.module'
      ).then((m) => m.ImportAgentAtmModule),
  },
  {
    path: 'ncb-destroy-box',
    data: ['ncb_destroy_box'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/box-feature/box-feature.module').then(
        (m) => m.BoxFeatureModule,
      ),
  },
  {
    path: 'agent-database',
    data: [
      'import_atm',
      'import_p',
      'import_q',
      'import_e_report',
      'ncb_apps_payment_kkp',
      'ncb_apps_mail_return',
      'ncb_apps_qr_payment',
      'import_dispute',
      'export_corp',
      'export_db',
      'export_group_db',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/agent-database/agent-database.module'
      ).then((m) => m.AgentDatabaseModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../app/modules/register/register.module').then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: 'configuration',
    canActivate: [MasterAdminOnlyViewGuard],
    loadChildren: () =>
      import('../app/modules/setting/setting.module').then(
        (m) => m.SettingModule,
      ),
  },
  {
    path: 'users',
    data: ['can_add_user', 'ncb_apps_holidays'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/users/users.module').then(
        (m) => m.UsersModule,
      ),
  },
  {
    path: 'department',
    data: ['department'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/department/department.module').then(
        (m) => m.DepartmentModule,
      ),
  },
  {
    path: 'memo-report',
    data: [
      'daily_report_detail',
      'daily_report_summary',
      'mail_return_date',
      'mail_return_app_date',
      'status_mail',
      'thp_enquiry_report',
      'dispute_correction_report_date_process',
      'dispute_correction_report_status',
      'agent_report',
      'summary_type_report',
      'counter_service_report',
      'register_mobile_report',
      'tax_invoice_receipt',
      'vat_report',
      'correction_report',
      'dispute_report',
      'csv_mail_report',
      'due_date_report',
      'tracking_csv_report',
      'postage_slip',
      'letter_slip',
      'postal_receipt',
      'ncb_apps_task_status',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/memo-report/memo-report.module').then(
        (m) => m.MemoReportModule,
      ),
  },
  {
    path: 'crystal-reports',
    data: [
      'due_date_report',
      'agent_report',
      'daily_report_detail',
      'daily_report_summary',
      'mail_return_date',
      'mail_return_app_date',
      'status_mail',
      'thp_enquiry_report',
      'dispute_correction_report_date_process',
      'dispute_correction_report_status',
      'summary_type_report',
      'counter_service_report',
      'register_mobile_report',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/crystal-reports/crystal-reports.module'
      ).then((m) => m.CrystalReportsModule),
  },
  {
    path: 'confirmation',
    data: ['confirm_self', 'confirm_agent'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/confirmation-check/confirmation-check.module'
      ).then((m) => m.ConfirmationCheckModule),
  },
  {
    path: 'manage-th-post',
    data: [
      'manage-th-post',
      'generate_ref_code',
      'generate_mail_code',
      'check_post',
      'check_match',
      'update_th_post',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-th-post/manage-th-post.module'
      ).then((m) => m.ManageThPostModule),
  },
  {
    path: 'th-post-return',
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-th-post-return/manage-th-post-return.module'
      ).then((m) => m.ManageThPostReturnModule),
  },
  {
    path: 'operation-logs',
    data: ['operation_logs'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/operation-log/operation-log.module'
      ).then((m) => m.OperationLogModule),
  },
  {
    path: 'master-data',
    data: [
      'master_data_apps_type',
      'master_data_groups_apptype',
      'master_data_reports_type',
      'master_data_branch',
      'master_data_post_code',
      'master_data_names_prefix',
      'master_data_apps_status',
      'master_data_mail_return',
      'master_data_disputes_rcv_name',
      'master_data_accounts_type',
      'master_data_disputes_problem',
      'master_data_disputes_status',
      'master_data_configs_atm',
      'master_data_source_lookup',
      'master_data_payment',
      'master_data_transferee',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/master-data/master-data.module').then(
        (m) => m.MasterDataModule,
      ),
  },
  {
    path: 'app-search',
    data: ['app_search'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/app-search/app-search.module').then(
        (m) => m.AppSearchModule,
      ),
  },
  {
    path: 'ncb-update-batch',
    data: ['ncb_update_batch'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/update-batch/update-batch.module').then(
        (m) => m.UpdateBatchModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },

  // otherwise redirect to home
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
