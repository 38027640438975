<div class="modal-header appSetTheme d-flex m-3">
  <div class="mr-auto">
    <div class="classic-4"></div>
  </div>
  <button type="button"
    class="close"
    aria-label="Close"
    (click)="isClose()">
    <em class="fas fa-times"></em>
  </button>
</div>

<div class="modal-body custom-box p-4 mx-4">
  <div class="col-12 p-0 mt-4"
    *ngIf="progressTaskId">
    <app-import-progress [taskId]="progressTaskId"
      (export)="exportDocumentFromUrl($event)"
      [resultTitle]="resultTitle"
      [progressExport]="true">
    </app-import-progress>
  </div>
</div>