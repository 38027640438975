import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NavbarService } from '../navbar/navbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private navbarService: NavbarService,
    private router: Router,
    private spinner: SpinnerService,
  ) {}
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.navbarService.setActiveSidebar(false);
    setTimeout(() => {
      // redirect home to default news feed if already logged in
      this.router.navigate(['/welcome-ncb']);
    }, 1500);
  }
}
