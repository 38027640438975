import {
  ImportAgent,
  WinAppResult,
} from 'src/app/modules/create-request/shared/idcard-model';

export interface DropdownItem<Value = number, Context = any> {
  label: string;
  value: any;
  value_en?: any;
  context?: Context;
  disabled?: boolean;
}

export interface ErrorMessage {
  [key: string]: ErrorMessageDetail;
}

export type ErrorMessageDetail = ErrorMessage | string | string[];

// eslint-disable-next-line prettier/prettier, max-len
export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export const mockConfigs = {
  ErrorCode: 0,
  Desc: null,
  Result: {
    version: 'N/A',
    crType: 'M',
    crComType: 'F',
    rejType: 'R',
    scoreType: 'NA',
    scoreComType: 'NF',
    rejscoreType: 'RN',
    emailType: 'ME',
    inspType: 'C',
    argType: 'D',
    edaType: 'CA',
    ncrsCode: '01',
    crTypeE: 'MAE',
    crComTypeE: 'FAE',
    scoreTypeE: 'NAE',
    scoreComTypeE: 'NFS',
    registerMobileType: 'AA',
    location: 'ธนาคารแห่งประเทศไทย',
    invoiceAddress: {
      addrLine1:
        'เลขที่ 100/28-29 ชั้น 18 อาคารสาธรนครทาวเวอร์ ถนนสาทรเหนือ',
      addrLine2: 'แขวงสีลม เขตบางรัก กรุงเทพมหานคร 10500',
      addrLine3: 'เลขที่ประจำตัวผู้เสียภาษี 0105542072801',
      addrLine4: '',
      addrLine5:
        'สาขาผู้ออกใบกำกับภาษี : สาขาที่ 1 อาคาร 2 ธนาคารอาคารสงเคราะห์สำนักงานใหญ่ ชั้น 2 เลขที่ 63 ถ.พระราม 9' +
        'แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10320 (ส่วนบริการตรวจสอบเครดิตบูโรฯ โทรศัพท์ 02-643-1250)',
    },
    complaintDivision: {
      complaint1:
        'ส่วนรับเรื่องร้องเรียน อาคาร 2 ชั้น 2 ธนาคารอาคารสงเคราะห์สำนักงานใหญ่ 63 ถ.พระราม 9 ห้วยขวาง กทม. 10310',
      complaint2: 'โทรศัพท์ 0-2643-1250',
    },
  } as WinAppResult,
};

export const importPConfigs = {
  ErrorCode: 0,
  Desc: null,
  Result: {
    defaultType: 'P',
    defaultCodeBaht: '9.6',
    listType: ['P', 'PP', 'PN', 'MM', 'MN', 'E', 'EA'],
    listCodeBaht: ['9.6', '2.1', '2.14', '2.2'],
  } as ImportAgent,
};

export const importQConfigs = {
  ErrorCode: 0,
  Desc: null,
  Result: {
    defaultType: 'Q',
    defaultCodeBaht: '2.1',
    listType: ['Q', 'NQ', 'F', 'FA'],
    listCodeBaht: ['2.1', '2.2'],
  } as ImportAgent,
};

export const importCorpConfigs = {
  ErrorCode: 0,
  Desc: null,
  Result: {
    defaultType: 'FI',
    defaultCodeBaht: '2.1',
    listType: ['FI', 'F', 'FF', 'NQ'],
  } as ImportAgent,
};
