export interface User {
  refresh: string;
  access: string;
  username: string;
  first_name: string;
  last_name: string;
  photo: string;
  id: number;
  person_id: number;
  no_signature: string;
  departments: number[];
  job_position: string;
  is_set_password: boolean;
  is_password_valid: boolean;
  role: string;
  otp_type: 'totp' | 'sms' | 'email' | null;
  phone_number: string;
  email: string;
  error?: string;
  side_bar?: string[];
  changed_password_at?: string;
}

export interface UserAccessManagement {
  id: number;
  text: string;
  app_label: string[];
  children?: UserAccessManagement[];
}

export enum UserAccessNodeType {
  ACCESS_MENU = 'access_menu',
  ACCESS_REPORT = 'access_report',
  ACCESS_WEB_CONFIG = 'access_web_config',
}

export interface TokenRefresh {
  role: string;
  permissions: string[];
  refresh: string;
}

export interface Token {
  access: string;
  is_permission_denied: boolean;
}
