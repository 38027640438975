import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//
// set custom pdf worker path.
// This custom pdf worker is modified to enable digital signature in pdf.
//      More info: https://stackoverflow.com/questions/57380989/preview-pdf-file-with-e-signature-in-angular
//
// if you update ng2-pdf-viewer. follow this steps
//  1. Download PDF worker js file from https://cdn.jsdelivr.net/npm/pdfjs-dist@{YOUR_PDF_WORKER_VERSION_HERE}>/build/pdf.worker.min.js
//  2. follow https://stackoverflow.com/questions/57380989/preview-pdf-file-with-e-signature-in-angular
//  3. save file into "frontend-v2/src/assets/scripts"
//  4. change variable PDFWorkerVersion in this file.
//
//
// NOTES: In the future, if ng2-pdf-viewer requires pdf.worker version >= '2.9.359',
//           that version will support digital signature on their own.
//           So, no need to customize pdf.worker anymore and you can delete this code.
//
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

