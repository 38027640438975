import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '../../models/drf-response.models';
import { Subject, Subscription } from 'rxjs';
import { DropdownItem } from '../../models/common.model';
import {
  CorrectionOrDispute,
  MailDetail,
} from 'src/app/modules/app-search/shared/memo.models';
import { DropdownService } from '../../service/dropdown.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ErrorMessageService } from '../../service/error-message.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { SendEmailService } from '../../service/send-email.service';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss'],
})
export class SendEmailModalComponent {
  @ViewChild('SendEmailModal', { static: true })
  SendEmailModal: ElementRef;
  @Input() isGenMailCode = false;
  // send email
  @Input() isAllEmail: boolean;
  @Input() infoResultTemplate?: DropdownItem<number, any>[];
  @Input() memoCheckedId?: DropdownItem<number, any>[];
  // has app_no
  @Input() appNo?: number | string;
  @Input() mailDetails?: MailDetail[];
  @Input() correctionOrDisputes?: CorrectionOrDispute[];
  @Output() isSendEmail = new EventEmitter();

  subscription: Subscription = new Subscription();
  infoResultType$: DropdownItem<number, any>[];
  peopleInput$ = new Subject<string>();
  sendEmail = [];
  isLoading = false;
  pdfFile: string;
  previewDocument = false;
  disableFields = false;
  showWarning = false;

  textWarning = '';
  titleText = '';

  subModal: NgbModalRef;

  @ViewChild('displayProgress', { static: true })
  displayProgress: NgbModal;
  progressTaskId: string;

  importResultState: CeleryImportState;
  progressExport = 'กำลังสร้าง PDF';

  modal: NgbModalRef;

  constructor(
    public modalService: NgbModal,
    private sendEmailService: SendEmailService,
    private dropdownService: DropdownService,
    private alert: AlertService,
    private errorMessageService: ErrorMessageService,
    private spinnerService: SpinnerService,
  ) {}

  open(): void {
    this.sendEmail = [];
    this.modal = this.modalService.open(this.SendEmailModal, {
      backdrop: 'static',
      centered: true,
    });
    if (!this.isAllEmail && !this.isGenMailCode) {
      if (
        this.mailDetails?.length === 0 ||
        this.correctionOrDisputes?.length === 0
      ) {
        this.titleText = 'กรุณาตรวจสอบ';
        this.textWarning =
          'กรุณาตรวจสอบว่ามีข้อมูลเลขที่หนังสือออก cc ใน Due date and Mail และ ข้อมูลใน Correction/Dispute';
        this.showWarning = true;
      } else {
        this.getDropDown();
        this.showWarning = false;
      }
    }
  }

  closeModal() {
    this.modal.close();
  }

  getDropDown(): void {
    const data = {
      type: 'info_result_type',
      app_no: this.appNo,
      template_type: 'email',
    };
    this.isLoading = true;
    this.dropdownService.getDropdown(data).subscribe(
      (res) => {
        this.infoResultType$ = res[data.type];
        this.isLoading = false;
        this.disableFields = false;
      },
      (err) => {
        this.isLoading = false;
        this.disableFields = true;
        this.handleError(err);
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  detectGenMailCode(
    index: number,
    ccId: number,
    actionType: string,
  ): void {
    if (!this.sendEmail[index]) {
      this.alert.error('กรุณาเลือก Template ในการพิมพ์เอกสาร');
      return;
    }
    if (this.isGenMailCode && this.sendEmail[index]) {
      // generate mail code
      this.modal.dismiss();
      this.isSendEmail.emit(this.sendEmail[index].context.template);
    } else {
      // for app search
      // but isAllEmail is hidden
      this.isSendAndPreviewEmails(index, ccId, actionType);
    }
  }

  isSendAndPreviewEmails(
    index: number,
    ccId: number,
    actionType: string,
  ): void {
    if (this.sendEmail[index]) {
      this.spinnerService.show();
      let data = {};
      if (this.isAllEmail || this.isGenMailCode) {
        const emailsTemplate = [];
        this.memoCheckedId.forEach((memoCheck) => {
          emailsTemplate.push({
            app_no: memoCheck,
            template: this.sendEmail[index].context.template,
          });
        });
        data = { emails_send: emailsTemplate };
      } else {
        data = {
          emails_send: [
            {
              app_no: this.appNo,
              template: this.sendEmail[index].context.template,
              mail_id: ccId,
            },
          ],
        };
      }
      switch (actionType) {
        case 'sendMail':
          new Promise<void>((resolve, reject) => {
            this.sendEmailService.resultNotiSendEmail(data).subscribe(
              (res: { detail: string }) => {
                this.handleSendMailSuccess(res.detail);
                resolve();
              },
              (err) => {
                this.handleError(err);
                reject();
              },
            );
          }).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
          break;
        case 'preview':
          if (ccId) {
            this.sendEmailService.previewEmail(data).subscribe(
              (res: { result: string }) => {
                this.handlePreviewSuccess(res.result);
              },
              (err) => {
                this.handleError(err);
              },
            );
          } else {
            this.previewResultNotificationEmail(data);
          }
          break;
        default:
          break;
      }
    } else {
      this.alert.error('กรุณาเลือก Template ในการส่ง Email');
    }
  }

  handleSendMailSuccess(detail: string) {
    this.spinnerService.hide();
    this.alert.success(detail);
    this.closeModal();
  }

  handleError(err: any) {
    this.spinnerService.hide();
    this.errorMessageService.errorMessage(err);
  }

  handlePreviewSuccess(result: string) {
    this.pdfFile = result;
    this.previewDocument = true;
    this.spinnerService.hide();
  }

  closePreviewDocument(): void {
    this.previewDocument = false;
  }

  previewResultNotificationEmail(data): void {
    this.spinnerService.hide();
    this.sendEmailService
      .previewEmailMultipleMemo(
        data,
        'result-notifications-preview-multiple-memo/',
      )
      .subscribe(
        (res: any) => {
          this.isProgress(res.task_id);
        },
        (err) => {
          this.handleError(err);
        },
      );
  }

  isProgress(taskId: string): void {
    this.progressTaskId = taskId;
    this.openSubModal(this.displayProgress);
  }

  openSubModal(content): void {
    this.subModal = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      centered: true,
    });
  }

  sent(state: CeleryImportState): void {
    this.importResultState = state;
  }

  exportFromUrl(event: CeleryTaskStatus): void {
    if (event._state === CeleryImportState.Success) {
      this.subModal.dismiss();
      this.handlePreviewSuccess(event?.result_notification_preview);
    }
  }

  closeWarning(): void {
    this.showWarning = false;
  }
}
