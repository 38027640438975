import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CreateRequestService } from 'src/app/modules/create-request/service/create-request.service';
import { DropdownItem } from 'src/app/shared/models/common.model';
import { DropdownService } from 'src/app/shared/service/dropdown.service';
import { Branch } from 'src/app/modules/create-request/shared/create-request.model';
import { ConvertDateYearService } from 'src/app/shared/service/convert-date-year.service';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit, OnDestroy {
  @ViewChild('detectCertificateDate', { static: true })
  detectCertificateDate: ElementRef;
  subscription: Subscription = new Subscription();
  modal: NgbModalRef;

  legalTitleList: DropdownItem[];
  provinces: DropdownItem[];
  districts: DropdownItem[];
  subdistricts: DropdownItem[];
  zipCodes: DropdownItem[];
  dropdownData: {
    gender_type: DropdownItem<number, any>[];
    province: DropdownItem<number, any>[];
    district: DropdownItem<number, any>[];
    sub_district: DropdownItem<number, any>[];
  };
  branch = Branch;
  currentDate = new Date();
  maxDate = {
    year: this.currentDate.getFullYear() + 543,
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate(),
  };

  @Input() submitted: boolean;
  @Input() legalForm: FormGroup;
  @Input() displaySession = true;
  @Output() legalNameChange = new EventEmitter();

  constructor(
    public createRequestService: CreateRequestService,
    private dropdownService: DropdownService,
    private modalService: NgbModal,
    private convertService: ConvertDateYearService,
  ) {}

  ngOnInit(): void {
    this.subscribeService();
    this.getPrefixNameItem();
  }

  subscribeService(): void {
    this.subscription.add(
      this.createRequestService.dropDownData$.subscribe((res) => {
        this.dropdownData = res;
        this.setDatafromDropDown();
      }),
    );
  }

  setDatafromDropDown(): void {
    this.provinces = this.dropdownData.province;
    this.districts = this.dropdownData.district;
    this.subdistricts = this.dropdownData.sub_district;
  }

  getPrefixNameItem(): void {
    const data = {
      type: 'name_prefix',
      applicant_type: 'commercial',
    };
    this.dropdownService.getDropdown(data).subscribe((res) => {
      this.legalTitleList = res.name_prefix;
    });
  }
  onLegalNameChange(): void {
    this.legalNameChange.emit();
  }

  provinceChange(event: DropdownItem): void {
    this.l.province.patchValue(event.value);
    const types = ['district', 'subdistrict', 'zipCode'];
    this.patchAddressNull(types);
    if (event) {
      const data = {
        type: 'district',
        province: event.value,
      };
      this.dropdownService.getDropdown(data).subscribe((res) => {
        this.districts = res.district;
      });
    }
  }

  districtChange(event: DropdownItem): void {
    this.l.district.patchValue(event.value);
    const types = ['subdistrict', 'zipCode'];
    this.patchAddressNull(types);
    if (event) {
      const data = {
        type: 'sub_district',
        district: event.value,
      };
      this.dropdownService.getDropdown(data).subscribe((res) => {
        this.subdistricts = res.sub_district;
      });
    }
  }

  subDistrictChange(event: DropdownItem): void {
    this.l.subdistrict.patchValue(event.value);
    const types = ['zipCode'];
    this.patchAddressNull(types);
    if (event) {
      const code = this.subdistricts.find(
        (item) => item.value === event.value,
      )?.context?.postal_code;
      this.zipCodes = [
        {
          label: String(code),
          value: code,
        },
      ];
      this.l.zipCode.patchValue(code);
    }
  }

  patchAddressNull(types: string[]): void {
    types.forEach((type) => {
      this.l[type].patchValue(null);
    });
  }

  checkCertificateNoLaterThanNinetyDays(
    event: string | number | Date,
  ): void {
    if (event) {
      const certificateDate = new Date(event);
      certificateDate.setFullYear(
        certificateDate.getFullYear() - 543,
      );
      if (!this.isWithinLast90Days(certificateDate)) {
        this.modal = this.modalService.open(
          this.detectCertificateDate,
          {
            size: 'md',
          },
        );
      }
    }
  }

  isWithinLast90Days(date: Date): boolean {
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
    ninetyDaysAgo.setHours(7);
    ninetyDaysAgo.setMinutes(0);
    ninetyDaysAgo.setSeconds(0);
    return date > ninetyDaysAgo;
  }

  setDisabledSubBranch(branch: string): void {
    const subBranch = this.l.subBranch as FormControl;
    if (branch === Branch.HEADOFFICE) {
      subBranch.disable();
      subBranch.patchValue(null);
      subBranch.clearValidators();
    } else {
      subBranch.enable();
      subBranch.setValidators(Validators.required);
    }
    subBranch.updateValueAndValidity();
  }

  closeModal(): void {
    this.modal.dismiss();
    this.l.certificateDate.patchValue(null);
  }

  getToday(dataForm: AbstractControl, keyPatch: string): void {
    const today = this.convertService.getToday(new Date());
    dataForm.patchValue({ [keyPatch]: today });
  }

  clearDate(dataForm: AbstractControl, keyPatch: string): void {
    dataForm.patchValue({ [keyPatch]: null });
  }

  get l(): { [key: string]: AbstractControl } {
    return this.legalForm.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
