import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  formatDateToDDMMYYYY,
  isLeapYear,
} from './modules/memos/service/NgDateParser';

@Injectable({
  providedIn: 'root',
})
export class ExtendedNgbDateAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (!date) {
      return null;
    }
    return (
      date.year +
      this.DELIMITER +
      ('0' + date.month).slice(-2) +
      this.DELIMITER +
      ('0' + date.day).slice(-2)
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ExtendedNgbDateParserFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  format(date: NgbDateStruct): string {
    if (date == null) {
      return '';
    }
    const d = moment({
      year: date.year,
      month: date.month - 1,
      date: date.day,
    });
    // detect leap year
    const year = date.year;
    const isLeap = isLeapYear(year);
    if (!isLeap) {
      return d.isValid() ? d.format('DD/MM/YYYY') : '';
    } else {
      return formatDateToDDMMYYYY(date);
    }
  }

  parse(
    value: string,
    format = 'YYYY-MM-DD',
    toBuddhist = false,
  ): NgbDateStruct {
    if (!value) {
      return null;
    }

    let d = dayjs(value, format);
    if (toBuddhist) {
      d = d.add(543, 'years');
    }

    return this.parseFromDayjs(d);
  }

  parseFromDayjs(value: dayjs.Dayjs): NgbDateStruct {
    if (!value) {
      return null;
    }

    return value.isValid()
      ? {
          year: value.year(),
          month: value.month() + 1,
          day: value.date(),
        }
      : null;
  }

  parseAndFormat(value: string, format = 'YYYY-MM-DD'): string {
    return this.format(this.parse(value, format, true));
  }
}
