<div class="modal-header">
  <h5 class="modal-title">
    สิทธิ์การเข้าถึง
  </h5>
</div>
<div class="modal-body text-center f-18 p-4">
  <div>คุณถูกเปลี่ยนสิทธิ์การเข้าถึง</div>
  <h6>กรุณาออกจากระบบเพื่อดำเนินการต่อ</h6>
</div>
<div class="modal-footer">
  <button class="btn btn-outline btn-confirm"
    [style.--c]="themeList?.header_table_color"
    (click)="closeModal()">
    ไว้ภายหลัง
  </button>
  <button class="btn btn-confirm delete-bt"
    appSetTheme
    type="button"
    (click)="closeModal();logOut()">
    ออกจากระบบ
  </button>
</div>