<app-navbar [profile]="profile"></app-navbar>

<div class="d-flex margin-top5rem"
  id="wrapper">
  <!-- Sidebar -->
  <div class="width-sidebar"
    id="sidebar-wrapper"
    [ngClass]="{'position-sidebar':openSidebar,'hidden-sidebar':!openSidebar,
      'width-sidebar-full': !isFullSidebar}"
    [ngStyle]="{
        background:themeList?.bg_color_primary,
        color: themeList?.text_color
      }"
    (window:resize)="onResize($event)">

    <!-- /#sidebar-wrapper -->
    <div class="list-group list-group-flush"
      [ngClass]="isFullSidebar ? 'text-left':'text-center'">

      <div class="menu-main overflow-auto"
        [style.--padding]="(isFullSidebar? '1.5':'0.5') + 'rem'">
        <ng-container *ngFor="let menu of menus | keyvalue">
          <div [ngSwitch]="menu.key">
            <div *ngSwitchCase="'manage_menu'"
              class="custom-border">
              <ng-container *ngFor="let manageMenu of menu.value">
                <div *ngIf="checkSingleMenu(manageMenu.text)">
                  <div *appCheckPermission="{appLabel: manageMenu.app_label}"
                    routerLinkActive="active"
                    [routerLink]="['/', manageMenu.text === 'Admin Operated' ? 'users' : setPath(manageMenu.app_label)]"
                    #rla="routerLinkActive"
                    [style.--color]="themeList?.hovered_text_color"
                    [style.--bg]="themeList?.hovered_text_color + '20'"
                    [ngbTooltip]="manageMenu.text"
                    placement="right">
                    <a class="list-group-item list-group-item-action px-menu-4">
                      <svg-icon [src]="setMenuIconPath([setChildPath(manageMenu.text)])"
                        [stretch]="true"
                        [svgStyle]="{
                              'fill': rla.isActive
                                ? themeList?.hovered_text_color
                                : themeList?.text_color,
                              'height.px': 20,
                              'width.px': 20
                            }">
                      </svg-icon>
                      <span class="pl-2 f-size-17"
                        *ngIf="isFullSidebar"
                        [ngStyle]="{
                              color: rla.isActive
                                ? themeList?.hovered_text_color
                                : themeList?.text_color
                            }">
                        {{ manageMenu.text }}
                      </span>
                    </a>
                  </div>
                </div>
                <div *ngIf="!checkSingleMenu(manageMenu.text)">
                  <div class="custom-border"
                    [ngStyle]="{'background': !manageMenu.subMenu? '': '' , 'color': themeList?.text_color}"
                    *appCheckPermission="{appLabel: manageMenu.app_label}">
                    <div routerLinkActive="active"
                      #rla="routerLinkActive"
                      class="mt-2 hasSubMenu"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      placement="right"
                      [ngbTooltip]="manageMenu.text"
                      (click)="manageMenu.subMenu=!manageMenu.subMenu">
                      <a class="list-group-item list-group-item-action px-menu-4 position-relative ">
                        <svg-icon [src]="setMenuIconPath([setChildPath(manageMenu.text)])"
                          class="mt-title-svg"
                          [stretch]="true"
                          [svgStyle]="{
                          'fill': rla?.isActive
                            ? themeList?.hovered_text_color
                            : themeList?.text_color,
                          'height.px': 20,
                          'width.px': 20
                        }">
                        </svg-icon>
                        <span class="w-100 f-size-17 pt-1 pl-2"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{'color': themeList?.text_color}">
                          {{ manageMenu.text }}
                        </span>
                        <div class="text-right pl-2 position-absolute position-arrow"
                          [ngStyle]="{'color': themeList?.text_color}">
                          <em class="fal"
                            [ngClass]="!manageMenu.subMenu ? 'fa-angle-up' : 'fa-angle-down'"></em>
                        </div>
                      </a>
                    </div>

                    <div *ngIf="!manageMenu.subMenu">
                      <div *ngIf="manageMenu.children.length >=1">
                        <ng-container *ngFor="let child of manageMenu.children">
                          <div *ngIf="manageMenu.text === 'สร้างรายการคำขอ' ||
                            manageMenu.text === 'ยืนยันรายการคำขอตรวจสอบ'">
                            <ul class="bullet">
                              <div *appCheckPermission="{appLabel: child.app_label}"
                                routerLinkActive="active"
                                [routerLink]="manageMenu.text === 'ยืนยันรายการคำขอตรวจสอบ' ? 
                                ['/confirmation/', setPath(child.app_label)]:
                                ['/', setPath(child.app_label)]"
                                #rla="routerLinkActive"
                                [style.--color]="themeList?.hovered_text_color"
                                [style.--bg]="themeList?.hovered_text_color + '20'"
                                [ngbTooltip]="child.text"
                                placement="right">
                                <a class="list-group-item list-group-item-action px-menu-4">
                                  <li>
                                    <span class="f-size-17"
                                      *ngIf="isFullSidebar"
                                      [ngStyle]="{
                                        color: rla.isActive
                                          ? themeList?.hovered_text_color
                                          : themeList?.text_color
                                      }">
                                      {{ child.text }}
                                    </span>
                                  </li>
                                </a>
                              </div>
                            </ul>
                          </div>

                          <div *ngIf="manageMenu.text !== 'สร้างรายการคำขอ' &&
                            manageMenu.text !== 'ยืนยันรายการคำขอตรวจสอบ'">
                            <div *ngIf="checkSingleMenu(child.text) || child.text === 'บันทึกทะเบียนคำขอ'">
                              <div *appCheckPermission="{appLabel: child.app_label}"
                                routerLinkActive="active"
                                [routerLink]="['/', child.children.length >=1 ? setChildPath(child.text) : setPath(child.app_label)]"
                                #rla="routerLinkActive"
                                [style.--color]="themeList?.hovered_text_color"
                                [style.--bg]="themeList?.hovered_text_color + '20'"
                                [ngbTooltip]="child.text"
                                placement="right">
                                <a class="list-group-item list-group-item-action px-menu-4">
                                  <svg-icon [src]="setMenuIconPath([setChildPath(child.text)])"
                                    [stretch]="true"
                                    [svgStyle]="{
                                  'height.px': 20,
                                  'width.px': 20
                                }">
                                  </svg-icon>
                                  <span class="pl-2 f-size-17"
                                    *ngIf="isFullSidebar"
                                    [ngStyle]="{
                                  color: rla.isActive
                                    ? themeList?.hovered_text_color
                                    : themeList?.text_color
                                }">
                                    {{ child.text }}
                                  </span>
                                </a>
                              </div>
                            </div>

                            <div *ngIf="!checkSingleMenu(child.text)">
                              <div [ngStyle]="{'background': !child.subMenu? '': '' , 'color': themeList?.text_color}"
                                *appCheckPermission="{appLabel: child.app_label}">
                                <div *ngIf="child.text !== 'บันทึกทะเบียนคำขอ'"
                                  routerLinkActive="active"
                                  #rla="routerLinkActive"
                                  class="mt-2 hasSubMenu"
                                  [style.--color]="themeList?.hovered_text_color"
                                  [style.--bg]="themeList?.hovered_text_color + '20'"
                                  placement="right"
                                  [ngbTooltip]="child.text"
                                  (click)="child.subMenu=!child.subMenu">
                                  <a class="list-group-item list-group-item-action px-menu-4 position-relative ">
                                    <svg-icon [src]="setMenuIconPath([setChildPath(child.text)])"
                                      class="mt-title-svg"
                                      [stretch]="true"
                                      [svgStyle]="{
                                        'height.px': 20,
                                        'width.px': 20
                                      }">
                                    </svg-icon>
                                    <span class="w-100 f-size-17 pt-1 pl-2"
                                      *ngIf="isFullSidebar"
                                      [ngStyle]="{'color': themeList?.text_color}">
                                      {{ child.text }}
                                    </span>
                                    <div class="text-right pl-2 position-absolute position-arrow"
                                      [ngStyle]="{'color': themeList?.text_color}">
                                      <em class="fal"
                                        [ngClass]="!child.subMenu ? 'fa-angle-up' : 'fa-angle-down'"></em>
                                    </div>
                                  </a>
                                </div>

                                <div *ngIf="!child.subMenu">
                                  <div *ngIf="child.children.length >=1">
                                    <ng-container *ngFor="let sub of child.children">
                                      <ul class="bullet">
                                        <div *appCheckPermission="{appLabel: sub.app_label};"
                                          routerLinkActive="active"
                                          [routerLink]="sub.text === 'Mail Report' ?
                                          ['/memo-report', 'mail-report'] :
                                          ['/', setChildPath(child.text), setPath(sub.app_label)]"
                                          #rla="routerLinkActive"
                                          [style.--color]="themeList?.hovered_text_color"
                                          [style.--bg]="themeList?.hovered_text_color + '20'"
                                          [ngbTooltip]="sub.text"
                                          placement="right">
                                          <a class="list-group-item list-group-item-action px-menu-4">
                                            <li>
                                              <span class="f-size-17"
                                                *ngIf="isFullSidebar"
                                                [ngStyle]="{
                                                  color: rla.isActive
                                                    ? themeList?.hovered_text_color
                                                    : themeList?.text_color
                                                }">
                                                {{ sub.text }}
                                              </span>
                                            </li>
                                          </a>
                                        </div>
                                      </ul>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngSwitchCase="'manage_report'">
              <div class="custom-border"
                [ngStyle]="{'background': !menu.subMenu? '': '' , 'color': themeList?.text_color}"
                *appCheckPermission="{appLabel: menu.value.app_label}">
                <div *appCheckPermission="{appLabel: menu.value.app_label}"
                  routerLinkActive="active"
                  #rla="routerLinkActive"
                  class="mt-2 hasSubMenu"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  placement="right"
                  [ngbTooltip]="'SIDEBAR.USAGE-INFORMATION'|translate"
                  (click)="menu.subMenu=!menu.subMenu">
                  <a class="list-group-item list-group-item-action px-menu-4 position-relative ">
                    <svg-icon src="assets/svg/icon-menu/carbon_information.svg"
                      class="mt-title-svg"
                      [stretch]="true"
                      [svgStyle]="{
                          'fill': rla?.isActive
                            ? themeList?.hovered_text_color
                            : themeList?.text_color,
                          'height.px': 20,
                          'width.px': 20
                        }">
                    </svg-icon>
                    <span class="w-100 f-size-17 pt-1 pl-2"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{'color': themeList?.text_color}">
                      {{'SIDEBAR.USAGE-INFORMATION'|translate}}
                    </span>
                    <div class="text-right pl-2 position-absolute position-arrow"
                      [ngStyle]="{'color': themeList?.text_color}">
                      <em class="fal"
                        [ngClass]="!menu.subMenu ? 'fa-angle-up' : 'fa-angle-down'"></em>
                    </div>
                  </a>
                </div>

                <div *ngIf="!menu.subMenu">
                  <ng-container *ngFor="let report of menu.value">
                    <div *ngIf="report.text === 'รายงานเอกสาร'">
                      <div *appCheckPermission="{appLabel: report.app_label}"
                        routerLinkActive="active"
                        [routerLink]="['/', 'memo-report', 'all-report']"
                        #rla="routerLinkActive"
                        [style.--color]="themeList?.hovered_text_color"
                        [style.--bg]="themeList?.hovered_text_color + '20'"
                        ngbTooltip="{{'SIDEBAR.MEMO-REPORT' | translate}}"
                        placement="right">
                        <a class="list-group-item list-group-item-action px-menu-4">
                          <svg-icon src="assets/svg/icon-menu/text-link--analysis.svg"
                            [stretch]="true"
                            [svgStyle]="{
                              'fill': rla.isActive
                                ? themeList?.hovered_text_color
                                : themeList?.text_color,
                              'height.px': 20,
                              'width.px': 20
                            }">
                          </svg-icon>
                          <span class="pl-2 f-size-17"
                            *ngIf="isFullSidebar"
                            [ngStyle]="{
                              color: rla.isActive
                                ? themeList?.hovered_text_color
                                : themeList?.text_color
                            }">
                            {{ report.text }}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="report.text === 'ผลการดาวน์โหลด'">
                      <div *appCheckPermission="{appLabel: report.app_label}"
                        routerLinkActive="active"
                        [routerLink]="['/', 'memo-report', 'task-results']"
                        #rla="routerLinkActive"
                        [style.--color]="themeList?.hovered_text_color"
                        [style.--bg]="themeList?.hovered_text_color + '20'"
                        ngbTooltip="{{'SIDEBAR.EXPORT-RESULTS' | translate}}"
                        placement="right">
                        <a class="list-group-item list-group-item-action px-menu-4">
                          <svg-icon src="assets/svg/icon-menu/export-results.svg"
                            [stretch]="true"
                            [svgStyle]="{
                              'fill': rla.isActive
                                ? themeList?.hovered_text_color
                                : themeList?.text_color,
                              'height.px': 20,
                              'width.px': 20
                            }">
                          </svg-icon>
                          <span class="pl-2 f-size-17"
                            *ngIf="isFullSidebar"
                            [ngStyle]="{
                              color: rla.isActive
                                ? themeList?.hovered_text_color
                                : themeList?.text_color
                            }">
                            {{ report.text }}
                          </span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </div>

              </div>
            </div>
            <div *ngSwitchCase="'manage_web_config'">
              <div class="custom-border"
                [ngStyle]="{'background': !menu.subMenu? '': '' , 'color': themeList?.text_color}"
                *appCheckPermission="{appLabel: menu.value.app_label, role: ['Master Data']}">
                <div *appCheckPermission="{appLabel: menu.value.app_label}"
                  routerLinkActive="active"
                  #rla="routerLinkActive"
                  class="mt-2 hasSubMenu"
                  [style.--color]="themeList?.hovered_text_color"
                  [style.--bg]="themeList?.hovered_text_color + '20'"
                  placement="right"
                  ngbTooltip="Website Config"
                  (click)="menu.subMenu=!menu.subMenu">
                  <a class="list-group-item list-group-item-action px-menu-4 position-relative ">
                    <svg-icon src="assets/svg/icon-ncb-menu/website-config.svg"
                      class="mt-title-svg"
                      [stretch]="true"
                      [svgStyle]="{
                          'height.px': 20,
                          'width.px': 20
                        }">
                    </svg-icon>
                    <span class="w-100 f-size-17 pt-1 pl-2"
                      *ngIf="isFullSidebar"
                      [ngStyle]="{'color': themeList?.text_color}">
                      Website Config
                    </span>
                    <div class="text-right pl-2 position-absolute position-arrow"
                      [ngStyle]="{'color': themeList?.text_color}">
                      <em class="fal"
                        [ngClass]="!menu.subMenu ? 'fa-angle-up' : 'fa-angle-down'"></em>
                    </div>
                  </a>
                </div>

                <div *ngIf="!menu.subMenu">
                  <ng-container *ngFor="let webConfig of menu.value">
                    <div *appCheckPermission="{appLabel: webConfig.app_label}"
                      routerLinkActive="active"
                      [routerLink]="['/', 'master-data', 'list']"
                      #rla="routerLinkActive"
                      [style.--color]="themeList?.hovered_text_color"
                      [style.--bg]="themeList?.hovered_text_color + '20'"
                      ngbTooltip="Master Data"
                      placement="right">
                      <a class="list-group-item list-group-item-action px-menu-4">
                        <svg-icon [src]="setMenuIconPath([setChildPath(webConfig.text)])"
                          [stretch]="true"
                          [svgStyle]="{
                            'height.px': 20,
                            'width.px': 20
                          }">
                        </svg-icon>
                        <span class="pl-2 f-size-17"
                          *ngIf="isFullSidebar"
                          [ngStyle]="{
                            color: rla.isActive
                              ? themeList?.hovered_text_color
                              : themeList?.text_color
                          }">
                          {{ webConfig.text }}
                        </span>
                      </a>
                    </div>
                  </ng-container>

                  <!-- Config -->
                  <div *appCheckPermission="{appLabel: [], role:['Master Admin']}"
                    routerLinkActive="active"
                    [routerLink]="['/', 'configuration']"
                    #rla="routerLinkActive"
                    [style.--color]="themeList?.hovered_text_color"
                    [style.--bg]="themeList?.hovered_text_color + '20'"
                    [ngbTooltip]="'SIDEBAR.CONFIGURATION' | translate"
                    placement="right">
                    <a class="list-group-item list-group-item-action px-menu-4">
                      <svg-icon src="assets/svg/icon-menu/setting-theme.svg"
                        [stretch]="true"
                        [svgStyle]="{
                            'fill': rla.isActive
                              ? themeList?.hovered_text_color
                              : themeList?.text_color,
                            'height.px': 20,
                            'width.px': 20
                          }">
                      </svg-icon>
                      <span class="pl-2 f-size-17"
                        *ngIf="isFullSidebar"
                        [ngStyle]="{
                            color: rla.isActive
                              ? themeList?.hovered_text_color
                              : themeList?.text_color
                          }">
                        {{'SIDEBAR.CONFIGURATION' | translate}}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="text-center mb-2">
        <div class="w-100 f-size-17">{{ configsRes?.Result?.version || '' }}</div>
        <div class="w-100 f-size-17">{{ tagVersion || '' }}</div>

        <ng-container *ngIf="config.leftTime && !isHideCountDown">
          <div class="w-100 f-size-17">ออกจากระบบอัตโนมัติในอีก</div>
          <div class="w-100 f-size-17">
            <countdown #cd
              [config]="config"
              (event)="handleEvent($event)">
            </countdown>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Page Content -->
  <div id="page-content-wrapper">

    <ng-container *appFeatureFlag="'ddoc'">
      <div class="col-12 px-0"
        *ngIf="showWarning &&
            profile?.otp_type === null &&
            (checkRouteActive('/news-feed') ||
              checkRouteActive('/memos'))">
        <div class="box-warning col-12 d-flex">
          <em class="fal fa-exclamation-triangle align-self-center mr-2"
            style="color:#d8bb18"></em>
          <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE'|translate }}</span>
          <button class="btn align-self-center ml-3"
            [routerLink]="['/', 'profile-detail', 'otp']"
            appSetTheme
            type="button">
            <span style="font-size: 13px">{{'CERTIFICATE.GOTO-OTP-SETTINGS'|translate}}</span>
          </button>
          <a class="text-black p-2 align-self-center ml-auto"
            href="javascript:void(0)"
            role="button"
            (click)="showWarning = false">
            <em class="fal fa-times"></em>
          </a>
        </div>
      </div>
    </ng-container>

    <div class="container-fluid overflow-auto p-0">
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>