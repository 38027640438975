<form [formGroup]="sectionForm">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">ประเภทผู้ยื่นคำขอ</label>
        <ng-select class="selected-box"
          [items]="personTypeList"
          bindLabel="label"
          bindValue="value"
          formControlName="personType"
          (change)="onPersonTypeChange($event)">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12"
      *ngIf="person.value">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">การมอบอำนาจ</label>
        <ng-select class="selected-box"
          [items]="delegationList"
          bindLabel="label"
          bindValue="value"
          formControlName="delegation"
          (change)="onDelegationChange($event)">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12"
      *ngIf="delegation.value">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">ประเภทคำขอ</label>
        <ng-select class="selected-box"
          [items]="requestTypeList"
          bindLabel="label"
          bindValue="value"
          formControlName="requestType"
          (change)="onRequestTypeChange($event)">
        </ng-select>
      </div>
    </div>
  </div>
</form>