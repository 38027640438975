import { RESPONSE } from './../../shared/utils/constants';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { ProfileService } from 'src/app/modules/users/shared/profile-service';
import {
  AddUsers,
  DeleteUsers,
  GetUsers,
  SelectedUser,
  UpdateUsers,
} from './users.actions';
import { ResultAllUsers } from './users.model';

export class UsersStateModel {
  users: ResultAllUsers[];
  count: number;
  selectedUser;
}

@State<UsersStateModel>({
  name: 'User',
  defaults: {
    users: null,
    selectedUser: null,
    count: 0,
  },
})
@Injectable({
  providedIn: 'root',
})
export class UserState {
  constructor(private service: ProfileService) {}

  @Selector()
  static getUserList(state: UsersStateModel) {
    return state.users;
  }

  @Selector()
  static getSelectedUser(state: UsersStateModel) {
    return state.selectedUser;
  }
  @Selector()
  static getCount(state: UsersStateModel): number {
    return state.count;
  }

  @Action(GetUsers)
  getUsers(
    { getState, setState }: StateContext<UsersStateModel>,
    { search, page }: GetUsers,
  ) {
    let params = { ...search };
    params = { ...params, page: page.toString() || '1' };
    return this.service.getPeopleList(params).pipe(
      tap(
        (result: RESPONSE<ResultAllUsers>) => {
          const state = getState();
          setState({
            ...state,
            users: result.results,
            count: result.count,
          });
        },
        (err) => {
          const state = getState();
          setState({
            ...state,
            users: null,
            count: null,
          });
        },
      ),
    );
  }

  @Action(AddUsers)
  addUser(
    { getState, patchState }: StateContext<UsersStateModel>,
    { payload }: AddUsers,
  ) {
    return this.service.createPerson(payload).pipe(
      tap((result) => {
        const state = getState();
      }),
    );
  }

  @Action(UpdateUsers)
  updateUser(
    { getState, setState }: StateContext<UsersStateModel>,
    { payload, id }: UpdateUsers,
  ) {
    return this.service.updatePerson(payload, id).pipe(
      tap((result) => {
        const state = getState();
        const userList = [...state.users];
        const userIndex = userList.find((item) => item.id === id);
        setState({
          ...state,
        });
      }),
    );
  }

  @Action(DeleteUsers)
  deleteUser(
    { getState, setState }: StateContext<UsersStateModel>,
    { id, payload }: DeleteUsers,
  ) {
    return this.service.deleteUser(id, payload).pipe(
      tap(() => {
        const state = getState();
        state.users = state.users.filter((item) => item.id !== id);
        setState({
          ...state,
        });
      }),
    );
  }

  @Action(SelectedUser)
  setSelectedUserId(
    { getState, setState }: StateContext<UsersStateModel>,
    { id }: SelectedUser,
  ) {
    return this.service.getPersonDetail(id).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          selectedUser: res,
        });
      }),
    );
  }
}
