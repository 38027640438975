import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetUserById } from 'src/app/store/auth/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  isDetectOneCallApi: boolean;
  constructor(private store: Store) {}

  private hasPermission(access: string[], accession: string[]) {
    return access.some((value) => accession.includes(value));
  }

  checkAccess(access: string[]) {
    access = access.filter((label) => label !== 'ncb_memos_common');
    if (
      !this.store.selectSnapshot<string>(
        (state) => state.auth?.role,
      ) &&
      !this.isDetectOneCallApi
    ) {
      this.isDetectOneCallApi = true;
    }
    const role = this.store.selectSnapshot<string>(
      (s) => s.auth.role,
    );
    const permissions = this.store.selectSnapshot<string>(
      (s) => s.auth.permission,
    );
    const foundCanAddUser: string | undefined = access.find(
      (str: string) => str === 'can_add_user',
    );
    const accession = [role, ...permissions];
    if (foundCanAddUser) {
      return role.includes('Master Admin') || role.includes('Admin');
    } else {
      return this.hasPermission(access, accession);
    }
  }
}
