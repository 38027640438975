import { Validators } from '@angular/forms';
import { Branch } from '../shared/create-request.model';

export function initialSectionForm() {
  return {
    personType: [null, [Validators.required]],
    delegation: [null],
    requestType: [null],
  };
}

export function initialPersonalForm() {
  return {
    cardType: [null, [Validators.required]],
    laserId: [null, [Validators.required]],
    cardExp: [null],
    titleName: [null, [Validators.required]],
    firstName: [null, [Validators.required]],
    middleName: [null],
    lastName: [null, [Validators.required]],
    fullName: [null],
    titleNameEng: [null],
    firstNameEng: [null],
    middleNameEng: [null],
    lastNameEng: [null],
    idCard: [null, [Validators.required]],
    sex: [null],
    birthday: [null, [Validators.required]],
    address: [null],
    subdistrict: [null],
    district: [null],
    province: [null],
    zipCode: [null],
    rawAddress: [null],
    fullAddress: [null],
    phone: [null],
    dopa: [null, [Validators.required]],
    noFaceRecognized: [null],
    noScanCard: [null, [Validators.required]],
    isSmartCard: [null],
    scanCardImage: [null],
    issueDate: [null],
    expireDate: [null],
    issuer: [null],
  };
}

export function initialLegalForm() {
  return {
    legalNo: [null, [Validators.required]],
    certificateDate: [null, [Validators.required]],
    title: [null, Validators.required],
    regisDate: [null, Validators.required],
    legalName: [null, Validators.required],
    address: [null],
    subdistrict: [null],
    district: [null],
    province: [null],
    zipCode: [null],
    phone: [null],
    headOffice: [Branch.HEADOFFICE, Validators.required],
    subBranch: [{ value: null, disabled: true }],
  };
}

export function initialAccountForm() {
  return {
    member: [null, [Validators.required]],
    accountNo: [null, [Validators.required]],
    credit: [null, [Validators.required]],
    transferee: [null, [Validators.required]],
    payOffDebt: [null, [Validators.required]],
  };
}

export function initialPostalAddressForm() {
  return {
    titleName: [
      { value: null, disabled: true },
      [Validators.required],
    ],
    firstName: [
      { value: null, disabled: true },
      [Validators.required],
    ],
    middleName: [{ value: null, disabled: true }],
    lastName: [{ value: null, disabled: true }],
    houseNo: [null, [Validators.required]],
    villageNo: [null],
    building: [null],
    lane: [null],
    road: [null],
    subdistrict: [null, [Validators.required]],
    district: [null, [Validators.required]],
    province: [null, [Validators.required]],
    zipCode: [null, [Validators.required]],
    phone: [null],
  };
}
