import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { CreateRequestService } from 'src/app/modules/create-request/service/create-request.service';
import {
  CreateRequest,
  Delegation,
  PersonType,
  RequestType,
} from 'src/app/modules/create-request/shared/create-request.model';
import { DropdownItem } from 'src/app/shared/models/common.model';
import { DropdownService } from 'src/app/shared/service/dropdown.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit, OnDestroy {
  sectionForm: FormGroup;
  personTypeList: DropdownItem[];
  delegationList = [
    { label: 'มาด้วยตนเอง', value: Delegation.PERSON },
    { label: 'มอบอำนาจ', value: Delegation.DELEGATION },
  ];
  requestTypeList = [];

  subscription: Subscription = new Subscription();
  delegationType = Delegation;
  createRequest = CreateRequest;

  @Input() path: string;
  @Output() resetCardDetail = new EventEmitter();

  constructor(
    private createRequestServive: CreateRequestService,
    private dropdownService: DropdownService,
    private spinnerService: SpinnerService,
  ) {}

  ngOnInit() {
    this.getDropdownItem();
    this.subscriptionService();
  }

  getDropdownItem(): void {
    this.spinnerService.show();
    const data = {
      type: 'applicant_type',
    };
    this.dropdownService.getDropdown(data).subscribe((res) => {
      this.personTypeList = res.applicant_type;
      this.spinnerService.hide();
    });
  }

  subscriptionService(): void {
    this.subscription.add(
      this.createRequestServive.getSectionForm$.subscribe((form) => {
        this.sectionForm = form;
      }),
    );
  }

  setRequestTypeItem(): void {
    if (this.path === CreateRequest.CREDITBUREAU) {
      if (
        this.person.value === PersonType.NORMALPERSON &&
        this.delegation.value === Delegation.PERSON
      ) {
        this.requestTypeList = [
          {
            label: 'ตรวจสอบข้อมูลเครดิต',
            value: RequestType.PLAINCHECK,
          },
          {
            label: 'ตรวจสอบคะแนนเครดิต',
            value: RequestType.CREDITPOINT,
          },
          {
            label: 'Register Mobile',
            value: RequestType.REGISTERMOBILE,
          },
        ];
      } else {
        this.requestTypeList = [
          {
            label: 'ตรวจสอบข้อมูลเครดิต',
            value: RequestType.PLAINCHECK,
          },
          {
            label: 'ตรวจสอบคะแนนเครดิต',
            value: RequestType.CREDITPOINT,
          },
        ];
      }
    } else {
      this.requestTypeList = [
        {
          label:
            'คำขอแก้ไขรหัสสถานะบัญชี (เนื่องจากได้ชำระหนี้ให้แก่นิติบุคคลผู้รับโอนหนี้เสร็จสิ้นแล้ว)',
          value: RequestType.STATUSACCOUNT,
        },
        {
          label: 'คำขอใช้สิทธิตรวจสอบ/ขอแก้ไขข้อมูลเครดิต',
          value: RequestType.CREDITINFO,
        },
        {
          label: 'คำขอใช้สิทธิบันทึกข้อโต้แย้งข้อมูลเครดิต',
          value: RequestType.CREDITARUMENT,
        },
      ];
    }
  }

  onPersonTypeChange(value: string): void {
    this.delegation.setValue(null);
    this.clearRequestType();
  }

  onDelegationChange(value: string): void {
    this.clearRequestType();
    this.setRequestTypeItem();
  }

  onRequestTypeChange(value: string): void {
    this.createRequestServive.createForm();
    if (value) {
      this.createRequestServive.createForm('create');
    }
    this.resetCardDetail.emit();
  }

  clearRequestType(): void {
    this.request?.setValue(null);
    this.createRequestServive.createForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get person() {
    return this.sectionForm.get('personType');
  }

  get delegation() {
    return this.sectionForm.get('delegation');
  }

  get request() {
    return this.sectionForm.get('requestType');
  }
}
