import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class NgbThaiDateParserFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }
  format(date: NgbDateStruct, forSendingToBackend = false): string {
    if (date == null) {
      return '';
    }
    const d = moment({
      year: date.year,
      month: date.month - 1,
      date: date.day,
    });
    // detect leap year
    const year = date.year;
    const isLeap = isLeapYear(year);
    if (!isLeap) {
      if (forSendingToBackend) {
        return d.isValid() ? d.format('YYYY-MM-DD') : '';
      } else {
        return d.isValid() ? d.format('DD/MM/YYYY') : '';
      }
    } else {
      return forSendingToBackend
        ? formatDateToYYYYMMDD(date)
        : formatDateToDDMMYYYY(date);
    }
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    let d = null;

    if (value.includes('-')) {
      d = moment(value, 'YYYY-MM-DD');
    } else {
      d = moment(value, 'DD/MM/YYYY');
    }

    return d.isValid()
      ? { year: d.year(), month: d.month() + 1, day: d.date() }
      : null;
  }
}

export function isLeapYear(year: number): boolean {
  const enYear = year > new Date().getFullYear() ? year - 543 : year;
  return (
    (enYear % 4 === 0 && enYear % 100 !== 0) || enYear % 400 === 0
  );
}

export function formatDateToYYYYMMDD(date: NgbDateStruct): string {
  if (date) {
    const year = date.year.toString().padStart(4, '0');
    const month = date.month.toString().padStart(2, '0');
    const day = date.day.toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return null;
}

export function formatDateToDDMMYYYY(date: NgbDateStruct): string {
  if (date) {
    const year = date.year.toString().padStart(4, '0');
    const month = date.month.toString().padStart(2, '0');
    const day = date.day.toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
  }
  return null;
}

export function isValidLeapDate(dateString: string): boolean {
  const [yearStr, monthStr, dayStr] = dateString
    .split('-')
    .map((str) => parseInt(str));
  const year = yearStr;
  const month = monthStr;
  const day = dayStr;

  if (month !== 2 || day !== 29) {
    return false; // Not February 29th
  }
  return isLeapYear(year);
}
