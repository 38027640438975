import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';

@Component({
  selector: 'app-preview-pdf',
  template: '',
})
export class PreviewPdfComponent implements OnInit {
  @Input() document: string;
  @Output() closed = new EventEmitter();

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.openMediaUrlInNewWindow(this.document);
    this.closed.emit();
  }
}
