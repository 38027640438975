export interface SectionForm {
  personType: string;
  delegation: string;
  requestType: string;
}

export interface PersonalForm {
  cardType: string;
  idCard: string;
  cardExp: string;
  laserId?: string;
  titleName: any;
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  birthday: string;
  address: string;
  subdistrict: any;
  district: any;
  province: any;
  zipCode: any;
  email?: string;
  phone: string;
  otp?: string;
  otpConfirm?: boolean;
  confirmOwner?: string;
  documentNo?: string;
  dopa: string;
}

export interface LegalForm {
  legalNo: string;
  certificateDate: string;
  title: string;
  regisDate: string;
  legalName: string;
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  zipCode: string;
  phone: string;
  email?: string;
  headOffice?: boolean;
  subBranch?: string;
}

export interface AccountForm {
  member: string;
  accountNo: string;
  credit: string;
  transferee: string;
  payOffDebt: string;
}

export interface PostalAddress {
  firstName: string;
  middleName: string;
  lastName: string;
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  zipCode: string;
  phone: string;
}

export interface ReceiptForm {
  appNumber: string;
  cardNumber: string;
  serviceCharge: number;
  paymentChannel: string;
  coupon: string;
  reportType?: number;
  member?: number;
  nextDue?: SentReportNextDue;
  isDelivery: boolean;
  postal?: Postal;
}

export interface SentReportNextDue {
  month: string;
  year: string;
}

export interface Postal {
  titleName: number;
  firstName: string;
  middleName: string;
  lastName: string;
  address?: string;
  houseNo: string;
  villageNo: string;
  building: string;
  lane: string;
  road: string;
  subdistrict: any;
  district: any;
  province: any;
  zipCode: any;
  phone?: string;
}

export interface PersonalInfo {
  cust_type?: string;
  card_type?: string;
  cust_id_no?: string;
  back_card_no?: string;
  cust_exp_date?: string;
  cust_tname?: string;
  cust_fname?: string;
  middle_name?: string;
  cust_lname?: string;
  cust_fname_en?: string;
  middle_name_en?: string;
  cust_lname_en?: string;
  gender?: string;
  cust_brdt?: string;
  cust_address?: string;
  district?: string;
  sub_district?: string;
  postal_code?: string;
  cust_tel?: string;
  email?: string;
  rejection_letter?: boolean;
  with_history?: boolean;
  eng_receive?: boolean;
  email_receive?: boolean;
  check_face?: boolean;
  save_piccard?: boolean;
  check_online_dopa?: boolean;
  dopa_server_connect?: boolean;
  otp?: string;
  otp_confirm?: boolean;
  otp_force_confirm?: boolean;
  app_type?: string;
  pic_card?: File | string;
  similarity?: number;
  f_match?: boolean;
  headquarter?: boolean;
  branch_code?: string;
  is_dip_chip?: boolean;
  raw_address?: string;
  cust_issue_date?: string;
  date_of_expire?: string;
  location?: string;
}

export interface MemoReceipt {
  id?: number;
  memo_number: string;
  applicant_type: string;
  card_number: string;
  full_name?: string;
  name_prefix: number;
  first_name: string;
  middle_name?: string;
  last_name: string;
  signed_document?: string;
  receipt_document?: string;
  address?: string;
  sub_district?: number;
  district?: number;
  province?: number;
  postal_code?: number;
  person_index?: number;
  already_gen_receipt?: boolean;
}

export interface AccountDetail {
  rcv_name?: string;
  mem_code: number;
  acc_no: string;
  acc_type: number;
  acc_close_date: string;
  name_transferee: number;
  app_line: number;
}

export interface DeliveryAddress {
  name_prefix: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  address?: string;
  house_no?: string;
  village_no?: string;
  building?: string;
  lane?: string;
  road?: string;
  sub_district: number;
  phone_no: string;
}

export interface Receipt {
  payment_channel: number;
  coupon_no?: string;
  member?: number;
  report_type?: number;
  receipt: ReceiptHeader;
  branch: ReceiptHeader;
  post_fee: boolean;
  name_prefix?: string | number;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  address?: string;
  house_no?: string;
  village_no?: string;
  building?: string;
  lane?: string;
  road?: string;
  sub_district?: number;
}

export interface ReceiptHeader {
  address_line1?: string;
  address_line2?: string;
  address_line3?: string;
  address_line4?: string;
  address_line5?: string;
}

export enum ButtonState {
  SUBMIT = 1,
  PRINTREQUEST = 2,
  RECEIPT = 3,
  VERIFY = 4,
}

export enum Address {
  PROVINCES = 'province',
  DISTRICTS = 'district',
  SUBDISTRICTS = 'sub_district',
  ZIPCODES = 'zipCodes',
}

export enum CreateRequest {
  CREDITBUREAU = 'request-credit-bureau',
  INFORMATION = 'request-information',
}

export enum PersonType {
  NORMALPERSON = 'customer',
  LEGALPERSON = 'commercial',
}

export enum Delegation {
  PERSON = 'person',
  DELEGATION = 'authorize',
}

export enum RequestType {
  PLAINCHECK = 'check_credit_info',
  CREDITPOINT = 'check_credit_score',
  REGISTERMOBILE = 'register_mobile',
  STATUSACCOUNT = 'account_status_code',
  CREDITINFO = 'credit_correction',
  CREDITARUMENT = 'credit_dispute',
}

export enum CardType {
  IDCARD = 'id_card',
  PASSPORT = 'passport',
  DIVERLICIENSE = 'driving_license',
  GOVTIDCARD = 'government_employee_card',
  FOREIGNCARD = 'alien_card',
  ORTHERS = 'other',
}

export enum ButtonType {
  SAVEREQUEST = 'save-request',
  REQUESTRECEIPT = 'request-receipt',
  CLEARFORM = 'clear-form',
  CLOSE = 'close',
  NEXT = 'next',
  PRINTDOCUMENT = 'print-document',
  VERIFY = 'verify-infomation',
}

export enum Branch {
  HEADOFFICE = 'head_office',
  SUBBRANCH = 'sub_branch',
}

export enum Operations {
  MANAGE_REGISTER_FIELD = 1,
  MANAGE_EMAIL_FIELD = 2,
  CLEAR_FORM = 3,
  ADD_ACCOUNT = 4,
  ADD_ADDRESS_DELIVERY = 5,
  ADD_LEGAL = 6,
}

export interface DataSendCompare {
  api_key: string;
  api_secret: string;
  image_base64_1: string;
  image_base64_2: string;
}

export interface FaceRectangle {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface Faces {
  face_token: string;
  face_rectangle: FaceRectangle;
}

export interface ResComparison {
  verified: boolean;
  distance: number;
  model: string;
  detector_backend: string;
  similarity_metric: string;
  similarity: number;
  similarity_percent: number;
  seconds: number;
}
