import { toTitleCase } from 'src/app/shared/utils/common.util';
import { Injectable } from '@angular/core';
import { AlertService } from '../../core/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(private alert: AlertService) {}

  errorMessage(error) {
    return new Promise<void>((resolve) => {
      if (error?.status !== 500) {
        const errorModify = this.formatNestedData(
          this.getFinalNestedDataAndKeys(error.error),
        );
        let errorMsg = '';
        const entries = Object.entries(errorModify);
        entries.forEach(([key, value]) => {
          if (!/\d/.test(key)) {
            errorMsg +=
              `${toTitleCase(key.replace('_', ' '))}: ` +
              `${value}<br/>`;
          } else {
            if (value !== '\n') {
              errorMsg += `${value}<br/>`;
            } else {
              errorMsg = null;
            }
          }
        });
        if (errorMsg) {
          this.alert.error(errorMsg);
        } else {
          this.alert.error('มีบางอย่างผิดพลาด');
        }
      } else {
        this.alert.error('มีบางอย่างผิดพลาด');
      }
      resolve();
    });
  }

  private formatNestedData(
    nestedData: Record<string, any>,
  ): Record<string, any> {
    const result: Record<string, any> = {};
    for (const key in nestedData) {
      const childKey = key.split('.').reverse()[1];
      if (!result[childKey] || result[childKey] === undefined) {
        result[childKey] = {};
      }
      result[childKey] = nestedData[key];
    }
    return result;
  }

  private getFinalNestedDataAndKeys(obj): Record<string, any> {
    if (obj) {
      const result: Record<string, any> = {};

      function getNested(obj, prefix: string) {
        for (const key in obj) {
          if (typeof obj[key] === 'object') {
            getNested(obj[key], `${prefix}.${key}`);
          } else {
            result[`${prefix}.${key}`] = obj[key];
          }
        }
      }

      getNested(obj, Object.keys(obj)[0]);
      return result;
    }
  }
}
