export interface LoginPayload {
  username: string;
  password: string;
}

export interface AuthResponse {
  refresh: string;
  access: string;
  username: string;
  first_name: string;
  last_name: string;
  photo: string;
  id: number;
  person_id: number;
  no_signature: string;
  departments: number[];
  job_position: string;
  is_set_password: boolean;
  is_password_valid: boolean;
  enable_delegate?: boolean;
  role: string;
}

export interface AuthStateModel {
  refresh?: string;
  access?: string;
  username?: string;
  first_name?: string;
  last_name?: string;
  photo?: string;
  id?: number;
  person_id?: number;
  signature?: string;
  departments?: number[];
  job_position?: string;
  is_set_password?: boolean;
  is_password_valid?: boolean;
  role?: string;
  otp_type?: 'totp' | 'sms' | 'email' | null;
  phone_number?: string;
  email?: string;
  is_custom?: boolean;
  citizen_id?: string;
  remaining_attempts?: number;
  permission?: string[];
  enable_delegate?: boolean;
  advance_notification_type?: LoginSuccessNotificationType;
}

export enum LoginSuccessNotificationType {
  PREPARE_CHANGE_PASSWORD = 'prepare_change_password',
  FORCE_CHANGE_PASSWORD = 'force_change_password',
}

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  id: number;
  username: string;
  is_active: boolean;
}

export interface Department {
  id: number;
  display_name: string;
  display_name_en: string;
  department_code: string;
  department_name: string;
  department_name_en: string;
  parent?: any;
  department_level: number;
}

export interface Role {
  id: number;
  name: string;
}

export interface UserDetail {
  id: number;
  person_id?: number;
  phone_number: string;
  user: User;
  job_position: string;
  photo?: any;
  signature?: any;
  full_name: string;
  line_user_id?: any;
  muted_notifications: any[];
  preferred_language: string;
  departments: Department[];
  otp_enable: boolean;
  can_view_all_memo: boolean;
  role: Role;
  in_loa: boolean;
  departments_id: number[];
  have_pending_memo: boolean;
  otp_type?: 'totp' | 'sms' | 'email' | null;
  email?: string;
  is_custom?: boolean;
  is_set_password?: boolean;
  is_password_valid?: boolean;
  enable_delegate?: boolean;
  remark?: string;
}

export interface TokenDecoded {
  exp: number;
  jti: string;
  token_type: string;
  user_id: number;
}
