<ng-template #downloadModal let-c="close" let-d="dismiss">
    <div class="modal-header text-center">
      <h5 class="modal-title"
          id="modal-basic-title">
        {{ checkPage==='userPage' ?("EXPORT.USER-REPORT" | translate) :("EXPORT.MEMO-REPORT" | translate)}}
      </h5>
      <button type="button"
          class="close"
          aria-label="Close"
          (click)="close()">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <div class="full-width mx-2">
          <ngb-progressbar [type]="currentStateProgressType"
              [striped]="currentStateProgressType === 'info'"
              [animated]="currentStateProgressType === 'info'"
              [value]="sendingTaskDetail.percent"
              height="2rem">
            <div class="font-weight-bold">
              <span class="mb-0 mx-1"
                  *ngIf="sendingTaskDetail._state === celeryImportState.Progress">
                {{ sendingTaskDetail.percent }}%
              </span>
              <h5 class="mb-0 mx-1 text-white"
                  *ngIf="sendingTaskDetail._state !== celeryImportState.Progress">
                {{ 'EXPORT.' + sendingTaskDetail._state | translate }}</h5>
            </div>
      
          </ngb-progressbar>
        </div>
      
        <div class="full-width mx-2 mt-4"
            *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
          <ngb-alert type="danger"
              [dismissible]="false">
            <h5 class="text-danger">{{ 'EXPORT.ERROR-OCCURRED' | translate }}</h5>
            <div class="frame error p-3"
                [innerHTML]="sendingTaskDetail.detail"></div>
          </ngb-alert>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button [style.--c]="themeList?.header_table_color"
          [style.--bg]="'#fff'"
          type="button"
          class="btn p-btn btn-outline"
          *ngIf="filePath"
          (click)="c('Close click')">
        {{ "MEMOS.CANCEL" | translate }}
      </button>
      <button appSetTheme
          type="button"
          class="btn p-btn"
          *ngIf="filePath"
          (click)="downloadCSV()">
        {{ "EXPORT.DOWNLOAD" | translate }}
      </button>
    </div>
  </ng-template>