<form [formGroup]="postalAddressForm">
  <div class="section d-flex justify-content-between align-items-center p-2 mb-4">
    <div>รายละเอียดที่อยู่จัดส่งทางไปรษณีย์</div>
    <div class="d-flex">
      <em class="far fa-chevron-circle-up mr-2 pointer"
        (click)="!isCollapsed ? collapse.toggle() : null"
        [attr.aria-expanded]="isCollapsed"
        aria-controls="collapseExample"></em>
      <em class="far fa-chevron-circle-down mr-2 pointer"
        (click)="isCollapsed ? collapse.toggle() : null"
        [attr.aria-expanded]="isCollapsed"
        aria-controls="collapseExample"></em>
    </div>
  </div>

  <div class="row"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed">
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">คำนำหน้า</label>
        <div class="selected-box">
          <ng-select [items]="dropdownData.name_prefix"
            bindLabel="label"
            bindValue="value"
            formControlName="titleName"
            [ngClass]="{ 'is-invalid': submitted && postal.titleName.errors }">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">ชื่อจริง</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="firstName"
          [ngClass]="{ 'is-invalid': submitted && postal.firstName.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label>ชื่อกลาง</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="middleName"
          [ngClass]="{ 'is-invalid': submitted && postal.middleName.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label>นามสกุล</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="lastName"
          [ngClass]="{ 'is-invalid': submitted && postal.lastName.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">บ้านเลขที่</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="houseNo"
          [ngClass]="{ 'is-invalid': submitted && postal.houseNo.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label>หมู่ที่</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="villageNo"
          maxlength="2"
          [ngClass]="{ 'is-invalid': submitted && postal.villageNo.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label>อาคาร/หมู่บ้าน</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="building"
          [ngClass]="{ 'is-invalid': submitted && postal.building.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <div class="form-group mx-1 mb-4">
        <label>ตรอก/ซอย</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="lane"
          [ngClass]="{ 'is-invalid': submitted && postal.lane.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>ถนน</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="road"
          [ngClass]="{ 'is-invalid': submitted && postal.road.errors }">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">จังหวัด</label>
        <ng-select class="selected-box"
          [items]="dropdownData.province"
          bindLabel="label"
          bindValue="value"
          formControlName="province"
          (ngModelChange)="provinceChange($event)"
          [ngClass]="{ 'is-invalid': submitted && postal.province.errors }">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">เขต/อำเภอ</label>
        <ng-select class="selected-box"
          [items]="dropdownData.district"
          bindLabel="label"
          bindValue="value"
          formControlName="district"
          (ngModelChange)="districtChange($event)"
          [ngClass]="{ 'is-invalid': submitted && postal.district.errors }">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">แขวง/ตำบล</label>
        <ng-select class="selected-box"
          [items]="dropdownData.sub_district"
          bindLabel="label"
          bindValue="value"
          formControlName="subdistrict"
          (ngModelChange)="subDistrictChange($event)"
          [ngClass]="{ 'is-invalid': submitted && postal.subdistrict.errors }">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label class="field-require">รหัสไปรษณีย์</label>
        <ng-select class="selected-box"
          [items]="zipCodes"
          bindLabel="label"
          bindValue="value"
          formControlName="zipCode"
          [ngClass]="{ 'is-invalid': submitted && postal.zipCode.errors }">
        </ng-select>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group mx-1 mb-4">
        <label>เบอร์โทรศัพท์มือถือ</label>
        <input type="text"
          autocomplete="off"
          class="form-control"
          formControlName="phone"
          [imask]="{mask: '000-000-0000'}">
      </div>
    </div>
  </div>
</form>