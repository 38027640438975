import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';
import { DopaLaser, ResDopa, DopaID } from '../shared/dopa.model';
import { ResComparison } from '../shared/create-request.model';
import { CardDetail } from '../shared/idcard-model';
import { take } from 'rxjs/operators';
import { DropdownService } from '../../../shared/service/dropdown.service';
import { SpinnerService } from '../../../core/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class CreateRequestService {
  errorCheckDopa: boolean[] = [];
  dopaSubmit = false;
  isLoading = false;

  private create = new Subject();
  private dropDownData = new BehaviorSubject(null);
  readonly dropDownData$ = this.dropDownData.asObservable();

  private getSectionForm = new BehaviorSubject<FormGroup>(null);
  readonly getSectionForm$ = this.getSectionForm.asObservable();

  private getPersonalForm = new BehaviorSubject<FormGroup>(null);
  readonly getPersonalForm$ = this.getPersonalForm.asObservable();

  private cardDetails = new BehaviorSubject<CardDetail>(null);
  readonly cardDetails$ = this.cardDetails.asObservable();

  public scanCardDetail = new BehaviorSubject<any>(null);
  readonly scanCardDetail$ = this.scanCardDetail.asObservable();

  public faceRecognition = new BehaviorSubject<
    { isMatch: boolean }[]
  >([{ isMatch: null }]);
  readonly faceRecognition$ = this.faceRecognition.asObservable();

  constructor(
    private http: ApiService,
    private httpClient: HttpClient,
    private dropdownService: DropdownService,
    private spinnerService: SpinnerService,
  ) {}

  updateSectionForm(form: FormGroup): void {
    this.getSectionForm.next(form);
  }

  updatePersonalForm(form: FormGroup): void {
    this.getPersonalForm.next(form);
  }

  updateCardDetails(object: CardDetail): void {
    this.cardDetails.next(object);
  }

  createForm(text?: string | null) {
    this.create.next(text);
  }

  getCreateForm() {
    return this.create.asObservable();
  }

  getDropdownItem(): void {
    this.spinnerService.show();
    const data = {
      type: 'applicant_type,card_type,gender_type,province,district,sub_district',
    };
    this.dropdownService.getDropdown(data).subscribe((res) => {
      this.dropDownData.next(res);
      this.spinnerService.hide();
    });
  }

  clearVariables(): void {
    this.updatePersonalForm(null);
  }

  addElementToObservableArray(): void {
    this.faceRecognition$.pipe(take(1)).subscribe((val) => {
      const newArr = [...val, { isMatch: null, dopa: null }];
      this.faceRecognition.next(newArr);
    });
  }

  faceComparing(data): Observable<ResComparison> {
    return this.http.post<ResComparison>(
      API_URL.face_comparison,
      data,
    );
  }

  createRequest(data): Observable<any> {
    const url = API_URL.memo_ncb;
    return this.http.post(url, data);
  }

  checkDopaID(data: DopaID | DopaLaser): Observable<ResDopa> {
    return this.http.post<ResDopa>(API_URL.dopa + 'check-cid/', data);
  }

  checkDopaLaser(data: DopaID | DopaLaser): Observable<ResDopa> {
    return this.http.post<ResDopa>(
      API_URL.dopa + 'check-laser/',
      data,
    );
  }

  sentOTP(data): Observable<any> {
    return this.http.post(API_URL.otp + 'send/', data);
  }

  validateOTP(data): Observable<any> {
    return this.http.post(API_URL.otp + 'verify/', data);
  }

  generateRequestPDF(
    memoNumber: string,
    data?,
  ): Observable<{ pdf: string }> {
    return this.http.post(
      API_URL.memo_ncb + `${memoNumber}/gen-pdf/`,
      data,
    );
  }

  generateReceipt(id, data): Observable<any> {
    return this.http.post(API_URL.memo_ncb + `${id}/receipt/`, data);
  }

  confirmVerifyInfo(
    memoNumber: string,
    param?: any,
  ): Observable<any> {
    return this.http.post(
      API_URL.memo_ncb + memoNumber + '/confirm-verify-info/',
      param,
    );
  }

  checkExistingCustomer(data): Observable<any> {
    return this.http.post(
      API_URL.memo_ncb + 'check-existing-customer/',
      data,
    );
  }
}
