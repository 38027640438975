import { Injectable } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ExtendedNgbDatepickerConfig extends NgbDatepickerConfig {
  minDate = {
    year: 2443,
    month: 1,
    day: 1,
  };
  maxDate = {
    year: new Date().getFullYear() + 20 + 543,
    month: 1,
    day: 1,
  };
}
