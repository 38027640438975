<ng-template #SendEmailModal
  class="modal-dialog"
  let-modal>
  <ng-container>
    <div class="modal-header">
      <div class="mr-auto">
        <h5 class="text-center">
          {{showWarning ?'กรุณาตรวจสอบ': 'ส่ง Email สำหรับหนังสือแจ้งผล'}}
        </h5>
      </div>
      <div class="ml-auto">
        <em (click)="closeModal()"
          class="fas fa-times pointer"
          aria-hidden="true">
        </em>
      </div>
    </div>
    <div class="modal-body py-4">
      <ng-container *ngIf="!showWarning">
        <div *ngFor="let item of mailDetails || [1]; let emailIndex= index">
          <div>
            {{isAllEmail || isGenMailCode ? 'เลือกรูปแบบหนังสือแจ้งผล':'CC No.:'+ item.doc_no|| ''}}
          </div>
          <div class="d-flex">
            <div class="col-12 p-0 input-group my-3">
              <ng-select [items]="isAllEmail || isGenMailCode? infoResultTemplate: infoResultType$"
                [loading]="isLoading"
                [(ngModel)]="sendEmail[emailIndex]"
                class="selected-box w-70 mr-3"
                bindLabel="label"
                [disabled]="disableFields">
              </ng-select>
              <div class="margin-t">
                <button (click)="isSendAndPreviewEmails(emailIndex, item.id,'preview')"
                  type="button"
                  [disabled]="disableFields"
                  class="btn primary-button mr-2">
                  <em class="fa fa-eye"></em>
                </button>
                <button (click)="detectGenMailCode(emailIndex, item.id,'sendMail')"
                  type="button"
                  [disabled]="disableFields"
                  class="btn primary-button">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="text-center"
        *ngIf="showWarning">
        <h5 class="mb-3 h4">
          เกิดข้อผิดพลาด
        </h5>
        <h6 class="text-center mb-5 warning-text">
          {{textWarning}}
          <br>หรือไม่ก่อนทำการพิมพ์เอกสาร
        </h6>
        <div class="mt-3">
          <button type="button"
            class="btn primary-button-sm py-2 px-4"
            (click)="closeWarning();closeModal()">
            ตกลง
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<div *ngIf="previewDocument">
  <app-preview-pdf [document]="pdfFile"
    (closed)="closePreviewDocument()"></app-preview-pdf>
</div>

<ng-template #displayProgress
  let-c="close"
  let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ progressExport }}
    </h5>
    <button (click)="d('Cross click')"
      aria-label="Close"
      class="close"
      type="button">
      <em class="fas fa-times"></em>
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="row mb-4">
      <div class="col-12 mt-4">
        <app-import-progress (export)="exportFromUrl($event)"
          (sent)="sent($event)"
          [displayTitle]="false"
          [progressExport]="progressExport"
          [taskId]="progressTaskId">
        </app-import-progress>
      </div>
    </div>
  </div>
</ng-template>