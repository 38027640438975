const HOST_URL = '';
export const BASE_URL = HOST_URL;
const HR_URL = HOST_URL + '/hr';

export const GATEWAY_URL = {
  user: HOST_URL + '/api/auth/user/',
  token: HOST_URL + '/api/auth/token/',
  refresh_token: HOST_URL + '/api/token-refresh/',
};

// The URL should be sorted alphabetically
export const API_URL = {
  announcement: '/api/announcement/',
  batch_uploads: '/api/batch-uploads/',
  bulk_update: '/api/configurations/bulk-update/',
  cc_group: '/api/cc-groups/',
  certificate: '/api/certificate/',
  close_otp: '/api/close-otp/',
  configuration: '/api/configurations/',
  dashboard_storage: '/api/storage-report/',
  delegate_permission: '/api/user-delegate/',
  departments: '/api/departments/',
  departments_tree: '/api/departments-tree/',
  excel_file: '/api/example-invite-user-file/',
  guest_approve_memo: '/api/guest-approval-memo/',
  import: '/api/members-import/',
  invite_user: '/api/invite-user/',
  add_user: '/api/add-user/',
  job_position: '/api/job-positions/',
  link_line_codium: '/api/line/codium/',
  link_line_user: '/api/line/user/',
  loa_groups: '/api/loa-groups/',
  login: '/api/token-auth/',
  logout: '/api/logout/',
  me: '/api/me/',
  face_comparison: '/api/face-comparison/',
  change_password: '/api/change-password/',
  memo_detail: '/api/memo-detail/',
  memo_attachment: '/api/memo-attachments/',
  memo_bulk_attachment: '/api/memo-attachments/bulk-create/',
  memo_comment: '/api/memo-comments/',
  memo_draft: '/api/memos-draft/',
  memo_link: '/api/memos-link/',
  memo_list_members: '/api/list-memo-numbers/',
  memo_logo: '/api/memos-logo/',
  memo_mentions: '/api/memos-comment/get_mention_profiles/',
  memo_number_verify: '/api/memos-verify-memo-number/',
  memo_organization: '/api/memos-organization/',
  memo_pdf2image: '/api/pdf2image/',
  memo_preview: '/api/memos-preview/',
  memo_publish: '/api/memos-publish/',
  memo_read: '/api/memos-read/',
  memo_real: '/api/memos-real/',
  memo_revised: '/api/get-revised-memo/',
  memo_resend_email: '/api/memos-resend-email/',
  memo_send_email: '/api/memos-send-email/',
  memo_special_type: '/api/memos-special-type/',
  memo_template: '/api/memos-template/',
  memos_history: '/api/memo-operation-logs/',
  news_feed_comment: '/api/news-feed/comments/',
  news_feed_post: '/api/news-feed-post/',
  news_related_post: '/api/news-feed-post/related-post/ ',
  notification: '/api/website-notifications/',
  open_otp: '/api/open-otp/',
  operation_log: '/api/operation-logs/',
  people: '/api/people/',
  preview_memo: '/api/render_pdf/',
  profile_user_active: '/api/profile-user-activate/',
  profiles_files: '/api/profiles-files/',
  profiles_guest: '/api/profiles-guest/',
  profiles_name: '/api/profiles/all/',
  refresh_token: HOST_URL + '/api/api/token-refresh/',
  initial: HOST_URL + '/api/initial/',
  reset_password: '/api/password_reset/',
  reset_password_confirm: '/api/password_reset/confirm/',
  reset_password_validate: '/api/password_reset/validate_token/',
  setting_email: '/api/mail-notifications/',
  site_config: '/api/site-config/',
  theme_active: '/api/theme-config-active/',
  theme_color_list: '/api/theme-config/',
  transfer_profile: '/api/transfer-profile/',

  user_department: '/api/departments/',
  upload_person_blob: '/api/upload-person-blob/',
  verify_otp: '/api/verify-otp/',
  dropdown: '/api/dropdown/',
  notification_action: '/api/notification-actions/',
  notification_channel: '/api/notification-channels/',
  muted_notification: '/api/muted-notifications/',
  general_memo: '/api/memos/general/',
  upload_memo_blob: '/api/upload-memo-blob/',
  remove_memo_blob: '/api/remove-memo-blob/',
  merge_pdf: '/api/merge-pdf/',
  download_merge_pdf: '/api/download-merge-pdf/',
  download_split_pdf: '/api/download-split-pdf/',
  my_task: '/api/my-tasks/',
  memos: '/api/memos/',
  upload_memo_type: '/api/memo-upload-type/',
  watermark: '/api/watermarks/',
  upload_memo_template: '/api/memo-upload-template/',
  finished_memo: '/api/memos/finished/',
  bulk_trash_memo: '/api/memos/bulk-trash/',
  bulk_untrash_memo: '/api/memos/bulk-untrash/',
  bulk_permanently_delete_memo: '/api/memos/bulk-permanently-delete/',
  otp: '/api/ncb-sms-otp/',
  memo_ncb: '/api/memos/ncb/',
  memo_ncb_general: '/api/memos/ncb/general/',
  master_data: '/api/master-data/',
  counter_import: '/api/counter-import/',
  dopa: '/api/dopa/',
  self_verify_memo: '/api/memos/ncb/self-verify/',
  agent_verify_memo: '/api/memos/ncb/agent-verify/',
  set_agent_verify_memo: '/api/memos/ncb/agent-set-y/',
  manage_th_post: '/api/memos/ncb/manage-th-post/',
  update_send_mail: '/api/memos/ncb/update-send-mail/',
  save_mail_code: '/api/memos/ncb/save-mail-code/',
  update_status: '/api/memos/ncb/update-status/',
  payment_kkp: '/api/payment-kkp/',
  ncb_destroy_box: '/api/ncb-destroy-box/',
  ncb_export: '/api/ncb-export/',
  task_status: '/api/task-status/',
  cancel_tasks: '/api/cancel-tasks/',
  result_notifications_send_email:
    '/api/result-notifications-send-email/',
  result_notifications_preview_email:
    '/api/result-notifications-preview-email/',
  generate_mail_code: '/api/memos/ncb/generate-mail-code/',
  auto_gen_due: '/api/memos/ncb/auto-gen-due/',
  send_mail: '/api/memos/ncb/send-mail/',
  holiday: '/api/holiday/',
  ncb_deploy_version: '/api/ncb/version/',
  email_notification: '/api/email-notification/',
};

export const HTTP_SERVER = {
  local_port: 'http://127.0.0.1:49151/',
};
