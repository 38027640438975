import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const collapse = trigger('collapse', [
  state(
    'true',
    style({
      height: '0',
      overflow: 'visible',
      opacity: '0',
    }),
  ),
  state(
    'false',
    style({
      overflow: 'hidden',
      opacity: '1',
    }),
  ),
  transition('false => true', animate(300 + 'ms ease-in')),
  transition('true => false', animate(300 + 'ms ease-out')),
]);
