import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TokenService } from 'src/app/core/authentication/token.service';
import { Token } from '../models/user.models';

export interface SiteConfigObj {
  id: number;
  ddoc_enable: boolean;
  ddoc_enable_feature_26: boolean;
  ddoc_enable_feature_28: boolean;
  timestamp_memo_enable: boolean;
  login_adfs_enable: boolean;
  maintenance_mode: boolean;
  maintenance_message_th: string;
  maintenance_message_en: string;
  ndid_enable: boolean;
  thaicom_enable: boolean;
  sms_otp_enable: boolean;
  email_otp_enable: boolean;
  ddoc_root_cert_company_name: string;
  self_service_form_enable: boolean;
  page_title: string;
}

@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  private sessionSubject = new Subject<any>();
  session$: Observable<any> = this.sessionSubject.asObservable();

  private timeLeft = new BehaviorSubject<number>(null);
  timeLeft$: Observable<number> = this.timeLeft.asObservable();
  private modalTimeSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);

  constructor(
    private http: ApiService,
    private store: Store,
    private authenticationService: AuthenticationService,
  ) {}

  public getConfig() {
    // this api does not require authentication.
    return this.http.get(API_URL.site_config);
  }
  public getSessionPolicy() {
    return this.http.get('/api/session-policy/');
  }

  pollSessionPolicyData(intervalMs: number): void {
    interval(intervalMs)
      .pipe(
        switchMap(() => {
          const currentUser =
            this.store.selectSnapshot<string>(
              (s) => s.auth?.access,
            ) || this.authenticationService.currentUserValue;
          if (currentUser) {
            return this.http.get('/api/session-policy/');
          } else {
            this.pollSessionPolicyData(intervalMs);
          }
        }),
        debounceTime(0),
        distinctUntilChanged(),
      )
      .subscribe((data: { session_timeout: number }) => {
        this.sessionSubject.next(data);
      });
  }

  sendTimeLeft(time: number): void {
    this.timeLeft.next(time);
  }

  sendModalTimeConfig(time: number): void {
    this.modalTimeSubject.next(time);
  }

  getModalTimeConfig(): Observable<number> {
    return this.modalTimeSubject.asObservable();
  }
}
