<div class="d-flex align-items-center mb-2">
  <h6 class="mr-2">รูปภาพสำหรับยืนยันตัวตน</h6>
  <ng-container *ngIf="faceDetect[personIndex].isMatch !== null">
    <span class="match d-flex align-items-center"
      *ngIf="faceDetect[personIndex].isMatch">
      <img src="assets/images/icons/match.svg"
        alt=""
        class="mt-n1"
        width="15px">
      Match
    </span>
    <span class="donot-match d-flex align-items-center"
      *ngIf="!faceDetect[personIndex].isMatch">
      <img src="assets/images/icons/donot-match.svg"
        alt=""
        class="mt-n1"
        width="15px">
      Don't Match
    </span>
  </ng-container>
</div>
<div class="row mx-0 mb-3 justify-content-start">
  <div id="box-search"
    *ngIf="person.get('isSmartCard')?.value; else elseTemplate">
    <div id="card">
      <h4 class="person-data person-id">{{ masked(person.get('idCard')?.value, '0 0000 00000 00 0') }}</h4>
      <h4 class="person-data person-name">{{ person.get('fullName')?.value }}</h4>
      <h4 class="person-data person-name-en person-en">
        Name
        <span>
          {{ person.get('titleNameEng')?.value + ' ' +
          person.get('firstNameEng')?.value }}
        </span>
      </h4>
      <h4 class="person-data person-lastname-en person-en">
        Last name
        <span>
          {{ person.get('lastNameEng')?.value }}
        </span>
      </h4>
      <h4 class="person-data person-birth-date">
        {{ dateFormatted(convertYearBack(person.get('birthday')?.value), 'th-TH') }}
      </h4>
      <h4 class="person-data person-birth-date-en person-en">
        {{ dateFormatted(convertYearBack(person.get('birthday')?.value), 'en-GB') }}
      </h4>
      <p class="person-data person-address">{{ address() }}</p>
      <p class="person-data person-issue-date">{{ dateFormatted(person.get('issueDate')?.value, 'th-TH') }}</p>
      <p class="person-data person-issue-date-en person-en">
        {{ dateFormatted(person.get('issueDate')?.value, 'en-GB') }}
      </p>
      <p class="person-data person-en issue-date-en">Date of Issue</p>
      <img src="assets/images/ministry.png"
        alt=""
        class="ministry ministry-center">
      <p class="person-data person-expire-date">{{ dateFormatted(person.get('expireDate')?.value, 'th-TH') }}</p>
      <p class="person-data person-en person-expire-date-en">
        {{ dateFormatted(person.get('expireDate')?.value, 'en-GB') }}
      </p>
      <p class="person-data person-en expire-date-en">Date of Expiry</p>
      <img alt=""
        class="avatar-img"
        [src]="person.value.scanCardImage | secure | async" />
    </div>
  </div>
  <ng-template #elseTemplate>
    <div class="col-sm-12 col-md-6 photo mt-3 d-flex align-items-center justify-content-center">
      <img alt=""
        [src]="(person.value.scanCardImage| secure | async) || 'assets/images/identity.svg'"
        class="mt-3 mb-3 img-respon">
    </div>
    <div *ngIf="person.get('isSmartCard')?.value"
      class="blur"></div>
  </ng-template>
  <div class="col mt-3"
    *ngIf="checkCardType()">
    <div class="d-flex align-items-center">
      <button class="btn outline-button mt-1 p-2"
        (click)="checkDopa()"
        [disabled]="state !== buttonState.SUBMIT || this.person.get('isSmartCard')?.value && 
        (dopaSuccess() || !!createRequestService.errorCheckDopa[personIndex])">
        Check DOPA
      </button>
      <span class="px-2 text-danger"
        *ngIf="person.get('dopa')?.value === 'server'">Check DOPA ไม่ผ่าน!</span>
      <span class="px-2 text-success"
        *ngIf="dopaSuccess() && !createRequestService.isLoading && !createRequestService.errorCheckDopa[personIndex]">Check
        DOPA ผ่าน!</span>
    </div>
    <form [formGroup]="person">
      <div class="mt-3">
        <div class="col p-0">
          <input type="radio"
            id="uncheckedOnline"
            name="dopa"
            value="online"
            formControlName="dopa"
            (ngModelChange)="checkedOnline($event)"
            [checked]="person.value.dopa === 'online'">
          <label for="uncheckedOnline"
            class="mx-3">ไม่เช็ค Online DOPA</label>
        </div>
        <div class="col p-0">
          <input type="radio"
            class=""
            id="unconnectedServer"
            name="dopa"
            value="server"
            formControlName="dopa"
            (ngModelChange)="checkedOnline($event)"
            [checked]="person.value.dopa === 'server' || !!createRequestService.errorCheckDopa[personIndex]">
          <label for="unconnectedServer"
            class="mx-3">ติดต่อ Server DOPA ไม่ได้</label>
        </div>
      </div>
    </form>
  </div>

</div>